import React, {createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useState} from "react";
import {decodeJwt, getCookie} from "util/Utilities";
import UserRole from "models/types/UserRole";

interface Identity {
    name: string
    username: string
    roles: UserRole[]
}

interface AuthContext {
    identity: Identity
    update: () => void
    isInRole: (role: UserRole) => boolean
    isAuthenticated: () => boolean
}

const defaultIdentity = {
    name: "",
    username: "",
    roles: []
}

const authContext = createContext<AuthContext>({
    identity: defaultIdentity,
    update: () => {
    },
    isInRole: () => false,
    isAuthenticated: () => false
})

export const useAuth = () => useContext<AuthContext>(authContext)

const AuthProvider: FC<PropsWithChildren> = (props) => {
    const [token, setToken] = useState<string>()
    const [identity, setIdentity] = useState<Identity>(defaultIdentity)

    const update = useCallback(() => {
        const idToken = getCookie('id-token')

        if (idToken !== token) {
            if (idToken) {
                const jwt = decodeJwt(idToken)
                const payload = jwt?.payload
                if (payload) {
                    setIdentity({
                        name: `${payload["given_name"]} ${payload["family_name"]}`,
                        username: payload["cognito:username"],
                        roles: payload["cognito:groups"] as UserRole[]
                    })
                }
            } else {
                setIdentity(defaultIdentity)
            }

            // NOTE: It's important to set the token after setting all the other states,
            //  as this will cause all of other components to refresh.
            setToken(idToken)
        }
    }, [token])

    useEffect(() => update(), [update])

    const isInRole = (role: UserRole) => Boolean(identity.roles.find(r => r === role))
    const isAuthenticated = () => Boolean(identity.username)

    return (
        <authContext.Provider
            value={{identity, update, isInRole, isAuthenticated}}>
            {props.children}
        </authContext.Provider>
    )
}

export default AuthProvider
