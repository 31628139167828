import BaseApi from "util/api/BaseApi";
import Media from "models/Media";
import List from "models/List";
import ApiClient from "util/api/client/ApiClient";

interface GetUploadInstructionsInput {
    resource: "jobs" | "users"
    id: string
    documents: Media[]
}

interface UploadInstructions {
    method: string
    uploadUrl: string
    expiresIn: number
    headers: Record<string, string>
}

class MediaApi extends BaseApi {
    async getProfilePictureUploadInstructionAsync(id: string, document: Media):
        Promise<UploadInstructions | undefined> {
        const body = {picture: document}
        return await this.put<UploadInstructions>(`users/${id}/picture`, body)
    }

    async getUploadInstructionsAsync(input: GetUploadInstructionsInput, companyId: string = ""):
        Promise<List<UploadInstructions> | undefined> {
        const url = this.buildUrl(`${input.resource}/${input.id}/documents`, {
            companyId
        })
        const body = {documents: input.documents}
        return await this.post<List<UploadInstructions>>(url, body)
    }

    async uploadAsync(instructions: UploadInstructions, file: File): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            const response = await ApiClient.put<any>(instructions.uploadUrl,
                file, {headers: instructions.headers});

            if (response.status === 200) {
                resolve(true)
            } else {
                reject(false)
            }
        })
    }
}

export default MediaApi
