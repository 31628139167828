import React, {FC} from "react"
import Job from "models/Job";
import {Body1, Body2, Heading4, Heading6} from "components/common/Typography";
import DataTable, {ColumnDefinition} from "components/common/DataTable";
import strings from "res/strings";
import colors from "res/colors";
import {handleFieldErrors, toAge, toShortDate} from "util/Utilities";
import List from "models/List";
import Api from "data/remote/Api";
import ErrorResponse from "data/remote/models/ErrorResponse";
import JobStatus from "models/types/JobStatus";
import {VerticalFlexLayout} from "components/common/FlexLayout";
import {useSearchParams} from "react-router-dom";
import {cachedCompanies} from "routers/AdminRouter";

const statuses: JobStatus[] = [
    "all",
    "draft",
    "pending",
    "rejected",
    "active",
    "completed"
]

const allStatuses: JobStatus[] = [
    ...statuses,
    "cancelled",
    "paid",
    "closed"
]

const columns: ColumnDefinition<Job>[] = [
    {id: "name", label: strings.title, align: "left"},
    {id: "status", label: strings.status, align: "left"}
]

const adminColumns: ColumnDefinition<Job>[] = [
    {id: "name", label: strings.title, align: "left"},
    {id: "companyId", label: strings.companyName, align: "left"},
    {id: "status", label: strings.status, align: "left"}
]

const renderRow = (j: Job): (string | JSX.Element)[] => {
    return [
        <>
            <Heading6>{j.name}</Heading6>
            <Body2 color={colors.disabledText}>
                {toShortDate(j.created)}
            </Body2>
        </>,
        <>
            <Heading6>{strings[`${j.status}Status`]}</Heading6>
            <Body2 color={colors.disabledText}>
                Last update: {toAge(j.updated)}
            </Body2>
        </>
    ]
}

const adminRenderRow = (j: Job): (string | JSX.Element)[] => {
    return [
        <>
            <Heading6>{j.name}</Heading6>
            <Body2 color={colors.disabledText}>
                {toShortDate(j.created)}
            </Body2>
        </>,
        <Body2 color={colors.disabledText}>
            {cachedCompanies.find(c => c.companyId === j.companyId)?.companyName ?? "-"}
        </Body2>,
        <>
            <Heading6>{strings[`${j.status}Status`]}</Heading6>
            <Body2 color={colors.disabledText}>
                Last update: {toAge(j.updated)}
            </Body2>
        </>
    ]
}

const emptyView = (
    <VerticalFlexLayout
        height={"100%"}
        wrap={"nowrap"}
        justify={"center"}>
        <Heading4 align={"center"}>
            {strings.noAssignmentsTitle}
        </Heading4>
        <Body1 align={"center"}>
            {strings.noAssignmentsMessage}
        </Body1>
    </VerticalFlexLayout>
)

interface Props {
    onClick: (job: Job) => void
    isAdmin?: boolean
}

const JobsTable: FC<Props> = (props) => {
    const [params] = useSearchParams()
    const s = params.get("status")
    const defaultStatus = s ? s as JobStatus : "pending"

    const load = async (status: JobStatus) => {
        // TODO: 'window.history' is used to avoid reloading the page when replacing
        //  the URL. Find a better way to do it using useHistory or any other hook.
        const url = new URL(window.location.href)
        url.searchParams.set('status', status)
        window.history.pushState(null, '', url.toString())

        const data = await Api.jobs
            .getJobsAsync(status, params.get("companyId") ?? "")
            .catch(res => {
                handleFieldErrors(res.data as ErrorResponse)
                return undefined
            })

        if (data) {
            data.items = data?.items
                .sort((a, b) => a.name.localeCompare(b.name))
        }

        return data
    }

    const search = (term: string, list: List<Job>) => {
        return {
            ...list,
            items: list?.items.filter(i =>
                i.name.toLowerCase().includes(term.toLowerCase().trim())) ?? []
        }
    }

    return (
        <DataTable
            load={load}
            columns={props.isAdmin ? adminColumns : columns}
            renderRow={props.isAdmin ? adminRenderRow : renderRow}
            onClick={props.onClick}
            emptyView={emptyView}
            defaultStatus={defaultStatus}
            statues={props.isAdmin ? allStatuses : statuses}
            search={search}/>
    )
}

export default JobsTable
