import React, {FC} from "react"
import {Box} from "@mui/material"
import {Link, useNavigate, useSearchParams} from "react-router-dom"
import {VerticalFlexLayout} from "components/common/FlexLayout"
import paths from "res/paths"
import strings from "res/strings"
import PasswordValidationControl from "components/account/PasswordValidationControl"
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator"
import NotFound from "pages/NotFound";
import Api from "data/remote/Api";
import {handleFieldErrors} from "util/Utilities";
import ErrorResponse from "data/remote/models/ErrorResponse";
import {useAlert} from "contexts/AlertContext";

const ResetPassword: FC = () => {
    const alert = useAlert()
    const navigate = useNavigate()
    const [params] = useSearchParams()

    const username = params.get("username")
    const code = params.get("code")

    const onSubmit = async (password: string, code?: string) => {
        await Api.auth
            .confirmForgotPasswordAsync(username!.replace(" ", "+"), password, code!)
            .then(() => {
                alert.enqueue({
                    message: "Your password was reset successfully.",
                    severity: "success"
                })
                navigate(paths.signIn)
            })
            .catch(res => handleFieldErrors(res.data as ErrorResponse))
    }

    if (!username || !code) {
        return (<NotFound/>)
    } else {
        return (<>
            <HtmlMetaDecorator
                title={strings.resetPassword}/>

            <Box
                padding={4}
                maxWidth={700}
                margin={"auto"}>
                <VerticalFlexLayout spacing={3}>
                    <Link to={"/"}>
                        <img src={process.env.PUBLIC_URL + "/full-logo.svg"} alt={"logo"}
                             style={{width: "100%", maxWidth: 250, paddingBottom: 32}}/>
                    </Link>
                    <PasswordValidationControl
                        code={code}
                        type={"reset"}
                        onSubmit={onSubmit}/>
                    <Box textAlign={"center"} pt={6}>
                        <Link to={paths.termsConditions}>
                            {strings.termsConditions}
                        </Link>
                    </Box>
                </VerticalFlexLayout>
            </Box>
        </>)
    }
}

export default ResetPassword
