import React, {FC, useState} from "react"
import {Box, FormHelperText, MenuItem, Paper, Select} from "@mui/material";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import Button from "components/common/Button";
import {Heading5, Typography} from "components/common/Typography";
import {handleFieldErrors} from "util/Utilities";
import Contractor from "models/Contractor";
import Api from "data/remote/Api";
import ErrorResponse from "data/remote/models/ErrorResponse";
import {useForm, FormProvider, Controller, useFieldArray} from "react-hook-form";
import strings from "res/strings";
import InputField from "components/common/InputField";
import BudgetField from "components/common/BudgetField";
import CloseIcon from "components/common/icons/CloseIcon";
import IconButton from "components/common/IconButton";
import PlusIcon from "components/common/icons/PlusIcon";
import LocationField from "components/common/LocationField";

const rateIntervals = [
    strings.rateIntervalHour,
    strings.rateIntervalDay,
    strings.rateIntervalProject
]

interface Props {
    contractor: Contractor
    onSuccess: (contractor: Contractor) => void
    isAdmin?: boolean
}

const UpsertProfile: FC<Props> = (props) => {
    const vm = {
        ...props.contractor,
        languages: props.contractor.languages?.map((l, i) => ({id: i, value: l})) ?? [],
        experiences: props.contractor.experiences?.map((l, i) => ({id: i, value: l})) ?? [],
        expertise: props.contractor.expertise?.map((l, i) => ({id: i, value: l})) ?? [],
        specialization: props.contractor.specialization?.map((l, i) => ({id: i, value: l})) ?? [],
        equipment: props.contractor.equipment ? Object.keys(props.contractor.equipment)
            .map((l, i) => ({id: i, value: l})) : [],
        portfolio: props.contractor.portfolio ? Object.keys(props.contractor.portfolio)
            .map((l, i) => ({id: i, name: l, url: props.contractor.portfolio[l]?.url})) : []
    }

    const [loading, setLoading] = useState(false)
    const form = useForm<typeof vm>({defaultValues: vm})
    const control = form.control
    const languages = useFieldArray({control, name: "languages"})
    const experiences = useFieldArray({control, name: "experiences"})
    const expertise = useFieldArray({control, name: "expertise"})
    const specialization = useFieldArray({control, name: "specialization"})
    const equipment = useFieldArray({control, name: "equipment"})
    const portfolio = useFieldArray({control, name: "portfolio"})
    form.setValue("rate.rateInterval", form.getValues("rate.rateInterval") ?? rateIntervals[0])

    const onSubmit = form.handleSubmit(async fields => {
        if (loading) {
            return
        }

        form.clearErrors()
        fields.agentRating = Number.parseFloat(fields.agentRating?.toString())

        if (fields.rate?.rate?.value) {
            fields.rate.rate.value = Number.parseFloat(fields.rate.rate.value.toString())
        } else {
            fields.rate.rate.currency = ""
        }

        const eq: Record<string, any> = {}
        const port: Record<string, { url: string }> = {}
        fields.equipment?.filter(l => l.value.trim()).forEach(l => eq[l.value] = {})
        fields.portfolio?.filter(l => l.name.trim() && l.url.trim()).forEach(l => port[l.name] = {url: l.url})

        const obj = {
            ...fields,
            languages: fields.languages?.filter(l => l.value.trim()).map(l => l.value),
            experiences: fields.experiences?.filter(l => l.value.trim()).map(l => l.value),
            expertise: fields.expertise?.filter(l => l.value.trim()).map(l => l.value),
            specialization: fields.specialization?.filter(l => l.value.trim()).map(l => l.value),
            equipment: eq,
            portfolio: port
        }

        setLoading(true)

        await Api.users
            .updateContractorProfile(obj)
            .then(async c => props.onSuccess(c ?? obj))
            .catch(res => handleFieldErrors(res.data as ErrorResponse, form.setError))

        setLoading(false)
    })

    return (
        <FormProvider {...form}>
            <form onSubmit={onSubmit}>
                <HorizontalFlexLayout spacing={2}>
                    <GridItem xs={12}>
                        <Paper variant={"outlined"}>
                            <Box p={2}>
                                <HorizontalFlexLayout spacing={2}>
                                    <GridItem xs={12}>
                                        <Heading5>
                                            {strings.details}
                                        </Heading5>
                                    </GridItem>
                                    {props.isAdmin &&
                                    <GridItem xs={12} md={6}>
                                        <Controller
                                            name="taoMediaId"
                                            control={form.control}
                                            render={({field, fieldState: {error}}) => (
                                                <InputField
                                                    label={strings.taoMediaId}
                                                    fieldError={error}
                                                    {...field} />
                                            )}/>
                                    </GridItem>}
                                    <GridItem xs={12} md={6}>
                                        <Controller
                                            name="name"
                                            control={form.control}
                                            render={({field, fieldState: {error}}) => (
                                                <InputField
                                                    label={strings.name}
                                                    required
                                                    fieldError={error}
                                                    {...field} />
                                            )}/>
                                    </GridItem>
                                    <GridItem xs={12} md={6}>
                                        <Controller
                                            name="title"
                                            control={form.control}
                                            render={({field, fieldState: {error}}) => (
                                                <InputField
                                                    label={strings.title}
                                                    required
                                                    fieldError={error}
                                                    {...field} />
                                            )}/>
                                    </GridItem>
                                    <GridItem xs={12} md={6}>
                                        <Controller
                                            name="employer"
                                            control={form.control}
                                            render={({field, fieldState: {error}}) => (
                                                <InputField
                                                    label={strings.employer}
                                                    fieldError={error}
                                                    {...field} />
                                            )}/>
                                    </GridItem>
                                    <GridItem xs={12} md={6}>
                                        <Controller
                                            name="phone"
                                            control={form.control}
                                            render={({field, fieldState: {error}}) => (
                                                <InputField
                                                    label={strings.phone}
                                                    type={"number"}
                                                    fieldError={error}
                                                    {...field} />
                                            )}/>
                                    </GridItem>
                                    <GridItem xs={12} md={6}>
                                        <Controller
                                            name="email"
                                            control={form.control}
                                            render={({field, fieldState: {error}}) => (
                                                <InputField
                                                    label={strings.email}
                                                    type={"email"}
                                                    fieldError={error}
                                                    {...field} />
                                            )}/>
                                    </GridItem>
                                    {props.isAdmin &&
                                    <GridItem xs={12} md={6}>
                                        <Controller
                                            name="agentRating"
                                            control={form.control}
                                            render={({field, fieldState: {error}}) => (
                                                <InputField
                                                    label={strings.rating}
                                                    type={"number"}
                                                    required
                                                    inputProps={{step: 0.1, min: 0, max: 5}}
                                                    fieldError={error}
                                                    {...field} />
                                            )}/>
                                    </GridItem>}
                                </HorizontalFlexLayout>
                            </Box>
                        </Paper>
                    </GridItem>
                    <GridItem xs={12}>
                        <Paper variant={"outlined"}>
                            <Box p={2}>
                                <HorizontalFlexLayout spacing={2}>
                                    <GridItem xs={12}>
                                        <Heading5>
                                            {strings.regularRate}
                                        </Heading5>
                                    </GridItem>
                                    <GridItem xs={12} sm={8}>
                                        <BudgetField
                                            namespace="rate.rate"
                                            label={strings.rate}/>
                                    </GridItem>
                                    <GridItem xs={12} sm={4}>
                                        <Controller
                                            name="rate.rateInterval"
                                            control={form.control}
                                            render={({field, fieldState: {error}}) => (
                                                <>
                                                    <Typography
                                                        component={"label"}
                                                        sx={{lineHeight: "20px", fontSize: 14}}>
                                                        {strings.ratePer}
                                                    </Typography>
                                                    <Select
                                                        fullWidth
                                                        required
                                                        error={Boolean(error)}
                                                        {...field}>
                                                        {rateIntervals.map(c => (
                                                            <MenuItem key={c} value={c}>{c}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    {Boolean(error) &&
                                                    <FormHelperText error={true}>
                                                        {error?.message}
                                                    </FormHelperText>}
                                                </>
                                            )}/>
                                    </GridItem>
                                </HorizontalFlexLayout>
                            </Box>
                        </Paper>
                    </GridItem>
                    <GridItem xs={12}>
                        <Paper variant={"outlined"}>
                            <Box p={2}>
                                <HorizontalFlexLayout spacing={2}>
                                    <GridItem xs={12}>
                                        <Heading5>
                                            {strings.location}
                                        </Heading5>
                                    </GridItem>
                                    <GridItem xs={12} sm={4}>
                                        <Controller
                                            name="nationality"
                                            control={form.control}
                                            render={({field, fieldState: {error}}) => (
                                                <InputField
                                                    label={strings.nationality}
                                                    fieldError={error}
                                                    {...field} />
                                            )}/>
                                    </GridItem>
                                    <GridItem xs={12} sm={8}>
                                        <LocationField withLabel/>
                                    </GridItem>
                                </HorizontalFlexLayout>
                            </Box>
                        </Paper>
                    </GridItem>
                    <GridItem xs={12}>
                        <Paper variant={"outlined"}>
                            <Box p={2}>
                                <HorizontalFlexLayout spacing={2}>
                                    <GridItem xs={12}>
                                        <Heading5>
                                            {strings.bankDetails}
                                        </Heading5>
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <Controller
                                            name="bankAccount.name"
                                            control={form.control}
                                            render={({field, fieldState: {error}}) => (
                                                <InputField
                                                    label={strings.name}
                                                    fieldError={error}
                                                    {...field} />
                                            )}/>
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <Controller
                                            name="bankAccount.iban"
                                            control={form.control}
                                            render={({field, fieldState: {error}}) => (
                                                <InputField
                                                    label={strings.iban}
                                                    fieldError={error}
                                                    {...field} />
                                            )}/>
                                    </GridItem>
                                </HorizontalFlexLayout>
                            </Box>
                        </Paper>
                    </GridItem>
                    <GridItem xs={12}>
                        <Paper variant={"outlined"}>
                            <Box p={2}>
                                <VerticalFlexLayout spacing={3}>
                                    <HorizontalFlexLayout
                                        spacing={2}
                                        wrap={"nowrap"}
                                        alignItems={"center"}>
                                        <GridItem grow={1}>
                                            <Heading5>
                                                {strings.languages}
                                            </Heading5>
                                        </GridItem>
                                        <IconButton
                                            variant={"contained"}
                                            onClick={() => languages.append({id: languages.fields.length, value: ""})}>
                                            <PlusIcon/>
                                        </IconButton>
                                    </HorizontalFlexLayout>
                                    {Boolean(languages.fields.length) &&
                                    <HorizontalFlexLayout spacing={2}>
                                        {languages.fields.map((f, i) =>
                                            <GridItem key={f.id} xs={12} sm={6} md={4} lg={3}>
                                                <HorizontalFlexLayout
                                                    wrap={"nowrap"}
                                                    alignItems={"center"}>
                                                    <GridItem grow={1}>
                                                        <Controller
                                                            name={`languages.${i}.value`}
                                                            control={form.control}
                                                            render={({field}) => (
                                                                <InputField {...field} />
                                                            )}/>
                                                    </GridItem>
                                                    <IconButton
                                                        onClick={() => languages.remove(i)}>
                                                        <CloseIcon size={16}/>
                                                    </IconButton>
                                                </HorizontalFlexLayout>
                                            </GridItem>
                                        )}
                                    </HorizontalFlexLayout>}
                                </VerticalFlexLayout>
                            </Box>
                        </Paper>
                    </GridItem>
                    <GridItem xs={12}>
                        <Paper variant={"outlined"}>
                            <Box p={2}>
                                <VerticalFlexLayout spacing={3}>
                                    <HorizontalFlexLayout
                                        spacing={2}
                                        wrap={"nowrap"}
                                        alignItems={"center"}>
                                        <GridItem grow={1}>
                                            <Heading5>
                                                {strings.portfolio}
                                            </Heading5>
                                        </GridItem>
                                        <IconButton
                                            variant={"contained"}
                                            onClick={() => portfolio.append({
                                                id: portfolio.fields.length,
                                                name: "",
                                                url: ""
                                            })}>
                                            <PlusIcon/>
                                        </IconButton>
                                    </HorizontalFlexLayout>
                                    {Boolean(portfolio.fields.length) &&
                                    <VerticalFlexLayout spacing={2}>
                                        {portfolio.fields.map((f, i) =>
                                            <GridItem key={f.id} xs={12} sm={6} md={4} lg={3}>
                                                <HorizontalFlexLayout
                                                    spacing={2}
                                                    wrap={"nowrap"}
                                                    alignItems={"center"}>
                                                    <GridItem grow={1}>
                                                        <Controller
                                                            name={`portfolio.${i}.name`}
                                                            control={form.control}
                                                            render={({field}) => (
                                                                <InputField
                                                                    placeholder={strings.name}
                                                                    {...field} />
                                                            )}/>
                                                    </GridItem>
                                                    <GridItem grow={1}>
                                                        <Controller
                                                            name={`portfolio.${i}.url`}
                                                            control={form.control}
                                                            render={({field}) => (
                                                                <InputField
                                                                    placeholder={strings.url}
                                                                    {...field} />
                                                            )}/>
                                                    </GridItem>
                                                    <IconButton
                                                        onClick={() => portfolio.remove(i)}>
                                                        <CloseIcon size={16}/>
                                                    </IconButton>
                                                </HorizontalFlexLayout>
                                            </GridItem>
                                        )}
                                    </VerticalFlexLayout>}
                                </VerticalFlexLayout>
                            </Box>
                        </Paper>
                    </GridItem>
                    <GridItem xs={12}>
                        <Paper variant={"outlined"}>
                            <Box p={2}>
                                <VerticalFlexLayout spacing={3}>
                                    <HorizontalFlexLayout
                                        spacing={2}
                                        wrap={"nowrap"}
                                        alignItems={"center"}>
                                        <GridItem grow={1}>
                                            <Heading5>
                                                {strings.expertise}
                                            </Heading5>
                                        </GridItem>
                                        <IconButton
                                            variant={"contained"}
                                            onClick={() => expertise.append({id: expertise.fields.length, value: ""})}>
                                            <PlusIcon/>
                                        </IconButton>
                                    </HorizontalFlexLayout>
                                    {Boolean(expertise.fields.length) &&
                                    <HorizontalFlexLayout spacing={2}>
                                        {expertise.fields.map((f, i) =>
                                            <GridItem key={f.id} xs={12} sm={6} md={4} lg={3}>
                                                <HorizontalFlexLayout
                                                    wrap={"nowrap"}
                                                    alignItems={"center"}>
                                                    <GridItem grow={1}>
                                                        <Controller
                                                            name={`expertise.${i}.value`}
                                                            control={form.control}
                                                            render={({field}) => (
                                                                <InputField {...field} />
                                                            )}/>
                                                    </GridItem>
                                                    <IconButton
                                                        onClick={() => expertise.remove(i)}>
                                                        <CloseIcon size={16}/>
                                                    </IconButton>
                                                </HorizontalFlexLayout>
                                            </GridItem>
                                        )}
                                    </HorizontalFlexLayout>}
                                </VerticalFlexLayout>
                            </Box>
                        </Paper>
                    </GridItem>
                    <GridItem xs={12}>
                        <Paper variant={"outlined"}>
                            <Box p={2}>
                                <VerticalFlexLayout spacing={3}>
                                    <HorizontalFlexLayout
                                        spacing={2}
                                        wrap={"nowrap"}
                                        alignItems={"center"}>
                                        <GridItem grow={1}>
                                            <Heading5>
                                                {strings.experiences}
                                            </Heading5>
                                        </GridItem>
                                        <IconButton
                                            variant={"contained"}
                                            onClick={() => experiences.append({
                                                id: experiences.fields.length,
                                                value: ""
                                            })}>
                                            <PlusIcon/>
                                        </IconButton>
                                    </HorizontalFlexLayout>
                                    {Boolean(experiences.fields.length) &&
                                    <HorizontalFlexLayout spacing={2}>
                                        {experiences.fields.map((f, i) =>
                                            <GridItem key={f.id} xs={12} sm={6} md={4} lg={3}>
                                                <HorizontalFlexLayout
                                                    wrap={"nowrap"}
                                                    alignItems={"center"}>
                                                    <GridItem grow={1}>
                                                        <Controller
                                                            name={`experiences.${i}.value`}
                                                            control={form.control}
                                                            render={({field}) => (
                                                                <InputField {...field} />
                                                            )}/>
                                                    </GridItem>
                                                    <IconButton
                                                        onClick={() => experiences.remove(i)}>
                                                        <CloseIcon size={16}/>
                                                    </IconButton>
                                                </HorizontalFlexLayout>
                                            </GridItem>
                                        )}
                                    </HorizontalFlexLayout>}
                                </VerticalFlexLayout>
                            </Box>
                        </Paper>
                    </GridItem>
                    <GridItem xs={12}>
                        <Paper variant={"outlined"}>
                            <Box p={2}>
                                <VerticalFlexLayout spacing={3}>
                                    <HorizontalFlexLayout
                                        spacing={2}
                                        wrap={"nowrap"}
                                        alignItems={"center"}>
                                        <GridItem grow={1}>
                                            <Heading5>
                                                {strings.specialization}
                                            </Heading5>
                                        </GridItem>
                                        <IconButton
                                            variant={"contained"}
                                            onClick={() => specialization.append({
                                                id: specialization.fields.length,
                                                value: ""
                                            })}>
                                            <PlusIcon/>
                                        </IconButton>
                                    </HorizontalFlexLayout>
                                    {Boolean(specialization.fields.length) &&
                                    <HorizontalFlexLayout spacing={2}>
                                        {specialization.fields.map((f, i) =>
                                            <GridItem key={f.id} xs={12} sm={6} md={4} lg={3}>
                                                <HorizontalFlexLayout
                                                    wrap={"nowrap"}
                                                    alignItems={"center"}>
                                                    <GridItem grow={1}>
                                                        <Controller
                                                            name={`specialization.${i}.value`}
                                                            control={form.control}
                                                            render={({field}) => (
                                                                <InputField {...field} />
                                                            )}/>
                                                    </GridItem>
                                                    <IconButton
                                                        onClick={() => specialization.remove(i)}>
                                                        <CloseIcon size={16}/>
                                                    </IconButton>
                                                </HorizontalFlexLayout>
                                            </GridItem>
                                        )}
                                    </HorizontalFlexLayout>}
                                </VerticalFlexLayout>
                            </Box>
                        </Paper>
                    </GridItem>
                    <GridItem xs={12}>
                        <Paper variant={"outlined"}>
                            <Box p={2}>
                                <VerticalFlexLayout spacing={3}>
                                    <HorizontalFlexLayout
                                        spacing={2}
                                        wrap={"nowrap"}
                                        alignItems={"center"}>
                                        <GridItem grow={1}>
                                            <Heading5>
                                                {strings.equipment}
                                            </Heading5>
                                        </GridItem>
                                        <IconButton
                                            variant={"contained"}
                                            onClick={() => equipment.append({
                                                id: equipment.fields.length,
                                                value: ""
                                            })}>
                                            <PlusIcon/>
                                        </IconButton>
                                    </HorizontalFlexLayout>
                                    {Boolean(equipment.fields.length) &&
                                    <HorizontalFlexLayout spacing={2}>
                                        {equipment.fields.map((f, i) =>
                                            <GridItem key={f.id} xs={12} sm={6} md={4} lg={3}>
                                                <HorizontalFlexLayout
                                                    wrap={"nowrap"}
                                                    alignItems={"center"}>
                                                    <GridItem grow={1}>
                                                        <Controller
                                                            name={`equipment.${i}.value`}
                                                            control={form.control}
                                                            render={({field}) => (
                                                                <InputField {...field} />
                                                            )}/>
                                                    </GridItem>
                                                    <IconButton
                                                        onClick={() => equipment.remove(i)}>
                                                        <CloseIcon size={16}/>
                                                    </IconButton>
                                                </HorizontalFlexLayout>
                                            </GridItem>
                                        )}
                                    </HorizontalFlexLayout>}
                                </VerticalFlexLayout>
                            </Box>
                        </Paper>
                    </GridItem>
                    <GridItem xs={12}>
                        <HorizontalFlexLayout
                            justify={"flex-end"}>
                            <Button
                                type="submit"
                                isBusy={loading}
                                variant="contained">
                                {strings.save}
                            </Button>
                        </HorizontalFlexLayout>
                    </GridItem>
                </HorizontalFlexLayout>
            </form>
        </FormProvider>
    )
}

export default UpsertProfile
