import React, {FC} from "react"
import {Box} from "@mui/material"
import {HorizontalFlexLayout} from "components/common/FlexLayout"
import colors from "res/colors"
import {Body2} from "components/common/Typography";
import JobStatus from "models/types/JobStatus";

interface Props {
    value: JobStatus
    color?: string
}

const Status: FC<Props> = (props) => {
    let color
    switch (props.value) {
        case "pending":
        case "matching":
        case "reviewing":
            color = colors.primary
            break
        case "approved":
        case "accepted":
        case "active":
        case "completed":
        case "paid":
            color = colors.success
            break
        case "rejected":
        case "closed":
        case "declined":
        case "cancelled":
            color = colors.error
            break
        default:
            color = colors.disabledText
    }

    return (
        <HorizontalFlexLayout
            spacing={1}
            wrap={"nowrap"}
            alignItems={"center"}>
            <Box
                width={12}
                height={12}
                borderRadius={"50%"}
                bgcolor={props.color ?? color}/>
            <Body2
                color={colors.disabledText}
                sx={{textTransform: "capitalize"}}>
                {props.value}
            </Body2>
        </HorizontalFlexLayout>
    )
}

export default Status
