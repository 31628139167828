import React, {FC} from "react"
import Contractor from "models/Contractor"
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout"
import {Body1, Body2, Heading4} from "components/common/Typography"
import StarIcon from "components/common/icons/StarIcon"
import colors from "res/colors"
import strings from "res/strings"
import {Avatar} from "@mui/material"
import {moneyString} from "util/Utilities";

interface Props {
    contractor: Contractor
    stretch?: boolean
    // TODO: This is a workaround, find a better way.
    subtitle?: string
}

const ContractorHeader: FC<Props> = (props) => {
    const contractor = props.contractor
    const subtitle = props.subtitle ? props.subtitle : contractor.rate?.rate ?
        `${strings.regularRate}: ${moneyString(contractor.rate?.rate)}/${contractor.rate?.rateInterval}` : ''

    return (
        <HorizontalFlexLayout
            spacing={2}
            wrap={"nowrap"}
            alignItems={subtitle ? "flex-start" : "center"}>
            <Avatar
                variant={"rounded"}
                src={contractor.image}
                alt={contractor.name}
                sx={{
                    width: 64,
                    height: 64,
                    backgroundColor:
                    colors.pearlWhite,
                    color: colors.text
                }}>
                {contractor.name?.slice(0, 2)}
            </Avatar>
            <VerticalFlexLayout>
                <HorizontalFlexLayout
                    spacing={2}
                    wrap={"nowrap"}
                    alignItems={"center"}>
                    <GridItem sx={{flexGrow: props.stretch ? 1 : undefined}}>
                        <Heading4>{contractor.name}</Heading4>
                    </GridItem>
                    {contractor.agentRating &&
                    <HorizontalFlexLayout
                        spacing={1}
                        wrap={"nowrap"}>
                        <Body1
                            color={colors.disabledText}
                            sx={{fontSize: 16, fontWeight: 500}}>
                            {contractor.agentRating.toFixed(1)}
                        </Body1>
                        <StarIcon color={colors.primary}/>
                    </HorizontalFlexLayout>}
                </HorizontalFlexLayout>
                <Body2 color={colors.disabledText}>
                    {Boolean(subtitle) && subtitle}
                </Body2>
            </VerticalFlexLayout>
        </HorizontalFlexLayout>
    )
}

export default ContractorHeader
