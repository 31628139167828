import React, {FC, useState} from "react";
import CrewMember from "models/CrewMember";
import {Heading5, Heading6, Typography} from "components/common/Typography";
import InputField from "components/common/InputField";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import CloseIcon from "components/common/icons/CloseIcon";
import colors from "res/colors";
import {Box, FormHelperText, MenuItem, Select} from "@mui/material";
import PlusIcon from "components/common/icons/PlusIcon";
import {Controller, useFieldArray, useForm, FormProvider} from "react-hook-form";
import strings from "res/strings";
import {deserializeWorkingDates, handleFieldErrors, serializeWorkingDates} from "util/Utilities";
import UpsertCrewMemberVM from "viewModels/UpsertCrewMemberVM";
import IconButton from "components/common/IconButton";
import Button from "components/common/Button";
import Api from "data/remote/Api";
import ErrorResponse from "data/remote/models/ErrorResponse";
import Offer from "models/Offer";
import BudgetField from "components/common/BudgetField";

const rateIntervals = [
    strings.rateIntervalHour,
    strings.rateIntervalDay,
    strings.rateIntervalProject
]

interface Props {
    offer: Offer
    crewMember?: CrewMember
    onSuccess: (crewMember: CrewMember) => void
    onCancel: () => void
}

const UpsertCrewMemberControl: FC<Props> = (props) => {
    const vm = {
        ...props.crewMember, ...{
            workingDates: deserializeWorkingDates(props.crewMember?.workingDates ?? [])
        }
    }

    const form = useForm<UpsertCrewMemberVM>({defaultValues: vm})
    const control = form.control
    const {fields, append, remove} = useFieldArray({control, name: "workingDates"});
    form.setValue("rate.rateInterval", form.getValues("rate.rateInterval") ?? rateIntervals[0])

    const [loading, setLoading] = useState(false)

    const addWorkingHour = () => append({date: "", from: "", to: ""})

    const onSubmit = form.handleSubmit(async fields => {
        form.clearErrors()

        if (fields.rate?.rate?.value) {
            fields.rate.rate.value = Number.parseFloat(fields.rate.rate.value.toString())
        } else {
            fields.rate.rate.currency = ""
        }

        setLoading(true)

        const body = {
            ...fields,
            workingDates: serializeWorkingDates(fields.workingDates)
        } as CrewMember

        if (props.crewMember) {
            await Api.offers
                .updateCrewMember(props.offer.id, props.offer.jobId, props.offer.contractorId, body)
                .then(() => {
                    props.onSuccess(body)
                })
                .catch(res => {
                    handleFieldErrors(res.data as ErrorResponse, form.setError)
                    setLoading(false)
                })
        } else {
            await Api.offers
                .createCrewMember(props.offer.id, props.offer.jobId, props.offer.contractorId, body)
                .then(c => {
                    body.id = c!.id
                    props.onSuccess(body)
                })
                .catch(res => {
                    handleFieldErrors(res.data as ErrorResponse, form.setError)
                    setLoading(false)
                })
        }
    })

    return (
        <FormProvider {...form}>
            <form onSubmit={onSubmit}>
                <VerticalFlexLayout spacing={3}>
                    <Heading5>
                        {strings.crewMember}
                    </Heading5>
                    <Controller
                        name="title"
                        control={form.control}
                        render={({field}) => (
                            <InputField
                                autoFocus
                                required
                                placeholder={strings.professionPlaceholder}
                                label={strings.profession}
                                {...field} />
                        )}/>
                    <HorizontalFlexLayout
                        spacing={2}
                        alignItems={"flex-end"}>
                        <GridItem xs={12} sm={8}>
                            <BudgetField
                                namespace="rate.rate"
                                label={strings.maxRate}/>
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                            <Controller
                                name="rate.rateInterval"
                                control={form.control}
                                render={({field, fieldState: {error}}) => (
                                    <>
                                        <Typography
                                            component={"label"}
                                            sx={{lineHeight: "20px", fontSize: 14}}>
                                            {strings.paymentPer}
                                        </Typography>
                                        <Select
                                            fullWidth
                                            required
                                            error={Boolean(error)}
                                            {...field}>
                                            {rateIntervals.map(c => (
                                                <MenuItem key={c} value={c}>{c}</MenuItem>
                                            ))}
                                        </Select>
                                        {Boolean(error) &&
                                        <FormHelperText error={true}>
                                            {error?.message}
                                        </FormHelperText>}
                                    </>
                                )}/>
                        </GridItem>
                    </HorizontalFlexLayout>
                    <Controller
                        name="notes"
                        control={form.control}
                        render={({field}) => (
                            <InputField
                                label={strings.notes}
                                placeholder={strings.notesPlaceholder}
                                multiline
                                {...field} />
                        )}/>
                    <HorizontalFlexLayout
                        wrap={"nowrap"}
                        alignItems={"center"}
                        justify={"space-between"}>
                        <Heading6>
                            {strings.workingDates}
                        </Heading6>
                        <IconButton
                            variant={"contained"}
                            onClick={addWorkingHour}>
                            <PlusIcon/>
                        </IconButton>
                    </HorizontalFlexLayout>
                    {fields.map((f, i) => (
                        <HorizontalFlexLayout
                            key={f.id}
                            spacing={2}
                            wrap={"nowrap"}
                            alignItems={"flex-end"}>
                            <IconButton
                                variant={"outlined"}
                                color={"error"}
                                onClick={() => remove(i)}>
                                <CloseIcon color={colors.error}/>
                            </IconButton>
                            <GridItem xs={4}>
                                <Controller
                                    name={`workingDates.${i}.date`}
                                    control={form.control}
                                    render={({field}) => (
                                        <InputField
                                            required
                                            type={"date"}
                                            {...field} />
                                    )}/>
                            </GridItem>
                            <GridItem xs={4}>
                                <Controller
                                    name={`workingDates.${i}.from`}
                                    control={form.control}
                                    render={({field}) => (
                                        <InputField
                                            required
                                            type={"time"}
                                            {...field} />
                                    )}/>
                            </GridItem>
                            <GridItem xs={4}>
                                <Controller
                                    name={`workingDates.${i}.to`}
                                    control={form.control}
                                    render={({field}) => (
                                        <InputField
                                            required
                                            type={"time"}
                                            {...field} />
                                    )}/>
                            </GridItem>
                        </HorizontalFlexLayout>
                    ))}
                    <Box pt={2}>
                        <HorizontalFlexLayout spacing={2}>
                            <GridItem sx={{flexGrow: 1}}>
                                <Button
                                    variant={"outlined"}
                                    color={"secondary"}
                                    fullWidth
                                    onClick={props.onCancel}>
                                    {strings.cancel}
                                </Button>
                            </GridItem>
                            <GridItem sx={{flexGrow: 1}}>
                                <Button
                                    fullWidth
                                    type="submit"
                                    isBusy={loading}
                                    variant="contained"
                                    color="primary">
                                    {props.crewMember ? strings.save : strings.add}
                                </Button>
                            </GridItem>
                        </HorizontalFlexLayout>
                    </Box>
                </VerticalFlexLayout>
            </form>
        </FormProvider>
    )
}

export default UpsertCrewMemberControl
