import React, {FC} from "react"
import {Box} from "@mui/material"
import {VerticalFlexLayout} from "components/common/FlexLayout"
import strings from "res/strings"
import {Link} from "react-router-dom"
import paths from "res/paths"
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator"
import ForgotPasswordControl from "components/account/ForgotPasswordControl";

const ForgotPassword: FC = () => {
    return (<>
        <HtmlMetaDecorator
            title={strings.forgotPassword}/>

        <Box padding={4} maxWidth={450} margin={"auto"}>
            <VerticalFlexLayout spacing={3}>
                <Link to={"/"}>
                    <img src={process.env.PUBLIC_URL + "/full-logo.svg"} alt={"logo"}
                         style={{width: "100%", maxWidth: 250, paddingBottom: 32}}/>
                </Link>
                <ForgotPasswordControl/>
                <Box textAlign={"center"} pt={6}>
                    <Link to={paths.termsConditions}>
                        {strings.termsConditions}
                    </Link>
                </Box>
            </VerticalFlexLayout>
        </Box>
    </>)
}

export default ForgotPassword
