import React, {FC, useEffect, useState} from "react"
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator"
import {Box, Dialog, DialogActions, DialogContent} from "@mui/material";
import Api from "data/remote/Api";
import {handleFieldErrors} from "util/Utilities";
import ErrorResponse from "data/remote/models/ErrorResponse";
import Contractor from "models/Contractor";
import Loading from "components/common/Loading";
import DataNotFound from "components/common/DataNotFound";
import ContractorProfile from "components/contractor/Profile";
import {useAuth} from "contexts/AuthContext";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import IconButton from "components/common/IconButton";
import CloseIcon from "components/common/icons/CloseIcon";
import {Body1, Heading3, Heading4} from "components/common/Typography";
import colors from "res/colors";
import UpsertProfile from "components/contractor/UpsertProfile";
import EditIcon from "components/common/icons/EditIcon";
import strings from "res/strings";
import DialogTransition from "components/common/DialogTransition";
import Button from "components/common/Button";
import PasswordValidationControl from "components/account/PasswordValidationControl";
import {useAlert} from "contexts/AlertContext";
import {useLocation} from "react-router-dom";
import paths from "res/paths";

const Profile: FC = () => {
    const {pathname, search} = useLocation()
    const {identity} = useAuth()
    const alert = useAlert()

    const [contractor, setContractor] = useState<Contractor>()
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [changeOpen, setChangeOpen] = useState(false)

    useEffect(() => {
        void async function fetchData() {
            if (identity.username) {
                await Api.users
                    .getContractorProfile(identity.username)
                    .then(c => setContractor(c))
                    .catch(res => handleFieldErrors(res.data as ErrorResponse))
            }

            setOpen(pathname === paths.editContractorProfile)
            setLoading(false)
        }()
    }, [identity, pathname])

    if (loading) {
        return (<Loading/>)
    } else if (!contractor) {
        return (<DataNotFound/>)
    }

    return (<>
        <HtmlMetaDecorator title={contractor.name}/>

        <VerticalFlexLayout
            height={"100%"}
            wrap={"nowrap"}>
            <Box p={2} sx={{backgroundColor: "white"}}>
                <HorizontalFlexLayout spacing={2}>
                    <GridItem sx={{flexGrow: 1}}>
                        <Heading3>
                            {strings.profile}
                        </Heading3>
                    </GridItem>
                    <Button
                        variant={"outlined"}
                        color={"secondary"}
                        fullWidth
                        onClick={() => setChangeOpen(true)}>
                        {strings.changePassword}
                    </Button>
                    <IconButton
                        variant={"outlined"}
                        color={"secondary"}
                        onClick={() => setOpen(true)}>
                        <EditIcon/>
                    </IconButton>
                </HorizontalFlexLayout>
            </Box>
            <GridItem sx={{backgroundColor: "#F9F9F9", flexGrow: 1}}>
                <Box p={2}>
                    <ContractorProfile
                        contractor={contractor}/>
                </Box>
            </GridItem>
        </VerticalFlexLayout>

        <Dialog
            open={open}
            fullScreen
            scroll={"paper"}
            TransitionComponent={DialogTransition}
            onClose={() => setOpen(false)}>
            <DialogActions sx={{padding: 2}}>
                <HorizontalFlexLayout spacing={2} wrap={"nowrap"}>
                    <GridItem sx={{flexGrow: 1}}>
                        <Heading4>{contractor.name}</Heading4>
                    </GridItem>
                    <IconButton
                        onClick={() => setOpen(false)}>
                        <CloseIcon/>
                    </IconButton>
                </HorizontalFlexLayout>
            </DialogActions>
            <DialogContent
                dividers
                sx={{backgroundColor: colors.pearlWhite}}>
                {search?.includes("state=new") &&
                <Body1 paddingBottom={2}>
                    Please complete your profile:
                </Body1>}
                <UpsertProfile
                    contractor={contractor}
                    onSuccess={c => {
                        setContractor(c)
                        setOpen(false)
                    }}/>
            </DialogContent>
        </Dialog>

        <Dialog
            open={changeOpen}
            fullWidth
            maxWidth={"sm"}
            TransitionComponent={DialogTransition}
            onClose={() => setChangeOpen(false)}>
            <DialogContent>
                <PasswordValidationControl
                    type={"change"}
                    onSubmit={async (password, code) => {
                        await Api.auth
                            .changePasswordAsync(code!, password)
                            .then(() => {
                                alert.enqueue({
                                    message: "Your password was changed successfully.",
                                    severity: "success"
                                })
                                setChangeOpen(false)
                            })
                            .catch(res => handleFieldErrors(res.data as ErrorResponse))
                    }}/>
            </DialogContent>
        </Dialog>
    </>)
}

export default Profile
