import React, {FC} from "react";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import {Box, Paper} from "@mui/material";
import {Body1} from "components/common/Typography";
import Job from "models/Job";
import DisplayField from "components/common/DisplayField";
import strings from "res/strings";
import {toShortDate, moneyString, locationString} from "util/Utilities";
import DownloadIcon from "components/common/icons/DownloadIcon";

interface Props {
    job: Job
}

const JobDetails: FC<Props> = (props) => {
    const job = props.job
    const hasType = Boolean(job.type)

    return (
        <HorizontalFlexLayout spacing={2}>
            {hasType &&
            <GridItem grow={1}>
                <Paper variant={"outlined"}>
                    <Box p={2}>
                        <VerticalFlexLayout spacing={1}>
                            <DisplayField
                                title={strings.projectType}
                                content={strings.projectTypeTip}/>
                            <Body1>{job.type}</Body1>
                        </VerticalFlexLayout>
                    </Box>
                </Paper>
            </GridItem>}
            <GridItem grow={1}>
                <Paper variant={"outlined"}>
                    <Box p={2}>
                        <VerticalFlexLayout spacing={1}>
                            <DisplayField
                                title={strings.location}
                                content={strings.jobLocationTip}/>
                            <Body1>{locationString(job.location)}</Body1>
                        </VerticalFlexLayout>
                    </Box>
                </Paper>
            </GridItem>
            <GridItem grow={1}>
                <Paper variant={"outlined"}>
                    <Box p={2}>
                        <VerticalFlexLayout spacing={1}>
                            <DisplayField
                                title={strings.budget}
                                content={strings.jobBudgetTip}/>
                            <Body1>{moneyString(job.budget)}</Body1>
                        </VerticalFlexLayout>
                    </Box>
                </Paper>
            </GridItem>
            <GridItem grow={1}>
                <Paper variant={"outlined"}>
                    <Box p={2}>
                        <VerticalFlexLayout spacing={1}>
                            <DisplayField
                                title={strings.deadline}
                                content={strings.jobDeadlineTip}/>
                            <Body1>
                                {toShortDate(job.deadline)}
                            </Body1>
                        </VerticalFlexLayout>
                    </Box>
                </Paper>
            </GridItem>
            <GridItem xs={12}>
                <Paper variant={"outlined"}>
                    <Box p={2}>
                        <VerticalFlexLayout spacing={1}>
                            <DisplayField
                                title={strings.requirements}
                                content={strings.jobRequirementsTip}/>
                            <Body1>{job.description}</Body1>
                        </VerticalFlexLayout>
                    </Box>
                </Paper>
            </GridItem>
            {Boolean(job.documents?.length) &&
            <GridItem xs={12}>
                <Paper variant={"outlined"}>
                    <Box p={2}>
                        <VerticalFlexLayout spacing={1}>
                            <DisplayField
                                title={strings.documents}
                                content={strings.jobDocumentsTip}/>
                            <GridItem width={"100%"}>
                                <HorizontalFlexLayout spacing={2}>
                                    {job.documents?.map((m, i) => (
                                        <GridItem key={i} xs={12} md={6}>
                                            <a href={m.url} style={{textDecoration: "none"}}
                                               target={"_blank"} rel="noreferrer">
                                                <Paper variant={"outlined"}>
                                                    <Box py={1} px={2}>
                                                        <HorizontalFlexLayout
                                                            spacing={1}
                                                            alignItems={"center"}>
                                                            <GridItem grow={1}>
                                                                <Body1>{m.title}</Body1>
                                                            </GridItem>
                                                            <DownloadIcon/>
                                                        </HorizontalFlexLayout>
                                                    </Box>
                                                </Paper>
                                            </a>
                                        </GridItem>
                                    ))}
                                </HorizontalFlexLayout>
                            </GridItem>
                        </VerticalFlexLayout>
                    </Box>
                </Paper>
            </GridItem>}
        </HorizontalFlexLayout>
    )
}

export default JobDetails
