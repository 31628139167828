import React from "react";
import IconProps from "components/common/icons/IconProps";

const MoreIcon: React.FC<IconProps> = (props) => (
    <div className={"svg-container"}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size ?? "24"}
            height={props.size ?? "24"}
            viewBox="0 0 24 24">
            <path
                d="M12 8C13.1027 8 14 7.10275 14 6C14 4.89725 13.1027 4 12 4C10.8973 4 10 4.89725 10 6C10 7.10275 10.8973 8 12 8Z"
                fill={props.color ?? "black"}/>
            <path
                d="M12 14C13.1027 14 14 13.1027 14 12C14 10.8973 13.1027 10 12 10C10.8973 10 10 10.8973 10 12C10 13.1027 10.8973 14 12 14Z"
                fill={props.color ?? "black"}/>
            <path
                d="M12 20C13.1027 20 14 19.1027 14 18C14 16.8973 13.1027 16 12 16C10.8973 16 10 16.8973 10 18C10 19.1027 10.8973 20 12 20Z"
                fill={props.color ?? "black"}/>
        </svg>
    </div>
)

export default MoreIcon
