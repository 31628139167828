import React, {ChangeEvent, useCallback, useEffect, useState} from "react"
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import {Body1, Body2, Heading6} from "components/common/Typography";
import colors from "res/colors";
import {Box, IconButton, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import List from "models/List";
import strings from "res/strings";
import JobStatus from "models/types/JobStatus";
import Loading from "components/common/Loading";
import InputField from "components/common/InputField";
import SearchIcon from "components/common/icons/SearchIcon";

export interface ColumnDefinition<T> {
    id: keyof T
    label: string
    align?: "left" | "center" | "right" | "justify"
}

interface Props<T> {
    load: (status: JobStatus) => Promise<List<T> | undefined>
    columns: ColumnDefinition<T>[]
    renderRow: (item: T) => (string | JSX.Element)[]
    onClick: (item: T) => void
    defaultStatus: JobStatus
    statues: JobStatus[]
    emptyView?: string | JSX.Element
    search?: (term: string, list: List<T>) => List<T>
    hideFiltration?: boolean
}

const DataTable = <T extends any>(props: Props<T>) => {
    const {load} = props;

    const [list, setList] = useState<List<T>>()
    const [filteredList, setFilteredList] = useState<List<T>>()
    const [loading, setLoading] = useState(true)
    const [status, setStatus] = useState<JobStatus>(props.defaultStatus)

    const loadList = useCallback(async (s: JobStatus) => {
        // TODO: In case of search, show different empty view 'No result for xxx'
        const data = await load(s)
        setList(data)
        setFilteredList(data)
    }, [load])

    useEffect(() => {
        void async function fetchData() {
            // TODO: This is being called twice because of the setLoading.
            if (!list) {
                await loadList(status)
            }

            setLoading(false)
        }()
    }, [list, loadList, status])

    const emptyView = () => {
        // TODO: This shouldn't hide the status and search fields.
        if (props.emptyView && typeof props.emptyView !== 'string') {
            return props.emptyView
        }

        return (
            <VerticalFlexLayout
                height={"100%"}
                wrap={"nowrap"}
                justify={"center"}>
                <Box p={2}>
                    <Body1 align={"center"}>
                        {props.emptyView ?? strings.noDataAvailable}
                    </Body1>
                </Box>
            </VerticalFlexLayout>
        )
    }

    const onStatusChange = async (e: SelectChangeEvent) => {
        const value = e.target.value
        if (value !== status) {
            setStatus(value as JobStatus)
            await loadList(value as JobStatus)
        }
    }

    const onSearchChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value

        if (props.search && value && list) {
            // TODO: 'Showing x/x' should be updated properly.
            // TODO: This handling should be part of the DataTable itself.
            // TODO: If search value is not empty and the Status changes, the list is not shown properly.
            setFilteredList(props.search(value, list))
        } else {
            setFilteredList(list)
        }
    }

    // TODO: Loading should be shown on top of the current view; blocking what's behind it.
    if (loading) {
        return (<Loading/>)
    }

    return (
        <VerticalFlexLayout
            height={"100%"}
            wrap={"nowrap"}>
            {!props.hideFiltration &&
            <Box p={2} sx={{backgroundColor: "white"}}>
                <VerticalFlexLayout spacing={2}>
                    <HorizontalFlexLayout spacing={2} alignItems={"center"}>
                        <GridItem xs={12} sm={"auto"}>
                            <HorizontalFlexLayout spacing={2} alignItems={"center"}>
                                <Body2 color={colors.disabledText}>
                                    {strings.status}:
                                </Body2>
                                <GridItem grow={1}>
                                    <Select
                                        fullWidth
                                        value={status}
                                        sx={{minWidth: 120}}
                                        onChange={onStatusChange}>
                                        {props.statues.map(s =>
                                            <MenuItem
                                                key={s}
                                                value={s}>
                                                {strings[`${s}Status`]}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </GridItem>
                            </HorizontalFlexLayout>
                        </GridItem>
                        <GridItem xs={12} sm={"auto"}>
                            <InputField
                                placeholder={strings.search}
                                onChange={onSearchChange}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            sx={{padding: "4px"}}
                                            edge="end">
                                            <SearchIcon/>
                                        </IconButton>
                                    )
                                }}/>
                        </GridItem>
                    </HorizontalFlexLayout>
                    <Body2 color={colors.disabledText}>
                        {`Showing ${filteredList?.items.length ?? 0}/${filteredList?.total ?? 0}`}
                    </Body2>
                </VerticalFlexLayout>
            </Box>}
            <GridItem sx={{overflow: "auto", flexGrow: 1, backgroundColor: "#F9F9F9"}}>
                <Box sx={{width: "100%", height: "100%", display: "table", tableLayout: "fixed"}}>
                    {Boolean(list?.items?.length) ?
                        Boolean(filteredList?.items?.length) ?
                            <div className={"tao-table-container"}>
                                <table className={"tao-table-header"}>
                                    <tr>
                                        {props.columns.map((c, i) => (
                                            <th key={i}
                                                align={c.align ?? "center"}>
                                                <Heading6
                                                    color={colors.disabledText}>
                                                    {c.label}
                                                </Heading6>
                                            </th>
                                        ))}
                                    </tr>
                                </table>
                                <table className="tao-table">
                                    {filteredList!.items.map((item, index) => (
                                        <tr
                                            key={index}
                                            onClick={() => props.onClick(item)}>
                                            {props.renderRow(item).map((c, i) => {
                                                const a = props.columns.length > i ?
                                                    (props.columns[i].align ?? "center") :
                                                    "center"

                                                return (<td key={i} align={a}>{c}</td>)
                                            })}
                                        </tr>
                                    ))}
                                </table>
                            </div>
                            :
                            <VerticalFlexLayout
                                height={"100%"}
                                wrap={"nowrap"}
                                justify={"center"}>
                                <Box p={2}>
                                    <Body1 align={"center"}>
                                        {strings.noSearchResultsMessage}
                                    </Body1>
                                </Box>
                            </VerticalFlexLayout>
                        :
                        emptyView()
                    }
                </Box>
            </GridItem>
            {/*<GridItem alignSelf={"center"}>*/}
            {/*    <Pagination count={10} color="primary"/>*/}
            {/*</GridItem>*/}
        </VerticalFlexLayout>
    )
}

export default DataTable
