import React from "react";
import IconProps from "components/common/icons/IconProps";

const SearchIcon: React.FC<IconProps> = (props) => (
    <div className={"svg-container"}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size ?? "24"}
            height={props.size ?? "24"}
            viewBox="0 0 24 24">
            <path
                d="M10.5 16.5C11.2879 16.5 12.0682 16.3448 12.7961 16.0433C13.5241 15.7417 14.1855 15.2998 14.7427 14.7426C15.2998 14.1855 15.7418 13.5241 16.0433 12.7961C16.3448 12.0681 16.5 11.2879 16.5 10.5C16.5 9.71207 16.3448 8.93185 16.0433 8.2039C15.7418 7.47595 15.2998 6.81451 14.7427 6.25736C14.1855 5.70021 13.5241 5.25825 12.7961 4.95672C12.0682 4.65519 11.2879 4.5 10.5 4.5C8.90872 4.5 7.38259 5.13214 6.25737 6.25736C5.13216 7.38258 4.50002 8.9087 4.50002 10.5C4.50002 12.0913 5.13216 13.6174 6.25737 14.7426C7.38259 15.8679 8.90872 16.5 10.5 16.5V16.5ZM16.82 15.406L20.4 18.986C20.4955 19.0783 20.5716 19.1887 20.6239 19.3108C20.6762 19.4328 20.7037 19.564 20.7048 19.6968C20.7058 19.8296 20.6804 19.9613 20.63 20.0841C20.5797 20.207 20.5053 20.3186 20.4114 20.4124C20.3174 20.5062 20.2057 20.5804 20.0828 20.6306C19.9599 20.6808 19.8282 20.706 19.6954 20.7047C19.5626 20.7035 19.4314 20.6758 19.3095 20.6233C19.1875 20.5708 19.0772 20.4946 18.985 20.399L15.405 16.819C13.7975 18.0668 11.7748 18.6552 9.74877 18.4642C7.72273 18.2732 5.84562 17.3173 4.49957 15.7911C3.15351 14.2648 2.4397 12.2829 2.50344 10.2489C2.56718 8.2149 3.40368 6.28162 4.84266 4.84265C6.28164 3.40367 8.21492 2.56717 10.2489 2.50343C12.283 2.43968 14.2648 3.1535 15.7911 4.49955C17.3173 5.8456 18.2732 7.72271 18.4642 9.74875C18.6552 11.7748 18.0669 13.7975 16.819 15.405L16.82 15.406Z"
                fill={props.color ?? "black"}/>
        </svg>
    </div>
)

export default SearchIcon
