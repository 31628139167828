import React, {FC} from "react"
import {Box, Paper} from "@mui/material";
import Offer from "models/Offer";
import {Body1, Heading4} from "components/common/Typography";
import strings from "res/strings";
import {handleFieldErrors, moneyString} from "util/Utilities";
import Api from "data/remote/Api";
import ErrorResponse from "data/remote/models/ErrorResponse";
import JobStatus from "models/types/JobStatus";
import DataTable2 from "components/common/DataTable2";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import ContractorHeader from "components/contractor/ContractorHeader";
import Status from "components/common/Status";

const statuses: JobStatus[] = [
    "all",
    "draft",
    "pending",
    "accepted",
    "rejected",
    "active",
    "closed",
    "cancelled"
]

// TODO: Move this to OfferRowItem or something.
const renderRow = (o: Offer): JSX.Element => (
    <Paper variant={"outlined"}>
        <Box p={1}>
            <HorizontalFlexLayout
                spacing={1}
                wrap={"nowrap"}
                alignItems={"center"}>
                <GridItem grow={1}>
                    <ContractorHeader
                        contractor={o.contractor}
                        subtitle={`${o.name} (${moneyString(o.budget)})`}/>
                </GridItem>
                <Status value={o.status}/>
            </HorizontalFlexLayout>
        </Box>
    </Paper>
)

const emptyView = (
    <VerticalFlexLayout
        height={"100%"}
        wrap={"nowrap"}
        justify={"center"}>
        <Heading4 align={"center"}>
            {strings.agentNoOffersTitle}
        </Heading4>
        <Body1 align={"center"}>
            {strings.agentNoOffersMessage}
        </Body1>
    </VerticalFlexLayout>
)

interface Props {
    jobId?: string
    onClick: (offer: Offer) => void
    defaultStatus?: JobStatus
}

// TODO: Rename
const OffersList2: FC<Props> = (props) => {
    const load = async (status: JobStatus) => {
        const s = status === "accepted" ? status : undefined
        return await Api.offers
            .getOffers(s, props.jobId)
            .catch(res => {
                handleFieldErrors(res.data as ErrorResponse)
                return undefined
            })
    }

    return (
        <DataTable2
            load={load}
            renderRow={renderRow}
            onClick={props.onClick}
            emptyView={emptyView}
            defaultStatus={props.defaultStatus ?? "pending"}
            statues={statuses}/>
    )
}

export default OffersList2
