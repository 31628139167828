import AuthApi from "data/remote/AuthApi";
import ApiConfig from "util/api/models/ApiConfig";
import JobsApi from "data/remote/JobsApi";
import OffersApi from "data/remote/OffersApi";
import ProposalsApi from "data/remote/ProposalsApi";
import MessagingApi from "data/remote/MessagingApi";
import UsersApi from "data/remote/UsersApi";
import GeneralApi from "data/remote/GeneralApi";
import MediaApi from "data/remote/MediaApi";

const config: ApiConfig = {
    baseUrl: window.location.origin + "/api/",
    clientId: process.env.REACT_APP_AWS_CLIENT_ID as string
}

class Api {
    private static _instance: Api
    private _authApi?: AuthApi
    private _offersApi?: OffersApi
    private _jobsApi?: JobsApi
    private _proposalsApi?: ProposalsApi
    private _usersApi?: UsersApi
    private _messagingApi?: MessagingApi
    private _generalApi?: GeneralApi
    private _mediaApi?: MediaApi

    public static get instance() {
        return Api._instance || (Api._instance = new this())
    }

    get auth() {
        if (!this._authApi) {
            this._authApi = new AuthApi(config);
        }
        return this._authApi;
    }

    get offers() {
        if (!this._offersApi) {
            this._offersApi = new OffersApi(config);
        }
        return this._offersApi;
    }

    get jobs() {
        if (!this._jobsApi) {
            this._jobsApi = new JobsApi(config);
        }
        return this._jobsApi;
    }

    get proposals() {
        if (!this._proposalsApi) {
            this._proposalsApi = new ProposalsApi(config);
        }
        return this._proposalsApi;
    }

    get users() {
        if (!this._usersApi) {
            this._usersApi = new UsersApi(config);
        }
        return this._usersApi;
    }

    get messaging() {
        if (!this._messagingApi) {
            this._messagingApi = new MessagingApi(config);
        }
        return this._messagingApi;
    }

    get general() {
        if (!this._generalApi) {
            this._generalApi = new GeneralApi(config);
        }
        return this._generalApi;
    }

    get media() {
        if (!this._mediaApi) {
            this._mediaApi = new MediaApi(config);
        }
        return this._mediaApi;
    }
}

const instance = Api.instance
export default instance
