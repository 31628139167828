import React from "react";
import IconProps from "components/common/icons/IconProps";

const ArrowDownIcon: React.FC<IconProps> = (props) => (
    <div className={"svg-container"}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size ?? "24"}
            height={props.size ?? "24"}
            viewBox="0 0 24 24">
            <path
                d="M12.0711 13.314L17.0211 8.364C17.1134 8.26849 17.2237 8.19231 17.3457 8.1399C17.4677 8.08749 17.599 8.05991 17.7317 8.05875C17.8645 8.0576 17.9962 8.0829 18.1191 8.13318C18.242 8.18346 18.3536 8.25772 18.4475 8.35161C18.5414 8.4455 18.6157 8.55715 18.666 8.68005C18.7162 8.80295 18.7415 8.93463 18.7404 9.06741C18.7392 9.20018 18.7117 9.3314 18.6592 9.45341C18.6068 9.57541 18.5307 9.68576 18.4351 9.778L12.7781 15.435C12.5906 15.6225 12.3363 15.7278 12.0711 15.7278C11.806 15.7278 11.5517 15.6225 11.3641 15.435L5.70714 9.778C5.61163 9.68576 5.53545 9.57541 5.48304 9.45341C5.43063 9.3314 5.40305 9.20018 5.40189 9.06741C5.40074 8.93463 5.42604 8.80295 5.47632 8.68005C5.5266 8.55715 5.60086 8.4455 5.69475 8.35161C5.78864 8.25772 5.90029 8.18346 6.02319 8.13318C6.14609 8.0829 6.27777 8.0576 6.41054 8.05875C6.54332 8.05991 6.67454 8.08749 6.79655 8.1399C6.91855 8.19231 7.0289 8.26849 7.12114 8.364L12.0711 13.314Z"
                fill={props.color ?? "black"}/>
        </svg>
    </div>
)

export default ArrowDownIcon
