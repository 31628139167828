const paths = {
    signIn: "/account/sign-in",
    signUp: "/account/sign-up",
    forgotPassword: "/account/forgot-password",
    termsConditions: "/legal/terms-and-conditions",
    privacyPolicy: "/legal/privacy-policy",
    adminClients: "/console/clients",
    adminContractors: "/console/contractors",
    adminAssignments: "/console/assignments",
    contractorProfile: "/contractor/profile",
    editContractorProfile: "/contractor/profile/edit",

    adminJobDetails: (companyId: string, jobId: string, tab?: "#offers" | "#proposals") =>
        `/console/assignments/${companyId}/${jobId}${tab || ''}`,
}

export default paths
