import {AxiosResponse} from "axios";
import ApiClient from "util/api/client/ApiClient";
import ApiResponseOptions from "util/api/models/ApiResponseOptions";
import ApiConfig from "util/api/models/ApiConfig";

class BaseApi {
    protected readonly _config: ApiConfig

    constructor(config: ApiConfig) {
        this._config = config
    }

    protected async get<T>(path: string, options?: ApiResponseOptions) {
        return this.handleResponse<T>(async () => {
            return await ApiClient.get<T>(this._config.baseUrl + path)
        }, options)
    }

    protected async post<T>(path: string, body?: any, options?: ApiResponseOptions) {
        return this.handleResponse<T>(async () => {
            return await ApiClient.post<T>(this._config.baseUrl + path, body)
        }, options)
    }

    protected async put<T>(path: string, body?: any, options?: ApiResponseOptions) {
        return this.handleResponse<T>(async () => {
            return await ApiClient.put<T>(this._config.baseUrl + path, body)
        }, options)
    }

    protected async patch<T>(path: string, body?: any, options?: ApiResponseOptions) {
        return this.handleResponse<T>(async () => {
            return await ApiClient.patch<T>(this._config.baseUrl + path, body)
        }, options)
    }

    protected async delete<T>(path: string, options?: ApiResponseOptions) {
        return this.handleResponse<T>(async () => {
            return await ApiClient.delete<T>(this._config.baseUrl + path)
        }, options)
    }

    protected buildUrl(url: string, params: Record<string, string | undefined>) {
        let query = ""
        const parts = url.split('?')

        if (parts.length > 1) {
            url = parts[0]
            query = parts[1].split('&').map(p => {
                const segments = p.split('=').filter(s => s)
                return segments.length > 1 ? segments.join('=') : ""
            }).filter(s => s).join('&')
        }

        const segments = Object.keys(params).filter(k => k && params[k])
            .map(k => `${k.trim()}=${params[k]!.trim()}`).join('&')

        query = [query, segments].filter(s => s).join('&')
        return [url, query].filter(s => s).join('?')
    }

    protected handleResponse<T>(callback: (() => Promise<AxiosResponse<T>>), options?: ApiResponseOptions) {
        return new Promise<T | undefined>(async (resolve, reject) => {
            await callback()
                .then(res => resolve(res.data))
                .catch(err => {
                    console.log("API Error - Response", err)
                    console.log("API Error - Data", err.response?.data)
                    if (err.response?.data?.errors) {
                        console.table(err.response?.data?.errors)
                    }
                    return reject(err.response)
                })
        })
    }
}

export default BaseApi
