import React, {FC, useState} from "react";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import {Box, Dialog, DialogContent, Paper} from "@mui/material";
import {Body1, Body2, Heading3, Heading4, Heading5} from "components/common/Typography";
import Button from "components/common/Button";
import InputField from "components/common/InputField";
import Job from "models/Job";
import Api from "data/remote/Api";
import {getUuid, handleFieldErrors, toDateInput} from "util/Utilities";
import ErrorResponse from "data/remote/models/ErrorResponse";
import {Controller, useForm, FormProvider} from "react-hook-form";
import strings from "res/strings";
import DisplayField from "components/common/DisplayField";
import BudgetField from "components/common/BudgetField";
import LocationField from "components/common/LocationField";
import IconButton from "components/common/IconButton";
import PlusIcon from "components/common/icons/PlusIcon";
import CloseIcon from "components/common/icons/CloseIcon";
import {useAlert} from "contexts/AlertContext";
import LookupFields from "components/common/LookupFields";
import jobTypes from "res/jobTypes";

interface Props {
    job?: Job
    companyId?: string
    onSuccess: (job: Job) => void
    isAdmin?: boolean
}

interface DocumentVm {
    id: string
    title: string
    file?: File
    new: boolean
}

const UpsertJobControl: FC<Props> = (props) => {
    const isStatusDraft = !props.job?.status || props.job?.status === "draft"
    const defaultValues: Job | undefined = props.job ? {
        ...props.job,
        deadline: toDateInput(props.job.deadline)
    } : undefined


    const docs = props.job?.documents?.map(d => ({id: d.id, title: d.title, new: false})) ?? []

    const alert = useAlert()
    const [loading, setLoading] = useState(false)
    const [isDraft, setIsDraft] = useState(true)
    const [document, setDocument] = useState<DocumentVm>()
    const [documents, setDocuments] = useState<DocumentVm[]>(docs.length ? docs : [{
        id: getUuid(), title: "", new: true
    }])
    const form = useForm<Job>({defaultValues: defaultValues})
    form.setValue("type", form.getValues("type"))

    const onSubmit = form.handleSubmit(async fields => {
        if (loading) {
            return
        }

        form.clearErrors()
        fields.deadline = new Date(fields.deadline).toISOString()
        fields.companyId = props.companyId ?? ""

        if (fields.budget?.value) {
            fields.budget.value = Number.parseFloat(fields.budget.value.toString())
        } else {
            fields.budget.currency = ""
        }

        setLoading(true)

        if (fields.id) {
            await Api.jobs
                .updateJobAsync(fields)
                .then(async () => await requestProposal(fields))
                .catch(res => handleFieldErrors(res.data as ErrorResponse, form.setError))
        } else {
            await Api.jobs
                .createJobAsync(fields)
                .then(async j => {
                    fields.id = j!.id
                    await requestProposal(fields)
                })
                .catch(res => handleFieldErrors(res.data as ErrorResponse, form.setError))
        }

        setLoading(false)
    })

    async function requestProposal(job: Job) {
        const docs = documents.filter(d => d.file)

        if (docs.length) {
            const success = await Api.media
                .getUploadInstructionsAsync({
                    resource: "jobs",
                    id: job.id,
                    documents: docs.map(d => ({
                        id: "",
                        title: d.title ?? d.file!.name,
                        contentType: d.file!.type,
                        filename: d.file!.name,
                        contentLength: d.file!.size,
                        type: "Document",
                        url: ""
                    }))
                }, job.companyId)
                .then(data => {
                    if (data?.items) {
                        data.items.forEach(async (item, index) => {
                            // TODO: Check the status of each upload and show errors if any.
                            await Api.media.uploadAsync(item, docs[index].file!)
                        })
                        return true
                    }
                    return false
                })
                .catch(res => {
                    handleFieldErrors(res.data as ErrorResponse)
                    return false
                })

            if (!success) {
                return
            }
        }

        if (isStatusDraft && !isDraft) {
            await Api.jobs
                .requestProposalAsync(job.id, props.companyId)
                .then(() => {
                    job.status = "pending"
                    props.onSuccess(job)
                })
                .catch(res => {
                    handleFieldErrors(res.data as ErrorResponse, form.setError)
                    setLoading(false)
                })
        } else {
            job.status = "draft"
            props.onSuccess(job)
        }
    }

    const deleteDocument = async () => {
        if (!loading && document) {
            if (!props.job?.id || document.new) {
                setDocument(undefined)
                setDocuments(ps => ps.filter(d1 => d1.id !== document.id))
            } else {
                setLoading(true)
                await Api.jobs
                    .deleteDocumentAsync(props.job?.id, document.id, props.companyId)
                    .then(async () => {
                        setDocument(undefined)
                        setDocuments(ps => ps.filter(d1 => d1.id !== document.id))
                    })
                    .catch(res => handleFieldErrors(res.data as ErrorResponse, form.setError))
                setLoading(false)
            }
        }
    }

    return (<>
        <FormProvider {...form}>
            <form onSubmit={onSubmit}>
                <VerticalFlexLayout spacing={2}>
                    <Box p={2} sx={{backgroundColor: "white"}}>
                        <HorizontalFlexLayout spacing={2}>
                            <GridItem sx={{flexGrow: 1}}>
                                <Heading3>
                                    {strings.startAProject}
                                </Heading3>
                            </GridItem>
                            {isStatusDraft &&
                            <Button
                                type="submit"
                                isBusy={isDraft && loading}
                                variant={"outlined"}
                                color={"secondary"}
                                onClick={() => setIsDraft(true)}>
                                {strings.saveAsDraft}
                            </Button>}
                            <Button
                                type="submit"
                                isBusy={!isDraft && loading}
                                variant={"contained"}
                                onClick={() => setIsDraft(false)}>
                                {isStatusDraft ? strings.submitAssignment : strings.save}
                            </Button>
                        </HorizontalFlexLayout>
                    </Box>
                    <Box p={2}>
                        <HorizontalFlexLayout spacing={2}>
                            <GridItem xs={12} md={props.isAdmin ? 6 : undefined}>
                                <Paper variant={"outlined"}>
                                    <Box p={2}>
                                        <VerticalFlexLayout spacing={2}>
                                            <Heading5>
                                                {strings.jobNameTip}
                                            </Heading5>
                                            <Controller
                                                name="name"
                                                control={form.control}
                                                render={({field, fieldState: {error}}) => (
                                                    <InputField
                                                        placeholder={strings.jobNamePlaceholder}
                                                        required
                                                        fieldError={error}
                                                        {...field} />
                                                )}/>
                                        </VerticalFlexLayout>
                                    </Box>
                                </Paper>
                            </GridItem>
                            {props.isAdmin &&
                            <GridItem xs={12} md={6}>
                                <Paper variant={"outlined"}>
                                    <Box p={2}>
                                        <VerticalFlexLayout spacing={2}>
                                            <Heading5>
                                                {strings.projectType}
                                            </Heading5>
                                            <Controller
                                                name="type"
                                                control={form.control}
                                                render={({field, fieldState: {error}}) => (
                                                    <LookupFields
                                                        items={jobTypes}
                                                        required
                                                        fieldError={error}
                                                        {...field}/>
                                                )}/>
                                        </VerticalFlexLayout>
                                    </Box>
                                </Paper>
                            </GridItem>}
                            <GridItem xs={12} md={6} lg={4}>
                                <Paper variant={"outlined"}>
                                    <Box p={2}>
                                        <VerticalFlexLayout spacing={1}>
                                            <DisplayField
                                                title={strings.deadline}
                                                content={strings.jobDeadlineTip}/>
                                            <Controller
                                                name="deadline"
                                                control={form.control}
                                                render={({field, fieldState: {error}}) => (
                                                    <InputField
                                                        type={"date"}
                                                        required
                                                        fieldError={error}
                                                        {...field} />
                                                )}/>
                                        </VerticalFlexLayout>
                                    </Box>
                                </Paper>
                            </GridItem>
                            <GridItem xs={12} md={6} lg={4}>
                                <Paper variant={"outlined"}>
                                    <Box p={2}>
                                        <VerticalFlexLayout spacing={1}>
                                            <DisplayField
                                                title={strings.budget}
                                                content={strings.jobBudgetTip}/>
                                            <BudgetField namespace="budget"/>
                                        </VerticalFlexLayout>
                                    </Box>
                                </Paper>
                            </GridItem>
                            <GridItem xs={12} md={12} lg={4}>
                                <Paper variant={"outlined"}>
                                    <Box p={2}>
                                        <VerticalFlexLayout spacing={1}>
                                            <DisplayField
                                                title={strings.location}
                                                content={strings.jobLocationTip}/>
                                            <LocationField/>
                                        </VerticalFlexLayout>
                                    </Box>
                                </Paper>
                            </GridItem>
                            <GridItem xs={12}>
                                <Paper variant={"outlined"}>
                                    <Box p={2}>
                                        <VerticalFlexLayout spacing={1}>
                                            <DisplayField
                                                title={strings.requirements}
                                                content={strings.jobRequirementsTip}/>
                                            <Controller
                                                name="description"
                                                control={form.control}
                                                render={({field, fieldState: {error}}) => (
                                                    <InputField
                                                        required
                                                        multiline
                                                        fieldError={error}
                                                        {...field} />
                                                )}/>
                                        </VerticalFlexLayout>
                                    </Box>
                                </Paper>
                            </GridItem>
                            <GridItem xs={12}>
                                <Paper variant={"outlined"}>
                                    <Box p={2}>
                                        <VerticalFlexLayout spacing={1}>
                                            <HorizontalFlexLayout
                                                spacing={1}
                                                wrap={"nowrap"}>
                                                <GridItem grow={1}>
                                                    <DisplayField
                                                        title={strings.documents}
                                                        content={strings.jobDocumentsTip}/>
                                                </GridItem>
                                                <IconButton
                                                    variant={"contained"}
                                                    onClick={() => setDocuments(ps => [...ps, {
                                                        id: getUuid(),
                                                        title: "",
                                                        new: true
                                                    }])}>
                                                    <PlusIcon/>
                                                </IconButton>
                                            </HorizontalFlexLayout>
                                            {documents.map(d => (
                                                <VerticalFlexLayout key={d.id}>
                                                    <HorizontalFlexLayout
                                                        key={d.id}
                                                        spacing={1}
                                                        wrap={"nowrap"}
                                                        alignItems={"center"}>
                                                        <GridItem style={{flexGrow: 1}}>
                                                            <InputField
                                                                disabled={!d.new}
                                                                value={documents.find(d1 => d1.id === d.id)?.title}
                                                                placeholder={strings.name}
                                                                onChange={e => {
                                                                    setDocuments(ps => ps.map(d1 => {
                                                                        if (d1.id === d.id) {
                                                                            d1.title = e.target.value
                                                                        }
                                                                        return d1
                                                                    }))
                                                                }}/>
                                                        </GridItem>
                                                        {d.new &&
                                                        <Button variant="contained" component="label">
                                                            Select
                                                            <input
                                                                hidden
                                                                type="file"
                                                                onChange={e => {
                                                                    if (e.currentTarget.files?.length) {
                                                                        const file = e.currentTarget.files[0]
                                                                        if (file.size > (10 * 1024 * 1024)) {
                                                                            e.currentTarget.value = ""
                                                                            alert.enqueue({
                                                                                message: "Maximum allowed file size is 10 MB.",
                                                                                severity: "error"
                                                                            })
                                                                        } else {
                                                                            setDocuments(ps => ps.map(d1 => {
                                                                                if (d1.id === d.id) {
                                                                                    d1.file = file
                                                                                    if (!d1.title) {
                                                                                        d1.title = file.name
                                                                                    }
                                                                                }
                                                                                return d1
                                                                            }))
                                                                        }
                                                                    }
                                                                }}/>
                                                        </Button>}
                                                        <IconButton onClick={() => {
                                                            if (!d.file) {
                                                                setDocuments(ps => ps.filter(d1 => d1.id !== d.id))
                                                            } else {
                                                                setDocument(d)
                                                            }
                                                        }}>
                                                            <CloseIcon/>
                                                        </IconButton>
                                                    </HorizontalFlexLayout>
                                                    <Body2 fontSize={12}>
                                                        {d.file?.name}
                                                    </Body2>
                                                </VerticalFlexLayout>
                                            ))}
                                        </VerticalFlexLayout>
                                    </Box>
                                </Paper>
                            </GridItem>
                        </HorizontalFlexLayout>
                    </Box>
                </VerticalFlexLayout>
            </form>
        </FormProvider>

        <Dialog
            open={Boolean(document)}
            fullWidth
            maxWidth={"xs"}
            onClose={() => setDocument(undefined)}>
            <DialogContent>
                <VerticalFlexLayout spacing={3}>
                    <Heading4>Deleting Document</Heading4>
                    <Body1>{`Are you sure you want to delete '${document?.title}'?`}</Body1>
                    <HorizontalFlexLayout spacing={2}>
                        <GridItem xs={6}>
                            <Button
                                fullWidth
                                onClick={() => setDocument(undefined)}
                                variant={"outlined"}
                                color={"secondary"}>
                                {strings.cancel}
                            </Button>
                        </GridItem>
                        <GridItem xs={6}>
                            <Button
                                fullWidth
                                variant={"contained"}
                                color={"error"}
                                isBusy={loading}
                                onClick={deleteDocument}>
                                {strings.delete}
                            </Button>
                        </GridItem>
                    </HorizontalFlexLayout>
                </VerticalFlexLayout>
            </DialogContent>
        </Dialog>
    </>)
}

export default UpsertJobControl
