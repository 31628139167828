import React, {FC, useCallback, useEffect, useState} from "react"
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator"
import {Avatar, Box, Divider, Drawer, Paper} from "@mui/material";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import {Body2} from "components/common/Typography";
import Api from "data/remote/Api";
import {handleFieldErrors} from "util/Utilities";
import ErrorResponse from "data/remote/models/ErrorResponse";
import MessagingTopic from "models/MessagingTopic";
import List from "models/List";
import colors from "res/colors";
import MessagingControl from "components/MessagingControl";
import {useMessaging} from "contexts/MessagingContext";
import Message from "models/Message";
import LookupFields from "components/common/LookupFields";
import IconButton from "components/common/IconButton";
import BackIcon from "components/common/icons/BackIcon";
import {useAuth} from "contexts/AuthContext";

const Messages: FC = () => {
    const {isInRole} = useAuth()
    const messaging = useMessaging()
    const [type, setType] = useState("Users")
    const [topics, setTopics] = useState<List<MessagingTopic>>()
    const [topic, setTopic] = useState<MessagingTopic>()

    const isAdmin = isInRole("agent")

    const loadMessages = useCallback(async () => {
        await Api.messaging
            .getTopics(type === "Users" ? ["user"] : ["newJob", "job", "offer", "proposal"])
            .then(d => setTopics(d))
            .catch(res => handleFieldErrors(res.data as ErrorResponse))
    }, [type])

    const onMessage = useCallback((e: MessageEvent) => {
        if (e) {
            // TODO: use this for model, better to understand later.
            const data = JSON.parse(e.data)
            if (data.action === "messageSent") {
                const m: Message = data.data
                if (!isAdmin || m.topicId.startsWith(type === "Users" ? "user" : "")) {
                    // TODO: handle new message that doesn't exist in the list
                    setTopics(ps => !ps ? undefined : {
                        ...ps,
                        items: ps.items.map(t => {
                            if (t.id === m.topicId) {
                                t.message = m

                                if (topic?.id !== m.topicId) {
                                    t.isNew = true
                                }
                            }
                            return t
                        })
                    })
                }
            }
        }
    }, [isAdmin, type, topic])

    const close = useCallback(() => {
        messaging.removeCallback("NewMessage")
    }, [messaging])

    messaging.addCallback("NewMessage", onMessage)

    useEffect(() => {
        void async function fetchData() {
            await loadMessages()
        }()

        return () => close()
    }, [loadMessages, close])

    const drawer = (
        <VerticalFlexLayout
            height={"100%"}
            wrap={"nowrap"}
            sx={{backgroundColor: "#F9F9F9"}}>
            <Divider/>
            {isInRole("agent") &&
            <GridItem>
                <Box p={1} sx={{backgroundColor: "white"}}>
                    <LookupFields
                        value={type}
                        onChange={e => setType(e.target.value as string)}
                        items={["Users", "Assignments"]}/>
                </Box>
                <Divider/>
            </GridItem>}
            <Box p={1}>
                <VerticalFlexLayout spacing={1}>
                    {topics?.items?.sort((a, b) =>
                        b.message?.created.localeCompare(a.message?.created)).map(t =>
                        <Paper
                            key={t.id}
                            variant={"outlined"}
                            onClick={() => {
                                setTopic(t)
                                setTopics(ps => !ps ? undefined : {
                                    ...ps,
                                    items: ps.items.map(t1 => {
                                        if (t1.id === t.id) {
                                            t1.isNew = false
                                        }
                                        return t1
                                    })
                                })
                            }}
                            sx={{
                                cursor: "pointer",
                                "&:hover": {
                                    borderColor: colors.primary,
                                    backgroundColor: colors.primary + "40"
                                }
                            }}>
                            <Box p={1}>
                                <HorizontalFlexLayout
                                    spacing={2}
                                    wrap={"nowrap"}
                                    alignItems={"center"}>
                                    <Avatar
                                        variant={"circular"}
                                        src={t.users.find(u => u.id === t.ownerId)?.image}
                                        alt={t.ownerName}
                                        sx={{
                                            margin: "auto",
                                            width: 40,
                                            height: 40,
                                            backgroundColor:
                                            colors.pearlWhite,
                                            color: colors.text
                                        }}>
                                        {t.ownerName?.slice(0, 2)}
                                    </Avatar>
                                    <GridItem grow={1}>
                                        <VerticalFlexLayout>
                                            <Body2
                                                color={colors.copy}
                                                fontWeight={500}>
                                                {t.title}
                                            </Body2>
                                            <HorizontalFlexLayout
                                                spacing={1}
                                                alignItems={"center"}
                                                wrap={"nowrap"}>
                                                <GridItem grow={1}>
                                                    <Body2 color={colors.copy}>
                                                        {t.message?.content}
                                                    </Body2>
                                                </GridItem>
                                                {t.isNew &&
                                                <Box
                                                    height={12}
                                                    width={12}
                                                    bgcolor={colors.primary}
                                                    borderRadius={"50%"}>
                                                </Box>}
                                            </HorizontalFlexLayout>
                                        </VerticalFlexLayout>
                                    </GridItem>
                                </HorizontalFlexLayout>
                            </Box>
                        </Paper>
                    )}
                </VerticalFlexLayout>
            </Box>
        </VerticalFlexLayout>
    )

    return (<>
        <HtmlMetaDecorator
            title={"Chats"}/>

        <HorizontalFlexLayout
            height={"100%"}
            wrap={"nowrap"}>
            <GridItem sm={5} md={4} lg={3} sx={{
                display: {xs: 'none', sm: 'block'},
                flexGrow: 1
            }}>
                {drawer}
            </GridItem>
            <Divider orientation={"vertical"}/>
            <GridItem grow={1}>
                {Boolean(topic) &&
                <VerticalFlexLayout
                    wrap={"nowrap"}
                    height={"100%"}>
                    <Divider/>
                    <Box py={1}>
                        <HorizontalFlexLayout
                            spacing={2}
                            wrap={"nowrap"}
                            alignItems={"center"}>
                            <IconButton
                                onClick={() => setTopic(undefined)}
                                sx={{display: {sm: 'none'}}}>
                                <BackIcon/>
                            </IconButton>
                            <Avatar
                                variant={"circular"}
                                src={topic!.users.find(u => u.id === topic!.ownerId)?.image}
                                alt={topic!.ownerName}
                                sx={{
                                    margin: "auto",
                                    width: 40,
                                    height: 40,
                                    backgroundColor:
                                    colors.pearlWhite,
                                    color: colors.text
                                }}>
                                {topic!.ownerName?.slice(0, 2)}
                            </Avatar>
                            <Body2
                                color={colors.copy}
                                fontWeight={500}>
                                {topic!.title}
                            </Body2>
                        </HorizontalFlexLayout>
                    </Box>
                    <GridItem grow={1} zIndex={2}>
                        <MessagingControl
                            topic={topic}
                            kind={topic!.kind}/>
                    </GridItem>
                </VerticalFlexLayout>}
            </GridItem>
        </HorizontalFlexLayout>

        <Drawer
            variant="temporary"
            open={!topic}
            ModalProps={{keepMounted: true}}
            PaperProps={{sx: {width: "100%"}}}
            sx={{display: {xs: 'block', sm: 'none'}, zIndex: 1}}>
            <Box pt={8} height={"100%"}>
                {drawer}
            </Box>
        </Drawer>
    </>)
}

export default Messages
