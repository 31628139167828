import BaseApi from "util/api/BaseApi";
import Offer from "models/Offer";
import List from "models/List";
import JobStatus from "models/types/JobStatus";
import CrewMember from "models/CrewMember";

class OffersApi extends BaseApi {
    async getOffer(id: string, jobId: string, contractorId: string = ""):
        Promise<Offer | undefined> {
        const url = this.buildUrl(`offers/${id}`, {
            jobId, contractorId
        })
        return await this.get<Offer>(url)
    }

    async getOffers(status?: JobStatus, jobId?: string, contractorId?: string):
        Promise<List<Offer> | undefined> {
        const url = this.buildUrl(`offers`, {
            jobId, contractorId,
            status: status === "all" ? undefined : status
        })
        return await this.get<List<Offer>>(url)
    }

    async createOffer(offer: Offer): Promise<Offer | undefined> {
        return await this.post<Offer>(`offers`, offer)
    }

    async updateOffer(offer: Offer): Promise<Offer | undefined> {
        return await this.put<Offer>(`offers/${offer.id}`, offer)
    }

    async createCrewMember(offerId: string, jobId: string, contractorId: string, member: CrewMember):
        Promise<CrewMember | undefined> {
        return await this.post<CrewMember>(`offers/${offerId}/crew`, {
            jobId, contractorId, ...member
        })
    }

    async updateCrewMember(offerId: string, jobId: string, contractorId: string, member: CrewMember):
        Promise<CrewMember | undefined> {
        return await this.put<CrewMember>(`offers/${offerId}/crew/${member.id}`, {
            jobId, contractorId, ...member
        })
    }

    async deleteCrewMember(id: string, offerId: string, jobId: string):
        Promise<CrewMember | undefined> {
        const url = this.buildUrl(`offers/${offerId}/crew/${id}`, {jobId})
        return await this.delete<CrewMember>(url)
    }

    async updateStatus(
        id: string,
        jobId: string,
        status: "accept" | "reject",
        reason?: string,
        contractorId?: string
    ): Promise<any> {
        const url = this.buildUrl(`offers/${id}/${status}`, {contractorId})
        return await this.post<any>(url, {jobId, reason})
    }

    async sendToContractor(offerId: string, jobId: string, contractorId: string): Promise<any> {
        const url = this.buildUrl(`offers/${offerId}/submit`, {
            contractorId
        })
        return await this.post<any>(url, {jobId})
    }
}

export default OffersApi
