import React, {FC, useEffect} from "react"
import {Box, Typography} from "@mui/material"
import {Link, useNavigate} from "react-router-dom"
import {VerticalFlexLayout} from "components/common/FlexLayout"
import paths from "res/paths"
import strings from "res/strings"
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator"
import SignUpControl from "components/account/SignUpControl";
import {useAuth} from "contexts/AuthContext";

const SignUp: FC = () => {
    const {identity} = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (identity.roles?.length) {
            let path = "/"
            switch (identity.roles[0]) {
                case "agent":
                    path = "/console"
                    break
                case "company":
                    path = "/company"
                    break
                case "contractor":
                    path = paths.editContractorProfile + "?state=new"
                    break
            }
            navigate(path, {replace: true})
        }
    }, [identity, navigate])

    return (<>
        <HtmlMetaDecorator title={strings.getStarted}/>

        <Box padding={4} maxWidth={450} margin={"auto"}>
            <VerticalFlexLayout spacing={3}>
                <Link to={"/"}>
                    <img src={process.env.PUBLIC_URL + "/full-logo.svg"} alt={"logo"}
                         style={{width: "100%", maxWidth: 250, paddingBottom: 32}}/>
                </Link>
                <Typography component="h1" variant="h4">
                    <strong>{strings.getStarted} As</strong>
                </Typography>
                <SignUpControl/>
                <Box textAlign={"center"} pt={6}>
                    <Link to={paths.termsConditions}>
                        {strings.termsConditions}
                    </Link>
                </Box>
            </VerticalFlexLayout>
        </Box>
    </>)
}

export default SignUp
