import React, {FC, PropsWithChildren} from 'react'
import {Routes, Route, useNavigate} from "react-router-dom"
import NotFound from "pages/NotFound"
import OfferDetails from "pages/contractor/OfferDetails";
import Offers from "pages/contractor/Offers";
import AppBarContainer from "components/common/AppBarContainer";
import Profile from "pages/contractor/Profile";
import Messages from "pages/Messages";
import {Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import TaskIcon from "components/common/icons/TaskIcon";
import strings from "res/strings";
import CommentIcon from "components/common/icons/CommentIcon";
import PersonIcon from "components/common/icons/PersonIcon";
import paths from "res/paths";

const ContractorRouterWrapper: FC<PropsWithChildren> = (props) => {
    let navigate = useNavigate()

    const list = (
        <Box>
            <List>
                <ListItem>
                    <ListItemButton onClick={() => navigate("/contractor/offers")}>
                        <ListItemIcon>
                            <TaskIcon/>
                        </ListItemIcon>
                        <ListItemText primary={strings.assignments}/>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton onClick={() => navigate("/contractor/chats")}>
                        <ListItemIcon>
                            <CommentIcon/>
                        </ListItemIcon>
                        <ListItemText primary={"Chats"}/>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton onClick={() => navigate(paths.contractorProfile)}>
                        <ListItemIcon>
                            <PersonIcon/>
                        </ListItemIcon>
                        <ListItemText primary={strings.profile}/>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    )

    return (
        <AppBarContainer drawerList={list}>
            {props.children}
        </AppBarContainer>
    )
}

const ContractorRouter: FC = () => {
    return (
        <ContractorRouterWrapper>
            <Routes>
                <Route path="/offers/:jobId/:id" element={<OfferDetails/>}/>
                <Route path="/offers" element={<Offers/>}/>
                <Route path="/profile" element={<Profile/>}/>
                <Route path="/profile/edit" element={<Profile/>}/>
                <Route path="chats" element={<Messages/>}/>
                <Route path="/" element={<Offers/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </ContractorRouterWrapper>
    )
}

export default ContractorRouter
