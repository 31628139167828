import React, {PropsWithChildren} from "react"
import {Box} from "@mui/material"

interface Props {
    ratio?: number
    width?: string | number
    maxWidth?: string | number
    height?: string | number
    maxHeight?: string | number
}

const RatioContainer: React.FC<PropsWithChildren<Props>> = (props) => {
    const classes = {
        root: {
            margin: "auto",
            width: props.width,
            height: props.height,
            maxWidth: props.maxWidth,
            maxHeight: props.maxHeight
        },
        container: {
            position: "relative",
            width: "100%",
            paddingTop: `${100 / (props.ratio ?? 1)}%`
        },
        content: {
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        }
    }

    return (
        <Box sx={classes.root}>
            {props.ratio ?
                <Box sx={classes.container}>
                    <Box sx={classes.content}>
                        {props.children}
                    </Box>
                </Box>
                :
                <>{props.children}</>
            }
        </Box>
    )
}

export default RatioContainer
