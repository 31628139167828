import React, {FC} from "react"
import Offer from "models/Offer";
import {Body1, Body2, Heading4, Heading6} from "components/common/Typography";
import DataTable, {ColumnDefinition} from "components/common/DataTable";
import strings from "res/strings";
import colors from "res/colors";
import {handleFieldErrors, toAge, toShortDate} from "util/Utilities";
import Api from "data/remote/Api";
import ErrorResponse from "data/remote/models/ErrorResponse";
import JobStatus from "models/types/JobStatus";
import List from "models/List";
import {VerticalFlexLayout} from "components/common/FlexLayout";
import {useSearchParams} from "react-router-dom";

const statuses: JobStatus[] = [
    "all",
    "pending",
    "accepted",
    "rejected",
    "active"
]

const allStatuses: JobStatus[] = [
    "draft",
    ...statuses,
    "closed",
    "cancelled"
]

const columns: ColumnDefinition<Offer>[] = [
    {id: "name", label: strings.title, align: "left"},
    {id: "status", label: strings.status, align: "left"}
]

const renderRow = (o: Offer): (string | JSX.Element)[] => {
    return [
        <>
            <Heading6>{o.name}</Heading6>
            <Body2 color={colors.disabledText}>
                {toShortDate(o.created)}
            </Body2>
        </>,
        <>
            <Heading6>{strings[`${o.status}Status`]}</Heading6>
            <Body2 color={colors.disabledText}>
                Last update: {toAge(o.updated)}
            </Body2>
        </>
    ]
}

const emptyView = (
    <VerticalFlexLayout
        height={"100%"}
        wrap={"nowrap"}
        justify={"center"}>
        <Heading4 align={"center"}>
            {strings.noOffersTitle}
        </Heading4>
        <Body1 align={"center"}>
            {strings.noOffersMessage}
        </Body1>
    </VerticalFlexLayout>
)

interface Props {
    jobId?: string
    onClick: (offer: Offer) => void
    isAdmin?: boolean
}

const OffersList: FC<Props> = (props) => {
    const [params] = useSearchParams()
    const s = params.get("status")
    const defaultStatus = s ? s as JobStatus : "pending"

    const load = async (status: JobStatus) => {
        // TODO: 'window.history' is used to avoid reloading the page when replacing
        //  the URL. Find a better way to do it using useHistory or any other hook.
        const url = new URL(window.location.href)
        url.searchParams.set('status', status)
        window.history.pushState(null, '', url.toString())

        const data = await Api.offers
            .getOffers(status, props.jobId)
            .catch(res => {
                handleFieldErrors(res.data as ErrorResponse)
                return undefined
            })

        if (data) {
            data.items = data?.items
                .sort((a, b) => a.name.localeCompare(b.name))
        }

        return data
    }

    const search = (term: string, list: List<Offer>) => {
        alert()
        return {
            ...list,
            items: list?.items.filter(i =>
                i.name.toLowerCase().includes(term.toLowerCase().trim())) ?? []
        }
    }

    return (
        <DataTable
            load={load}
            columns={columns}
            renderRow={renderRow}
            onClick={props.onClick}
            emptyView={emptyView}
            defaultStatus={defaultStatus}
            statues={props.isAdmin ? allStatuses : statuses}
            search={search}/>
    )
}

export default OffersList
