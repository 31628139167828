const strings = {
    appName: "TAO Media",
    appDescription: "Access top media talents!",
    firstName: "First Name",
    companyName: "Company Name",
    lastName: "Last Name",
    emailAddress: "Email Address",
    signIn: "Sign In",
    alreadyUser: "Already a user?",
    signUp: "Sign Up",
    create: "Create",
    createClient: "Create Client",
    createContractor: "Create Contractor",
    status: "Status",
    getStarted: "Get Started",
    send: "Send",
    finish: "Finish",
    offerDetails: "Offer Details",
    add: "Add",
    offers: "Offers",
    save: "Save",
    password: "Password",
    portfolio: "Portfolio",
    quote: "Quote",
    overallBudget: "Overall Budget",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
    termsConditions: "Terms & Conditions",
    privacyPolicy: "Privacy Policy",
    internalErrorMessage: "Oops! It looks like something went wrong!",
    resetPassword: "Reset password",
    changePassword: "Change password",
    forgotPassword: "Forgot password?",
    returnToHome: "Return to home",
    forgotPasswordEnterUsernameMessage: "Type in your email address and we will send a verification code for password restoration",
    confirmSignUpMessage_Username: "A verification code sent to {0}.\n\nPlease enter the code to confirm your email.",
    regularRate: "Regular Rate",
    languages: "Languages",
    expertise: "Expertise",
    next: "Next",
    back: "Back",
    name: "Name",
    writeYourMessage: "Write your message",
    experiences: "Experiences",
    bankDetails: "Bank Details",
    submit: "Submit",
    equipment: "Equipment",
    specialization: "Specialization",
    url: "URL",
    actions: "Actions",
    taoMediaId: "TAO Media ID",
    employer: "Company/Employer",
    nationality: "Nationality",
    phone: "Phone",
    email: "Email",
    hometown: "Hometown",
    search: "Search",
    verificationCode: "Verification Code",
    remove: "Remove",
    all: "All",
    allStatus: "All",
    draftStatus: "Draft",
    pendingStatus: "Pending",
    matchingStatus: "Matching",
    approvedStatus: "Approved",
    acceptedStatus: "Accepted",
    declinedStatus: "Declined",
    reviewingStatus: "Reviewing",
    rejectedStatus: "Rejected",
    activeStatus: "Active",
    completedStatus: "Completed",
    cancelledStatus: "Cancelled",
    paidStatus: "Paid",
    closedStatus: "Closed",
    select: "Select",
    iban: "IBAN",
    profession: "Profession",
    filter: "Filter",
    notes: "Notes",
    delete: "Delete",
    cancel: "Cancel",
    reset: "Reset",
    removeCrewMemberTitle: "Removing Crew Member",
    removeCrewMemberMessage: "Are you sure you want to remove this member?",
    amount: "Amount",
    ratePer: "Rate Per",
    rateIntervalHour: "Hour",
    rateIntervalDay: "Day",
    rateIntervalProject: "Project",
    myAssignments: "My Assignments",
    city: "City",
    title: "Title",
    SendToContractor: "Send to contractor",
    profile: "Profile",
    contractorProfile: "Contractor Profile",
    selectContractor: "Select a Contractor",
    country: "Country",
    noAssignmentsTitle: "You don't have any assignments!",
    noAssignmentsMessage: "Create new assignment & get started",
    noOffersTitle: "Stay tuned!",
    noOffersMessage: "No offers yet!",
    agentNoOffersTitle: "No offers yet!",
    agentNoOffersMessage: "Create an offer and get started!",
    agentNoProposalsTitle: "No proposals yet!",
    agentNoProposalsMessage: "Create a proposal and get started!",
    agentNoCewMembersTitle: "No crew members yet!",
    agentNoCewMembersMessage: "Start adding them now!",
    noSearchResultsMessage: "No search results found!",
    successContactUsMessage: "Thank you for contacting TAO Media. We'll get in touch as soon as possible.",
    currency: "Currency",
    maxRate: "Max Rate",
    rate: "Rate",
    reject: "Reject",
    approve: "Approve",
    deadline: "Deadline",
    saveAsDraft: "Save As Draft",
    createAssignment: "Create Assignment",
    submitAssignment: "Submit Assignment",
    startAProject: "Start a Project",
    jobNameTip: "Give your project a name",
    jobNamePlaceholder: "i.e. 2 minutes clip for YouTube",
    professionPlaceholder: "i.e. Developer, Designer, etc.",
    requirements: "Requirements",
    jobRequirementsTip: "Note what needs to be done, what are format requirements for the content to be delivered and with what equipment it is likely to be done, what are content delivery preferences.",
    documents: "Documents",
    jobDocumentsTip: "Upload any additional requirements or documents.",
    jobDeadlineTip: "What is your deadline for the project?",
    jobBudgetTip: "What is your budget (if available)?",
    budget: "Budget",
    noDataAvailable: "No data are available!",
    general: "General",
    details: "Details",
    liveChat: "Live Chat",
    proposalRejectedSuccessMessage: "Proposal rejected!",
    messagesHistory: "Messages History",
    multipleProposalsMessage: "These are our proposals for this assignment. Please choose one of them to proceed:",
    singleProposalMessage: "These are our proposals for this assignment. Check the details:",
    noProposalsMessage: "Stay tuned, we're still matching. we'll notify you.",
    rejectionDialogTitle: "What’s the reason?",
    rejectionReasonPlaceholder: "Type the reason here...",
    notesPlaceholder: "Enter your requirements or instructions...",
    clients: "Clients",
    location: "Location",
    projectType: "Project Type",
    projectTypeTip: "What's the nature of this job?",
    projectDetails: "Project Details",
    jobLocationTip: "Where do you want to hire for this project?",
    invalidPasswordMessage: "Please enter a valid password!",
    passwordMismatchMessage: "Passwords do not match!",
    contractors: "Contractors",
    assignments: "Assignments",
    assignmentDetails: "Assignment Details",
    rating: "Rating",
    proposals: "Proposals",
    proposalDetails: "Proposal Details",
    paymentPer: "Payment Per",
    workingDates: "Working Dates",
    crewMembers: "Crew Members",
    crewMember: "Crew Member",
    pickContractors_Number: "Pick {0} Contractors",
}

export default strings
