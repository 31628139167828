import React from "react";
import IconProps from "components/common/icons/IconProps";

const EditIcon: React.FC<IconProps> = (props) => (
    <div className={"svg-container"}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size ?? "24"}
            height={props.size ?? "24"}
            viewBox="0 0 24 24">
            <path
                d="M14.7378 7.972L5.69976 17.01L5.10876 19.026L7.08376 18.455L16.1518 9.387L14.7378 7.972ZM16.2798 6.43L17.6938 7.844L19.0118 6.526C19.1055 6.43224 19.1582 6.30508 19.1582 6.1725C19.1582 6.03992 19.1055 5.91276 19.0118 5.819L18.3038 5.112C18.21 5.01827 18.0828 4.96561 17.9503 4.96561C17.8177 4.96561 17.6905 5.01827 17.5968 5.112L16.2808 6.43H16.2798ZM19.7188 3.698L20.4258 4.405C20.8944 4.87382 21.1577 5.50959 21.1577 6.1725C21.1577 6.83541 20.8944 7.47118 20.4258 7.94L8.13376 20.233L3.91376 21.453C3.74175 21.5026 3.55958 21.5051 3.38626 21.4603C3.21295 21.4154 3.05485 21.3249 2.92847 21.1981C2.80209 21.0713 2.71206 20.9129 2.66779 20.7395C2.62351 20.566 2.62661 20.3839 2.67676 20.212L3.92476 15.957L16.1848 3.697C16.6536 3.22832 17.2894 2.96503 17.9523 2.96503C18.6152 2.96503 19.2509 3.22832 19.7198 3.697L19.7188 3.698Z"
                fill={props.color ?? "black"}/>
        </svg>
    </div>
)

export default EditIcon
