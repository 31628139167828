const jobTypes = [
    "Commercial (TVC)",
    "Event Coverage",
    "Content for Social Media",
    "Hire a Photographer",
    "Hire a Videographer",
    "Live Streaming",
    "Electronic News Gathering (ENG)",
    "Hire Media Equipment",
    "Hire a Crew for Video Production",
    "Other"
]

export default jobTypes
