import React, {FC} from 'react'
import {Routes, Route} from "react-router-dom"
import SignIn from "pages/account/SignIn"
import ForgotPassword from "pages/account/ForgotPassword"
import NotFound from "pages/NotFound"
import ResetPassword from "pages/account/ResetPassword";
import SignUp from "pages/account/SignUp";

const AccountRouter: FC = () => {
    return (
        <Routes>
            <Route path="sign-in" element={<SignIn/>}/>
            <Route path="sign-up" element={<SignUp/>}/>
            <Route path="forgot-password" element={<ForgotPassword/>}/>
            <Route path="reset-password" element={<ResetPassword/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    )
}

export default AccountRouter
