import React, {FC, useState,} from "react"
import Contractor from "models/Contractor";
import SearchContractor from "components/contractor/SearchContractor";
import ContractorHeader from "components/contractor/ContractorHeader";
import {VerticalFlexLayout} from "components/common/FlexLayout";
import strings from "res/strings";
import Job from "models/Job";
import Offer from "models/Offer";
import UpsertOfferControl from "components/offer/UpsertOfferControl";
import Button from "components/common/Button";
import ArrowLeftIcon from "components/common/icons/ArrowLeftIcon";

type Stage = "contractor" | "offer"

interface Props {
    job: Job
    changeTitle: (title: string) => void
    onComplete: (offer: Offer) => void
}

const CreateOffer: FC<Props> = (props) => {
    const [stage, setStage] = useState<Stage>("contractor")
    const [contractor, setContractor] = useState<Contractor>()

    if (stage === "contractor") {
        props.changeTitle(strings.selectContractor)

        return (
            <SearchContractor onSelect={c => {
                setContractor(c)
                setStage("offer")
            }}/>
        )
    } else {
        props.changeTitle(strings.offerDetails)

        const offer: Partial<Offer> = {
            jobId: props.job.id,
            companyId: props.job.companyId,
            contractorId: contractor!.id,
            name: props.job.name,
            deadline: props.job.deadline,
            budget: props.job.budget,
            notes: props.job.description,
            job: props.job
        }

        return (
            <VerticalFlexLayout spacing={4}>
                <Button
                    color={"secondary"}
                    onClick={() => setStage("contractor")}>
                    <ArrowLeftIcon size={16}/>
                    {strings.back}
                </Button>
                <ContractorHeader
                    contractor={contractor!}/>
                <UpsertOfferControl
                    offer={offer as Offer}
                    onComplete={props.onComplete}/>
            </VerticalFlexLayout>
        )
    }
}

export default CreateOffer
