import React, {useCallback, useEffect, useState} from "react"
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import {Body1, Body2} from "components/common/Typography";
import colors from "res/colors";
import List from "models/List";
import strings from "res/strings";
import JobStatus from "models/types/JobStatus";
import Loading from "components/common/Loading";
import {Box} from "@mui/material";

interface Props<T> {
    load: (status: JobStatus) => Promise<List<T> | undefined>
    renderRow: (item: T) => JSX.Element
    onClick: (item: T) => void
    defaultStatus: JobStatus
    statues: JobStatus[]
    emptyView?: string | JSX.Element
}

// TODO: Most of the functionalities here can be abstracted in the original DataTable.
const DataTable2 = <T extends any>(props: Props<T>) => {
    const {load} = props;

    const [list, setList] = useState<List<T>>()
    const [loading, setLoading] = useState(true)
    const [status] = useState<JobStatus>(props.defaultStatus)

    const loadList = useCallback(async () => {
        // TODO: In case of search, show different empty view 'No result for xxx'
        setList(await load(status))
    }, [status, load])

    useEffect(() => {
        void async function fetchData() {
            // TODO: This is being called twice because of the setLoading.
            if (!list) {
                await loadList()
            }

            setLoading(false)
        }()
    }, [list, loadList])

    // TODO: Loading should be shown on top of the current view; blocking what's behind it.
    if (loading) {
        return (<Loading/>)
    } else if (!list?.count) {
        // TODO: This shouldn't hide the status and search fields.
        if (props.emptyView && typeof props.emptyView !== 'string') {
            return props.emptyView
        }

        return (
            <VerticalFlexLayout
                height={"100%"}
                wrap={"nowrap"}
                justify={"center"}>
                <Box p={2}>
                    <Body1 align={"center"}>
                        {props.emptyView ?? strings.noDataAvailable}
                    </Body1>
                </Box>
            </VerticalFlexLayout>
        )
    }

    return (
        <VerticalFlexLayout spacing={4}>
            <Body2 color={colors.disabledText}>
                {`Showing ${list.count}/${list.total}`}
            </Body2>
            <HorizontalFlexLayout spacing={2}>
                {list.items.map((item, index) => (
                    <GridItem
                        key={index}
                        xs={12} md={6}
                        sx={{cursor: "pointer"}}
                        onClick={() => props.onClick(item)}>
                        {props.renderRow(item)}
                    </GridItem>
                ))}
            </HorizontalFlexLayout>

            {/*<GridItem alignSelf={"center"}>*/}
            {/*    <Pagination count={10} color="primary"/>*/}
            {/*</GridItem>*/}
        </VerticalFlexLayout>
    )
}

export default DataTable2
