import React, {FC} from "react"
import {GridItem, HorizontalFlexLayout} from "components/common/FlexLayout";
import InputField from "components/common/InputField";
import {Controller, useFormContext} from "react-hook-form";
import strings from "res/strings";
import {FormHelperText, MenuItem, Select} from "@mui/material";
import currencies from "res/currencies";
import {Typography} from "components/common/Typography";

interface Props {
    namespace: string
    label?: string
}

// TODO: Make it type safe.
const BudgetField: FC<Props> = (props) => {
    const {control, getValues, setValue, watch} = useFormContext()

    if (Number(getValues(`${props.namespace}.value`))) {
        setValue(`${props.namespace}.currency`, getValues(`${props.namespace}.currency`))
    }

    return (<>
        <HorizontalFlexLayout
            spacing={2}
            alignItems={"flex-end"}
            wrap={"nowrap"}>
            <GridItem xs={6}>
                <Controller
                    name={`${props.namespace}.value`}
                    control={control}
                    render={({field, fieldState: {error}}) => (
                        <InputField
                            label={props.label}
                            placeholder={strings.amount}
                            type={"number"}
                            inputProps={{step: 0.01}}
                            fieldError={error}
                            {...field} />
                    )}/>
            </GridItem>
            <GridItem xs={6}>
                <Controller
                    name={`${props.namespace}.currency`}
                    control={control}
                    render={({field, fieldState: {error}}) => (
                        <>
                            {Boolean(props.label) &&
                            <Typography
                                component={"label"}
                                sx={{lineHeight: "20px", fontSize: 14}}>
                                {strings.currency}
                            </Typography>}
                            <Select
                                fullWidth
                                required={Boolean(watch(`${props.namespace}.value`))}
                                error={Boolean(error)}
                                {...field}>
                                {currencies.map(c => (
                                    <MenuItem key={c} value={c}>{c}</MenuItem>
                                ))}
                            </Select>
                            {Boolean(error) &&
                            <FormHelperText error={true}>
                                {error?.message}
                            </FormHelperText>}
                        </>
                    )}/>
            </GridItem>
        </HorizontalFlexLayout>
    </>)
}

export default BudgetField
