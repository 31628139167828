import React, {FC, useState} from "react"
import {VerticalFlexLayout} from "components/common/FlexLayout"
import {Controller, useForm} from "react-hook-form"
import Button from "components/common/Button"
import strings from "res/strings"
import Api from "data/remote/Api";
import InputField from "components/common/InputField";
import ErrorResponse from "data/remote/models/ErrorResponse";
import {handleFieldErrors} from "util/Utilities";
import UserRole from "models/types/UserRole";
import {useAlert} from "contexts/AlertContext";
import {Box} from "@mui/material";

interface Fields {
    email: string
    password: string
    confirmPassword: string
    attributes: {
        "custom:company-name": string
        given_name: string
        family_name: string
    }
}

interface Props {
    role: UserRole
    onSuccess: () => void
    companyId?: string
}

const UpsertUserControl: FC<Props> = (props) => {
    const alert = useAlert()
    const {control, handleSubmit, clearErrors, setError} = useForm<Fields>()

    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = handleSubmit(async fields => {
        setIsLoading(true)
        clearErrors()

        await Api.auth
            .createUserAsync(
                fields.email,
                fields.password,
                props.role,
                fields.attributes,
                fields.attributes["custom:company-name"]
            )
            .then(() => {
                alert.enqueue({
                    message: `User created successfully! Password sent to ${fields.email}`,
                    severity: "success"
                })
                props.onSuccess()
            })
            .catch(res => {
                handleFieldErrors(res.data as ErrorResponse, setError)
            })

        setIsLoading(false)
    })

    return (
        <form onSubmit={onSubmit}>
            <VerticalFlexLayout spacing={2}>
                {props.role === "company" &&
                <Controller
                    name="attributes.custom:company-name"
                    control={control}
                    render={({field, fieldState: {error}}) => (
                        <InputField
                            label={strings.companyName}
                            required
                            autoFocus={props.role === "company"}
                            fieldError={error}
                            {...field} />
                    )}/>}

                <Controller
                    name="attributes.given_name"
                    control={control}
                    render={({field, fieldState: {error}}) => (
                        <InputField
                            label={strings.firstName}
                            autoFocus={props.role !== "company"}
                            required
                            fieldError={error}
                            {...field} />
                    )}/>

                <Controller
                    name="attributes.family_name"
                    control={control}
                    render={({field, fieldState: {error}}) => (
                        <InputField
                            label={strings.lastName}
                            required
                            fieldError={error}
                            {...field} />
                    )}/>

                <Controller
                    name="email"
                    control={control}
                    render={({field, fieldState: {error}}) => (
                        <InputField
                            label={strings.emailAddress}
                            required
                            type="email"
                            fieldError={error}
                            {...field} />
                    )}/>

                <Box pt={2}>
                    <Button
                        type="submit"
                        fullWidth
                        size={"large"}
                        isBusy={isLoading}
                        variant="contained"
                        color="primary">
                        {strings.create}
                    </Button>
                </Box>
            </VerticalFlexLayout>
        </form>
    )
}

export default UpsertUserControl
