import React, {FC} from "react"
import {Box} from "@mui/material"
import {VerticalFlexLayout} from "components/common/FlexLayout"
import {Body1} from "components/common/Typography"

const DataNotFound: FC = () => {
    return (
        <Box p={2}>
            <VerticalFlexLayout justify={"center"} alignItems={"center"}>
                <Body1>Not found</Body1>
            </VerticalFlexLayout>
        </Box>
    )
}

export default DataNotFound
