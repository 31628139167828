import React, {FC} from "react"
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator"
import {VerticalFlexLayout} from "components/common/FlexLayout";
import {Heading1, Heading5} from "components/common/Typography";
import colors from "res/colors";

const Error: FC = () => {
    return (<>
        <HtmlMetaDecorator title="Error"/>
        <VerticalFlexLayout
            spacing={4}
            height={"100%"}
            justify={"center"}
            alignItems={"center"}>
            <Heading1 color={colors.error}>Oops!</Heading1>
            <Heading5 color={colors.error}>Internal Error ☹️</Heading5>
        </VerticalFlexLayout>
    </>)
}

export default Error
