import React, {FC, PropsWithChildren, useState} from 'react';
import MuiAppBar from "@mui/material/AppBar"
import {
    Box,
    IconButton,
    Toolbar,
    SwipeableDrawer,
    List,
    ListItemButton,
    ListItemText,
    Divider,
    Hidden
} from "@mui/material";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import {Link, useLocation} from "react-router-dom";
import strings from "res/strings";
import MenuIcon from "components/common/icons/MenuIcon";
import {useAuth} from "contexts/AuthContext";
import Button from "components/common/Button";
import {Body1} from "components/common/Typography";
import colors from "res/colors";

const HomeAppBar: FC<PropsWithChildren> = (props) => {
    const {isAuthenticated} = useAuth()
    const {pathname} = useLocation()

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const toggleDrawer = () => setIsDrawerOpen(ps => !ps)

    const isHome = pathname.split('/').length === 2

    const list = [
        ["/#how-to", "How it works"],
        ["/#inspiration", "Inspiration"],
        ["/account/sign-up?role=contractor", "For Creators"],
        ["/#about", "About"]
    ]

    const actionButtons = (
        <HorizontalFlexLayout
            spacing={2}
            justify={"flex-end"}>
            {!isAuthenticated() &&
            <Link
                to={"/account/sign-up"}
                style={{textDecoration: "none"}}>
                <Button
                    variant={"outlined"}
                    color={"secondary"}
                    sx={{
                        backgroundColor: "white",
                        borderRadius: 24
                    }}>
                    Sign Up
                </Button>
            </Link>}
            <Link
                to={"/account/sign-in"}
                style={{textDecoration: "none"}}>
                <Button
                    variant={"contained"}
                    sx={{borderRadius: 24}}>
                    {isAuthenticated() ? "Dashboard" : "Sign In"}
                </Button>
            </Link>
        </HorizontalFlexLayout>
    )

    return (
        <Box sx={{display: "flex", height: "100%"}}>
            <MuiAppBar
                position="absolute"
                sx={{
                    backgroundColor: {xs: colors.primary, md: "transparent"},
                    zIndex: 1201,
                    boxShadow: "none",
                    borderRadius: 0
                }}>
                <Toolbar>
                    <Box sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: 200,
                        zIndex: -1,
                        display: {xs: "none", md: "block"}
                    }}>
                        <img
                            style={{width: "100%"}} alt={"logo"}
                            src={process.env.PUBLIC_URL + "/assets/polygon.png"}/>
                    </Box>
                    <HorizontalFlexLayout
                        spacing={2}
                        wrap={"nowrap"}
                        alignItems={"center"}>
                        <GridItem sx={{flexGrow: {xs: 1, md: 0}}}>
                            <Link to={"/"}>
                                <img
                                    style={{width: 140, padding: 8}}
                                    alt={strings.appName}
                                    src={process.env.PUBLIC_URL + "/full-logo.svg"}/>
                            </Link>
                        </GridItem>
                        <GridItem sx={{
                            flexGrow: 1,
                            display: {xs: "none", md: "block"}
                        }}>
                            <HorizontalFlexLayout justify={"center"}>
                                {list.map(i => (
                                    <Link key={i[0]} to={i[0]} style={{textDecoration: "none"}}>
                                        <Body1 padding={2} color={isHome ? "white" : undefined}>{i[1]}</Body1>
                                    </Link>
                                ))}
                            </HorizontalFlexLayout>
                        </GridItem>
                        <GridItem sx={{display: {xs: "none", sm: "block"}}}>
                            {actionButtons}
                        </GridItem>
                        <GridItem sx={{display: {xs: "block", md: "none"}}}>
                            <IconButton onClick={toggleDrawer}>
                                <MenuIcon/>
                            </IconButton>
                        </GridItem>
                    </HorizontalFlexLayout>
                </Toolbar>
            </MuiAppBar>

            <nav>
                <Hidden mdUp>
                    <SwipeableDrawer
                        anchor={'top'}
                        variant={"temporary"}
                        open={isDrawerOpen}
                        onClose={toggleDrawer}
                        onOpen={toggleDrawer}
                        ModalProps={{keepMounted: true}}>
                        <Box
                            p={2} pt={8}
                            onClick={toggleDrawer}
                            onKeyDown={toggleDrawer}>
                            <VerticalFlexLayout spacing={2}>
                                <List>
                                    {list.map((t, i) => (
                                        <ListItemButton
                                            key={i}
                                            component={Link}
                                            to={t[0] as string}>
                                            <ListItemText primary={t[1]}/>
                                        </ListItemButton>
                                    ))}
                                </List>
                                <Divider/>
                                {actionButtons}
                            </VerticalFlexLayout>
                        </Box>
                    </SwipeableDrawer>
                </Hidden>
            </nav>

            <Box flexGrow={1} pt={isHome ? 0 : 8}>
                <main>
                    {props.children}
                </main>
            </Box>
        </Box>
    )
}

export default HomeAppBar
