const currencies = [
    "USD",
    "SAR",
    "AED",
    "BHD",
    "EGY",
    "GBP"
]

export default currencies
