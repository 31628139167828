import BaseApi from "util/api/BaseApi";
import SignInResponse from "data/remote/models/SignInResponse";
import UserRole from "models/types/UserRole";

class AuthApi extends BaseApi {
    async signUpAsync(
        role: UserRole,
        username: string,
        password: string,
        attributes: Record<string, string>,
        company: string = ""
    ): Promise<any | undefined> {
        const body = {
            clientId: this._config.clientId,
            username: username,
            email: username,
            password: password,
            type: role,
            company,
            attributes
        }

        return await this.post<any>(`auth/signup?type=${role}`, body)
    }

    async confirmSignUpAsync(username: string, code: string):
        Promise<SignInResponse | undefined> {
        const body = {
            clientId: this._config.clientId,
            username: username,
            code: code
        }

        return await this.post<SignInResponse>(`auth/confirm`, body, {
            errorHandling: "manual"
        })
    }

    async signInAsync(username: string, password: string, newPassword: string = ""):
        Promise<SignInResponse | undefined> {
        const body = {
            clientId: this._config.clientId,
            persist: true,
            username,
            password,
            newPassword
        }

        return await this.post<SignInResponse>(`auth/login`, body, {
            errorHandling: "manual"
        })
    }

    async signOutAsync(): Promise<SignInResponse | undefined> {
        return await this.post<SignInResponse>(`auth/logout`, {
            clientId: this._config.clientId
        })
    }

    async forgotPasswordAsync(username: string): Promise<any | undefined> {
        return await this.post<any>(`auth/forgotPassword`, {
            clientId: this._config.clientId,
            username
        })
    }

    async confirmForgotPasswordAsync(
        username: string,
        password: string,
        code: string
    ): Promise<any | undefined> {
        return await this.post<any>(`auth/confirmForgotPassword`, {
            clientId: this._config.clientId,
            username,
            password,
            code
        })
    }

    async changePasswordAsync(
        oldPassword: string,
        newPassword: string
    ): Promise<any | undefined> {
        return await this.post<any>(`auth/changePassword`, {
            oldPassword,
            newPassword
        })
    }

    async resetPasswordAsync(
        username: string
    ): Promise<any | undefined> {
        return await this.post<any>(`auth/resetPassword`, {username})
    }

    async createUserAsync(
        username: string,
        password: string,
        role: UserRole,
        attributes: Record<string, string>,
        company: string = ""
    ): Promise<any | undefined> {
        const body = {
            clientId: this._config.clientId,
            username: username,
            email: username,
            group: role,
            company,
            attributes
        }

        return await this.post<any>(`auth/user`, body)
    }
}

export default AuthApi
