import React, {FC, useEffect, useState} from "react"
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator"
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import {Box, Breadcrumbs, Dialog, DialogActions, DialogContent, Tab, Tabs} from "@mui/material";
import Details from "components/job/JobDetails";
import Job from "models/Job";
import {Link, useParams} from "react-router-dom";
import DataNotFound from "components/common/DataNotFound";
import {Body1, Body2, Heading3, Heading4, Heading6} from "components/common/Typography";
import Status from "components/common/Status";
import Proposal from "models/Proposal";
import RejectionReasonDialog from "components/common/RejectionReasonDialog";
import Api from "data/remote/Api";
import {handleFieldErrors, moneyString} from "util/Utilities";
import ErrorResponse from "data/remote/models/ErrorResponse";
import Loading from "components/common/Loading";
import strings from "res/strings";
import List from "models/List";
import DataTable, {ColumnDefinition} from "components/common/DataTable";
import colors from "res/colors";
import Button from "components/common/Button";
import IconButton from "components/common/IconButton";
import CloseIcon from "components/common/icons/CloseIcon";
import ProposalDetailsCard from "components/common/ProposalDetailsCard";
import MessagingControl from "components/MessagingControl";
import DialogTransition from "components/common/DialogTransition";
import {useAlert} from "contexts/AlertContext";
import ProposalDetails from "components/proposal/ProposalDetails";

const columns: ColumnDefinition<Proposal>[] = [
    {id: "budget", label: strings.quote, align: "left"},
    {id: "offers", label: strings.crewMembers},
    {id: "status", label: strings.status}
]

const renderRow = (p: Proposal): (string | JSX.Element)[] => {
    return [
        <Heading6>{p.job.name}</Heading6>,
        <Body2 color={colors.disabledText}>{moneyString(p.budget)}</Body2>,
        <Body2 color={colors.disabledText}>{p.offers.length}</Body2>,
        <Body2 color={colors.disabledText}>{p.status}</Body2>
    ]
}

const JobDetails: FC = () => {
    const {id} = useParams()
    const alert = useAlert()

    const [job, setJob] = useState<Job>()
    const [proposals, setProposals] = useState<List<Proposal>>()
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [rejectOpen, setRejectOpen] = useState(false)
    const [value, setValue] = React.useState(0)
    const [proposal, setProposal] = useState<Proposal>()

    useEffect(() => {
        void async function fetchData() {
            if (id) {
                await Api.jobs.getJobAsync(id)
                    .then(async j => {
                        if (j) {
                            setJob(j as Job)
                            await Api.proposals.getJobProposalsAsync(id)
                                .then(p => setProposals(p))
                                .catch(res => handleFieldErrors(res.data as ErrorResponse))
                        }
                    })
                    .catch(res => handleFieldErrors(res.data as ErrorResponse))
            }

            setLoading(false)
        }()
    }, [id])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    const approveProposal = async () => {
        if (proposal) {
            await Api.proposals.updateStatus(proposal.id, job!.id, job!.companyId, "accept")
                .then(() => {
                    alert.enqueue({
                        message: `Proposal approved!`,
                        severity: "success"
                    })
                    setOpen(false)
                    // TODO: Change the status of the job.
                    // TODO: Remove all other proposals from UI, and show the approved one only?
                })
                .catch(res => handleFieldErrors(res.data as ErrorResponse))
        }
    }

    const rejectProposal = async (reason: string) => {
        if (proposal) {
            await Api.proposals.updateStatus(proposal.id, job!.id, job!.companyId, "reject", reason)
                .then(() => {
                    alert.enqueue({
                        message: `Proposal rejected!`,
                        severity: "success"
                    })
                    setRejectOpen(false)
                    setOpen(false)
                    // TODO: Remove the proposal from the list or change the status
                })
                .catch(res => handleFieldErrors(res.data as ErrorResponse))
        }
    }

    const completeJob = async () => {
        await Api.jobs.updateStatus(job!.id, job!.companyId, "complete")
            .then(() => {
                alert.enqueue({
                    message: `Assignment completed!`,
                    severity: "success"
                })

                setJob(ps => ps && ({...ps, status: "completed"}))
            })
            .catch(res => handleFieldErrors(res.data as ErrorResponse))
    }

    const renderGeneralTab = () => {
        const _proposal = proposals?.items.find(p => p.status === "approved")

        if ((job?.status === "active" || job?.status === "completed") && _proposal) {
            return (
                <GridItem sx={{
                    backgroundColor: "#F9F9F9",
                    padding: 2,
                    flexGrow: 1
                }}>
                    <ProposalDetails proposal={_proposal}/>
                </GridItem>
            )
        } else {
            return (
                <GridItem sx={{backgroundColor: "#F9F9F9", flexGrow: 1}}>
                    {proposals?.count ?
                        <VerticalFlexLayout>
                            <Box p={2}>
                                <Body1>
                                    {proposals.count > 1 ?
                                        strings.multipleProposalsMessage :
                                        strings.singleProposalMessage}
                                </Body1>
                            </Box>
                            <DataTable
                                hideFiltration
                                load={async () => proposals}
                                columns={columns}
                                defaultStatus={"pending"}
                                statues={["pending"]}
                                renderRow={renderRow}
                                onClick={p => {
                                    setProposal(p)
                                    setOpen(true)
                                }}/>
                        </VerticalFlexLayout>
                        :
                        <Box p={2}>
                            <Body1>
                                {strings.noProposalsMessage}
                            </Body1>
                        </Box>
                    }
                </GridItem>
            )
        }
    }

    if (loading) {
        return (<Loading/>)
    } else if (!job) {
        return (<DataNotFound/>)
    }

    return (<>
        <HtmlMetaDecorator title={job.name}/>

        <VerticalFlexLayout
            height={"100%"}
            wrap={"nowrap"}>
            <Box bgcolor={"white"} px={2} pt={2}>
                <VerticalFlexLayout spacing={2}>
                    <Breadcrumbs
                        separator=">"
                        sx={{fontSize: "small"}}>
                        <Link to={"/company"}>
                            <Body2>
                                {strings.myAssignments}
                            </Body2>
                        </Link>
                        <Body2>
                            {strings.assignmentDetails}
                        </Body2>
                    </Breadcrumbs>
                    <HorizontalFlexLayout
                        spacing={2}
                        justify={"flex-end"}>
                        <GridItem sx={{flexGrow: 1}}>
                            <Heading3>{job.name}</Heading3>
                            <Status value={job.status}/>
                        </GridItem>
                        {job.status === "active" &&
                        <Button
                            variant={"contained"}
                            onClick={completeJob}>
                            Mark as Completed
                        </Button>}
                    </HorizontalFlexLayout>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label={strings.general} sx={{padding: 3}}/>
                        <Tab label={strings.details}/>
                        <Tab label={strings.messagesHistory}/>
                    </Tabs>
                </VerticalFlexLayout>
            </Box>

            {value === 0 && renderGeneralTab()}

            {value === 1 &&
            <GridItem sx={{backgroundColor: "#F9F9F9", flexGrow: 1}}>
                <Box p={2}>
                    <Details job={job}/>
                </Box>
            </GridItem>}

            {value === 2 &&
            <GridItem height={"100%"} zIndex={2}>
                <MessagingControl
                    kind={job.proposalId ? "job" : "newJob"}
                    jobId={job.id}
                    companyId={job.companyId}/>
            </GridItem>}
        </VerticalFlexLayout>

        <Dialog
            open={open}
            fullScreen
            scroll={"paper"}
            TransitionComponent={DialogTransition}
            onClose={() => setOpen(false)}>
            <DialogActions sx={{padding: 2}}>
                <HorizontalFlexLayout
                    spacing={2}
                    alignItems={"center"}>
                    <GridItem grow={1}>
                        <HorizontalFlexLayout
                            spacing={2}
                            wrap={"nowrap"}>
                            <IconButton
                                onClick={() => setOpen(false)}>
                                <CloseIcon/>
                            </IconButton>
                            <GridItem grow={1}>
                                <Heading4>{proposal?.job.name}</Heading4>
                            </GridItem>
                        </HorizontalFlexLayout>
                    </GridItem>
                    {job.status === "pending" &&
                    <GridItem grow={1}>
                        <HorizontalFlexLayout
                            spacing={2}
                            wrap={"nowrap"}
                            justify={"flex-end"}>
                            <GridItem xs={6} sm={"auto"}>
                                <Button
                                    fullWidth
                                    variant={"outlined"}
                                    color={"secondary"}
                                    onClick={() => setRejectOpen(true)}>
                                    {strings.reject}
                                </Button>
                            </GridItem>
                            <GridItem xs={6} sm={"auto"}>
                                <Button
                                    fullWidth
                                    variant={"contained"}
                                    onClick={approveProposal}>
                                    {strings.approve}
                                </Button>
                            </GridItem>
                        </HorizontalFlexLayout>
                    </GridItem>}
                </HorizontalFlexLayout>
            </DialogActions>
            <DialogContent
                dividers
                sx={{
                    backgroundColor: colors.pearlWhite,
                    padding: 0
                }}>
                <ProposalDetailsCard proposal={proposal!}/>
            </DialogContent>
        </Dialog>

        {/* NOTE: It's important to put this after the Dialog in order to be shown on top. */}
        <RejectionReasonDialog
            open={rejectOpen}
            onReject={rejectProposal}
            onCancel={() => setRejectOpen(false)}/>
    </>)
}

export default JobDetails
