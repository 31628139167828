import React, {FC} from "react";
import {GridItem, VerticalFlexLayout} from "components/common/FlexLayout";
import strings from "res/strings";
import Proposal from "models/Proposal";
import {Box, Tab, Tabs} from "@mui/material";
import MessagingControl from "components/MessagingControl";
import ProposalDetails from "components/proposal/ProposalDetails";

interface Props {
    proposal: Proposal
    onUpdate?: (p: Proposal) => void
}

const ProposalDetailsCard: FC<Props> = (props) => {
    const proposal = props.proposal
    const [value, setValue] = React.useState(0)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    return (
        <VerticalFlexLayout
            height={"100%"}
            wrap={"nowrap"}>
            <Box bgcolor={"white"} px={2}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label={strings.details} sx={{padding: 3}}/>
                    <Tab label={strings.messagesHistory}/>
                </Tabs>
            </Box>
            {value === 0 &&
            <Box p={2}>
                <ProposalDetails
                    proposal={proposal}
                    onUpdate={props.onUpdate}/>
            </Box>}
            {value === 1 &&
            <GridItem height={"100%"} zIndex={2}>
                <MessagingControl
                    kind={"proposal"}
                    proposalId={proposal.id}
                    jobId={proposal.job.id}
                    companyId={proposal.job.companyId}/>
            </GridItem>}
        </VerticalFlexLayout>
    )
}

export default ProposalDetailsCard
