import React, {FC, useState} from "react";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import {Box, Collapse, Dialog, DialogContent, Divider, Paper} from "@mui/material";
import {Body1, Heading4, Heading5} from "components/common/Typography";
import Button from "components/common/Button";
import ContractorHeader from "components/contractor/ContractorHeader";
import CrewMemberDetailsCard from "components/crewMember/CrewMemberDetailsCard";
import ArrowUpIcon from "components/common/icons/ArrowUpIcon";
import ArrowDownIcon from "components/common/icons/ArrowDownIcon";
import strings from "res/strings";
import DisplayField from "components/common/DisplayField";
import {toShortDate, moneyString} from "util/Utilities";
import Offer from "models/Offer";
import {FormProvider, useForm} from "react-hook-form";
import BudgetField from "components/common/BudgetField";
import colors from "res/colors";

interface Props {
    offer: Offer
    expanded?: boolean
    collapsible?: boolean
    editRates?: boolean
    onUpdate?: (o: Offer) => Promise<boolean>
}

const OfferDetailsCard: FC<Props> = (props) => {
    const offer = props.offer
    const form = useForm<Offer>({defaultValues: offer})

    const [open, setOpen] = useState(props.collapsible ? props.expanded : true)
    const [editor, setEditor] = useState(false)
    const [loading, setLoading] = useState(false)

    const onSubmit = form.handleSubmit(async fields => {
        if (props.onUpdate) {
            if (fields.budget?.value) {
                fields.budget.value = Number.parseFloat(fields.budget.value.toString())
            } else {
                fields.budget.currency = ""
            }

            fields.crew.forEach(c => {
                if (c.rate?.rate?.value) {
                    c.rate.rate.value = Number.parseFloat(c.rate.rate.value.toString())
                } else {
                    c.rate.rate.currency = ""
                }
            })

            setLoading(true)
            if (await props.onUpdate(fields)) {
                toggleEditor()
            }
            setLoading(false)
        }
    })

    const handleClick = () => {
        if (props.collapsible ?? true) {
            setOpen(ps => !ps)
        }
    }

    const toggleEditor = () => setEditor(ps => !ps)

    return (<>
        <Paper variant={"outlined"}>
            <Box p={2}>
                <VerticalFlexLayout spacing={2} wrap={"nowrap"}>
                    <HorizontalFlexLayout spacing={2}>
                        <GridItem grow={1}>
                            <ContractorHeader
                                contractor={offer.contractor}
                                stretch={true}/>
                        </GridItem>
                        {props.editRates &&
                        <Button
                            variant={"contained"}
                            onClick={toggleEditor}>
                            Edit Rates
                        </Button>}
                    </HorizontalFlexLayout>
                    <Collapse in={open} timeout="auto">
                        <Box py={2}>
                            <VerticalFlexLayout
                                height={"100%"}
                                wrap={"nowrap"}
                                spacing={4}>
                                <HorizontalFlexLayout spacing={2}>
                                    <DisplayField
                                        title={strings.deadline}
                                        content={toShortDate(offer.job.deadline)}
                                        size={"h6"}/>
                                    <DisplayField
                                        title={strings.budget}
                                        content={moneyString(offer.budget)}
                                        size={"h6"}/>
                                    <GridItem xs={12}>
                                        <DisplayField
                                            title={strings.notes}
                                            content={offer.notes}
                                            size={"h6"}/>
                                    </GridItem>
                                </HorizontalFlexLayout>
                                <Heading5>
                                    {strings.crewMembers}
                                </Heading5>
                                <GridItem xs={12}>
                                    <HorizontalFlexLayout spacing={2}>
                                        {offer.crew?.map(c => (
                                            <GridItem key={c.id} xs={12} sm={6} md={4}>
                                                <CrewMemberDetailsCard
                                                    crewMember={c}/>
                                            </GridItem>
                                        ))}
                                    </HorizontalFlexLayout>
                                </GridItem>
                            </VerticalFlexLayout>
                        </Box>
                    </Collapse>

                    {(props.collapsible ?? true) &&
                    <Button
                        fullWidth
                        color={"secondary"}
                        onClick={handleClick}>
                        {open ? <><ArrowUpIcon/> Less</> :
                            <><ArrowDownIcon/> More</>}
                    </Button>}
                </VerticalFlexLayout>
            </Box>
        </Paper>

        <Dialog
            open={editor}
            fullWidth
            maxWidth={"sm"}
            onClose={toggleEditor}>
            <DialogContent>
                <FormProvider {...form}>
                    <form onSubmit={onSubmit}>
                        <VerticalFlexLayout spacing={2}>
                            <Heading4>
                                Edit Offer Rates
                            </Heading4>
                            <HorizontalFlexLayout
                                spacing={2}
                                alignItems={"center"}>
                                <GridItem xs={12} sm={6}>
                                    <Body1>
                                        {strings.budget}
                                    </Body1>
                                </GridItem>
                                <GridItem xs={12} sm={6}>
                                    <BudgetField namespace="budget"/>
                                </GridItem>
                            </HorizontalFlexLayout>
                            <GridItem xs={12}>
                                <Divider/>
                            </GridItem>
                            <Heading5 color={colors.disabledText}>
                                {strings.crewMembers}
                            </Heading5>
                            {offer.crew?.map((c, i) => [
                                <HorizontalFlexLayout
                                    key={c.id}
                                    spacing={2}
                                    alignItems={"center"}>
                                    <GridItem xs={12} sm={6}>
                                        <Body1>
                                            {c.title}
                                        </Body1>
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <BudgetField namespace={`crew[${i}].rate.rate`}/>
                                    </GridItem>
                                </HorizontalFlexLayout>,
                                <GridItem xs={12}>
                                    <Divider/>
                                </GridItem>
                            ])}
                            <HorizontalFlexLayout spacing={2}>
                                <GridItem xs={12} sm={6}>
                                    <Button
                                        fullWidth
                                        variant={"outlined"}
                                        color={"secondary"}
                                        onClick={toggleEditor}>
                                        {strings.cancel}
                                    </Button>
                                </GridItem>
                                <GridItem xs={12} sm={6}>
                                    <Button
                                        fullWidth
                                        isBusy={loading}
                                        type={"submit"}
                                        variant={"contained"}>
                                        {strings.save}
                                    </Button>
                                </GridItem>
                            </HorizontalFlexLayout>
                        </VerticalFlexLayout>
                    </form>
                </FormProvider>
            </DialogContent>
        </Dialog>
    </>)
}

export default OfferDetailsCard
