import React, {FC} from "react";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import {Heading4, Heading6} from "components/common/Typography";
import colors from "res/colors";
import strings from "res/strings";
import Proposal from "models/Proposal";
import {toShortDate, moneyString, handleFieldErrors} from "util/Utilities";
import OfferDetailsCard from "components/common/OfferDetailsCard";
import Api from "data/remote/Api";
import ErrorResponse from "data/remote/models/ErrorResponse";
import Offer from "models/Offer";
import {useAuth} from "contexts/AuthContext";

interface Props {
    proposal: Proposal
    onUpdate?: (p: Proposal) => void
}

const ProposalDetails: FC<Props> = (props) => {
    const auth = useAuth()
    const proposal = props.proposal
    const modificationAllowed = auth.isInRole("agent") &&
        ["draft", "rejected", "declined"].includes(proposal.status)

    const onUpdate = async (offer: Offer): Promise<boolean> => {
        const data = {
            ...proposal,
            offers: proposal.offers.map(o => o.id === offer.id ? offer : o)
        }

        return await Api.proposals
            .updateProposalAsync(data)
            .then(p => {
                if (props.onUpdate) {
                    props.onUpdate(p as Proposal)
                }
                return true
            })
            .catch(res => {
                handleFieldErrors(res.data as ErrorResponse)
                return false
            })
    }

    return (
        <VerticalFlexLayout
            height={"100%"}
            wrap={"nowrap"}
            spacing={4}>
            <HorizontalFlexLayout spacing={4}>
                <GridItem>
                    <Heading6 color={colors.disabledText}>
                        {strings.quote}
                    </Heading6>
                    <Heading4>
                        {moneyString(proposal.budget)}
                    </Heading4>
                </GridItem>
                <GridItem>
                    <Heading6 color={colors.disabledText}>
                        {strings.deadline}
                    </Heading6>
                    <Heading4>
                        {toShortDate(proposal.job.deadline)}
                    </Heading4>
                </GridItem>
            </HorizontalFlexLayout>
            <Heading6
                color={colors.disabledText}>
                {strings.contractors}
            </Heading6>
            {props.proposal.offers.map(offer => (
                <OfferDetailsCard
                    key={offer.id}
                    offer={offer}
                    expanded={false}
                    collapsible={true}
                    editRates={modificationAllowed}
                    onUpdate={onUpdate}/>
            ))}
        </VerticalFlexLayout>
    )
}

export default ProposalDetails
