import React, {FC, PropsWithChildren, ReactNode, useLayoutEffect, useRef, useState} from 'react';
import MuiAppBar from "@mui/material/AppBar"
import {
    Box, IconButton, Toolbar,
    SwipeableDrawer, Avatar, MenuItem, Menu
} from "@mui/material";
import {GridItem, HorizontalFlexLayout} from "components/common/FlexLayout";
import {Link, useNavigate} from "react-router-dom";
import strings from "res/strings";
import MenuIcon from "components/common/icons/MenuIcon";
import {Heading4} from "components/common/Typography";
import ArrowLeftIcon from "components/common/icons/ArrowLeftIcon";
import colors from "res/colors";
import {useAuth} from "contexts/AuthContext";
import Api from "data/remote/Api";
import ErrorResponse from "data/remote/models/ErrorResponse";
import {handleFieldErrors} from "util/Utilities";

interface Props extends PropsWithChildren {
    drawerList?: ReactNode
}

const drawerWidth = 250

const AppBarContainer: FC<Props> = (props) => {
    const {update, identity, isAuthenticated} = useAuth()
    const navigate = useNavigate()
    const appBarRef = useRef<HTMLDivElement>(null)

    // NOTE: Used to push the top of the content under the App Bar.
    const [appBarHeight, setAppBarHeight] = useState(0)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    useLayoutEffect(() => {
        setAppBarHeight(appBarRef.current?.offsetHeight ?? 0)
    }, [])

    const toggleDrawer = (open?: boolean) => () => {
        setIsDrawerOpen(open ?? !isDrawerOpen)
    }

    const setMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    };

    const toggleMenu = () => {
        setAnchorEl(null)
    }

    const classes = {
        root: {
            display: "flex",
            height: "100%"
        },
        appBar: {
            zIndex: 1201
        },
        main: {
            flexGrow: 1,
            paddingTop: `${appBarHeight}px`
        },
        drawer: {
            width: isDrawerOpen ? drawerWidth : 0
        },
        drawerContent: {
            width: isDrawerOpen ? drawerWidth : 0,
            paddingTop: `${appBarHeight}px`
        }
    }

    return (
        <Box sx={classes.root}>
            <MuiAppBar ref={appBarRef} position="fixed" sx={classes.appBar}>
                <Toolbar>
                    <HorizontalFlexLayout
                        spacing={2} alignItems={"center"} wrap={"nowrap"}>
                        {props.drawerList &&
                        <IconButton edge="start" onClick={toggleDrawer()}>
                            {isDrawerOpen ? <ArrowLeftIcon/> : <MenuIcon/>}
                        </IconButton>}
                        <Link to={isAuthenticated() ? "/" : "/account/sign-in"}>
                            <img style={{width: 150}}
                                 alt={strings.appName} src={process.env.PUBLIC_URL + "/full-logo.svg"}/>
                        </Link>
                        <GridItem sx={{flexGrow: 1}}>
                            <Heading4>
                                {/*Title...*/}
                            </Heading4>
                        </GridItem>
                        {isAuthenticated() &&
                        <Avatar
                            onClick={setMenu}
                            sx={{
                                backgroundColor: colors.pearlWhite,
                                color: colors.text
                            }}/>}
                    </HorizontalFlexLayout>
                </Toolbar>
            </MuiAppBar>

            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={toggleMenu}>
                {identity.roles.includes("contractor") &&
                <MenuItem onClick={() => {
                    navigate(`/${identity.roles[0]}/profile`)
                    toggleMenu()
                }}>
                    Profile
                </MenuItem>}
                <MenuItem onClick={async () => {
                    // TODO: Move it to the AuthContext.
                    await Api.auth.signOutAsync()
                        .then(() => {
                            document.cookie = "id-token=; path=/; max-age=0"
                            update()
                            toggleMenu()
                            navigate("/", {replace: true})
                        })
                        .catch(res => {
                            const data = res.data as ErrorResponse
                            if (res.status === 400 && data.errors?.find(e => e.field === "token")) {
                                document.cookie = "id-token=; path=/; max-age=0"
                                update()
                                toggleMenu()
                                navigate("/", {replace: true})
                            } else {
                                handleFieldErrors(res.data as ErrorResponse)
                            }
                        })
                }}>Logout</MenuItem>
            </Menu>

            {props.drawerList &&
            <nav style={{width: isDrawerOpen ? drawerWidth : 0}}>
                <SwipeableDrawer
                    anchor={'left'}
                    sx={classes.drawer}
                    variant={"persistent"}
                    open={isDrawerOpen}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    ModalProps={{keepMounted: true}}>
                    <Box sx={classes.drawerContent}>
                        {props.drawerList}
                    </Box>
                </SwipeableDrawer>
            </nav>}

            <Box sx={classes.main}>
                <main>
                    {props.children}
                </main>
            </Box>
        </Box>
    )
}

export default AppBarContainer
