import React, {FC, useState} from "react"
import {GridItem, VerticalFlexLayout} from "components/common/FlexLayout"
import {TextField, Typography} from "@mui/material"
import {useForm} from "react-hook-form"
import Button from "components/common/Button"
import strings from "res/strings"
import Api from "data/remote/Api";
import {handleFieldErrors} from "util/Utilities";
import ErrorResponse from "data/remote/models/ErrorResponse";
import {Body1, Heading4} from "components/common/Typography";
import {useNavigate} from "react-router-dom";

const ForgotPasswordControl: FC = () => {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [username, setUsername] = useState<string>()
    const {register, handleSubmit, setError} = useForm<{ username: string }>()

    const onSubmit = handleSubmit(async fields => {
        setLoading(true)
        await Api.auth
            .forgotPasswordAsync(fields.username)
            .then(() => setUsername(fields.username))
            .catch(res => handleFieldErrors(res.data as ErrorResponse, setError))
        setLoading(false)
    })

    if (!username) {
        return (
            <form onSubmit={onSubmit}>
                <VerticalFlexLayout spacing={4}>
                    <Heading4 component="h1">
                        <strong>{strings.forgotPassword}</strong>
                    </Heading4>
                    <Body1>
                        {strings.forgotPasswordEnterUsernameMessage}
                    </Body1>
                    <GridItem>
                        <Typography variant={"subtitle1"}>
                            {strings.emailAddress}
                        </Typography>
                        <TextField
                            {...register("username")}
                            type="email"
                            fullWidth
                            required
                            autoFocus
                            variant="outlined"/>
                    </GridItem>
                    <Button
                        type="submit"
                        fullWidth
                        size={"large"}
                        isBusy={loading}
                        variant="contained"
                        color="primary">
                        {strings.send}
                    </Button>
                </VerticalFlexLayout>
            </form>
        )
    } else {
        return (
            <VerticalFlexLayout spacing={4}>
                <Heading4 component="h1">
                    <strong>{strings.resetPassword}</strong>
                </Heading4>
                <Body1>
                    An email has been sent to:
                </Body1>
                <Body1 align={"center"}>
                    <strong>{username}</strong>
                </Body1>
                <Body1>
                    Please check your inbox and click the link provided in the email to reset your password.
                </Body1>
                <Button
                    fullWidth
                    size={"large"}
                    isBusy={loading}
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/")}>
                    {strings.returnToHome}
                </Button>
            </VerticalFlexLayout>
        )
    }
}

export default ForgotPasswordControl
