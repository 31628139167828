import React, {FC} from "react"
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator"
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import {Box} from "@mui/material";
import Button from "components/common/Button";
import {useNavigate} from "react-router-dom";
import Job from "models/Job";
import {Heading3} from "components/common/Typography";
import strings from "res/strings";
import JobsTable from "components/job/JobsTable";

const Jobs: FC = () => {
    const navigate = useNavigate()

    const onClick = (job: Job) => {
        if (job.status === "draft") {
            navigate(`/company/jobs/edit/${job.id}`)
        } else {
            navigate(`/company/jobs/${job.id}`)
        }
    }

    return (<>
        <HtmlMetaDecorator title={strings.myAssignments}/>

        <VerticalFlexLayout
            height={"100%"}
            wrap={"nowrap"}>
            <Box p={2} sx={{backgroundColor: "white"}}>
                <HorizontalFlexLayout
                    spacing={2}
                    alignItems={"center"}
                    justify={"flex-end"}>
                    <GridItem grow={2}>
                        <Heading3>
                            {strings.myAssignments}
                        </Heading3>
                    </GridItem>
                    <Button
                        variant="contained"
                        onClick={() => navigate("/company/jobs/create")}>
                        {strings.createAssignment}
                    </Button>
                </HorizontalFlexLayout>
            </Box>
            <GridItem grow={1}>
                <JobsTable
                    onClick={onClick}/>
            </GridItem>
        </VerticalFlexLayout>
    </>)
}

export default Jobs
