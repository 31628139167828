import React, {FC} from "react"
import {Box} from "@mui/material";
import colors from "res/colors";
import {VerticalFlexLayout} from "components/common/FlexLayout";

const Loading: FC = () => {
    const style = {
        width: 16,
        height: 16,
        backgroundColor: colors.primary,
    }

    return (
        <VerticalFlexLayout
            height={"100%"}
            justify={"center"}
            alignItems={"center"}>
            <div className="lds-ellipsis">
                <Box sx={style}/>
                <Box sx={style}/>
                <Box sx={style}/>
                <Box sx={style}/>
            </div>
        </VerticalFlexLayout>
    )
}

export default Loading
