import React, {FC, useState} from "react"
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator"
import {Box, Dialog, DialogContent, DialogTitle} from "@mui/material";
import {useNavigate} from "react-router-dom";
import SearchContractor from "components/contractor/SearchContractor";
import strings from "res/strings";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import {Heading3, Heading4} from "components/common/Typography";
import Button from "components/common/Button";
import IconButton from "components/common/IconButton";
import CloseIcon from "components/common/icons/CloseIcon";
import UpsertUserControl from "components/user/UpsertUserControl";

const Contractors: FC = () => {
    const navigate = useNavigate()

    const [open, setOpen] = useState(false)

    return (<>
        <HtmlMetaDecorator
            title={strings.contractors}/>

        <VerticalFlexLayout
            height={"100%"}
            wrap={"nowrap"}
            sx={{backgroundColor: "white"}}>
            <Box p={2} sx={{backgroundColor: "white"}}>
                <HorizontalFlexLayout spacing={2}>
                    <GridItem grow={1}>
                        <Heading3>{strings.contractors}</Heading3>
                    </GridItem>
                    <Button
                        variant={"contained"}
                        onClick={() => setOpen(true)}>
                        {strings.createContractor}
                    </Button>
                </HorizontalFlexLayout>
            </Box>
            <Box p={2}>
                <SearchContractor
                    label={strings.profile}
                    onSelect={c => navigate("/console/contractors/" + c.id)}/>
            </Box>
        </VerticalFlexLayout>

        <Dialog
            open={open}
            fullWidth
            maxWidth={"sm"}>
            <DialogTitle>
                <HorizontalFlexLayout wrap={"nowrap"}>
                    <GridItem sx={{flexGrow: 1}}>
                        <Heading4>{strings.createContractor}</Heading4>
                    </GridItem>
                    <IconButton
                        onClick={() => setOpen(false)}>
                        <CloseIcon/>
                    </IconButton>
                </HorizontalFlexLayout>
            </DialogTitle>
            <DialogContent dividers>
                <UpsertUserControl
                    role={"contractor"}
                    onSuccess={() => setOpen(false)}/>
            </DialogContent>
        </Dialog>
    </>)
}

export default Contractors
