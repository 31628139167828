import React from "react";
import IconProps from "components/common/icons/IconProps";

const SendIcon: React.FC<IconProps> = (props) => (
    <div className={"svg-container"}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size ?? "24"}
            height={props.size ?? "24"}
            viewBox="0 0 24 24">
            <path
                d="M2.00952 20L22 12L2.00952 4L2 10.2222L16.2857 12L2 13.7778L2.00952 20Z"
                fill={props.color ?? "black"}/>
        </svg>
    </div>
)

export default SendIcon
