import React, {FC, PropsWithChildren, useEffect} from 'react';
import {BrowserRouter, Outlet, Route, Routes, useLocation} from "react-router-dom";
import NotFound from "pages/NotFound";
import TermsConditions from "pages/legal/TermsConditions";
import Home from "pages/Home";
import Error from "pages/Error";
import AccountRouter from "routers/AccountRouter";
import UiKit from "pages/UiKit";
import AdminRouter from "routers/AdminRouter"
import ContractorRouter from "routers/ContractorRouter";
import CompanyRouter from "routers/CompanyRouter";
import {useAuth} from "contexts/AuthContext";
import PrivacyPolicy from "pages/legal/PrivacyPolicy";
import HomeAppBar from "components/common/HomeAppBar";

const ScrollToTop: FC<PropsWithChildren<{}>> = (props) => {
    const {pathname} = useLocation()

    useEffect(() => window.scrollTo(0, 0), [pathname])

    return (<>{props.children}</>)
}

const Router: FC = () => {
    const {isInRole} = useAuth()

    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <ScrollToTop>
                <Routes>
                    {isInRole("agent") &&
                    <Route path="/console/*" element={<AdminRouter/>}/>}
                    {isInRole("contractor") &&
                    <Route path="/contractor/*" element={<ContractorRouter/>}/>}
                    {isInRole("company") &&
                    <Route path="/company/*" element={<CompanyRouter/>}/>}

                    <Route path="/ui-kit" element={<UiKit/>}/>

                    <Route element={<HomeAppBar><Outlet/></HomeAppBar>}>
                        <Route path="/account/*" element={<AccountRouter/>}/>
                        <Route path="/legal/terms-and-conditions" element={<TermsConditions/>}/>
                        <Route path="/legal/privacy-policy" element={<PrivacyPolicy/>}/>
                        <Route path="/404" element={<NotFound/>}/>
                        <Route path="/error" element={<Error/>}/>
                        <Route path="" element={<Home/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Route>
                </Routes>
            </ScrollToTop>
        </BrowserRouter>
    )
}

export default Router
