import React, {FC} from "react"
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator"
import strings from "res/strings"
import {GridItem, VerticalFlexLayout} from "components/common/FlexLayout";
import {Body1, Body2, Heading1, Heading4} from "components/common/Typography";
import {Box, Container} from "@mui/material";
import colors from "res/colors";
import Footer from "components/common/Footer";

const PrivacyPolicy: FC = () => {
    return (<>
        <HtmlMetaDecorator
            title={strings.privacyPolicy}/>

        <Container maxWidth={"lg"}>
            <Box px={2} py={6}>
                <VerticalFlexLayout spacing={4} sx={{whiteSpace: "pre-wrap"}}>
                    <Heading1>Privacy Policy</Heading1>
                    <Body2 color={colors.copy}>
                        Last updated: 26 JAN 2022
                    </Body2>
                    <Body1 color={colors.copy} align={"justify"}>
                        {"This Privacy Policy sets out how Tao Media Limited is committed to protecting your privacy, details of the information that we may collect from you and how we may use that information when you use our websites. Please take your time to read this Privacy Policy carefully."}
                    </Body1>
                    <GridItem>
                        <Heading4 pb={2}>
                            Information about us
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"Tao Media Limited is the controller and responsible for your personal data (we, us or our). We are incorporated and registered in England and Wales with company number 12921480 and have our registered office at 71-75 Shelton Street, London, WC2H 9JQ, England.\n\nOur site is operated by Tao Media Limited (we or us). We are incorporated and registered in England and Wales with company number 12921480 and have our registered office at 71-75 Shelton Street, London, WC2H 9JQ, England.\n\nIf you have any questions about our privacy practices, please contact us in the following ways:\n\nE-mail address: hello@taomedia.io\n\nPostal address: 71-75, Shelton Street, London, Greater London, WC2H 9JQ, UNITED KINGDOM\n\nTelephone number: +44 20 3728 6203\n\nYou have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK regulator for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance."}
                        </Body1>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            Changes to the privacy policy and your duty to inform us of changes
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"We keep our privacy policy under regular review. It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us."}
                        </Body1>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            Personal information we will be collecting
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data). We may collect, use and transfer the following personal information about you:"}
                        </Body1>

                        <ul>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"Identity includes your first and last names, company details (i.e. for brands and non-profit organizations), usernames or similar identifies, date of birth, country of residence, your age, your marital status, a copy of your passport, your curriculum vitae (CV), photo, your gender and your status (i.e. are you a student or a professional)."}
                                </Body1>
                            </li>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"Contact Data includes your e-mail address, social media accounts and telephone numbers."}
                                </Body1>
                            </li>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"Technical Data includes information about how you use our website, IT, communication and other systems, internet protocol (IP) address, your location data, login data, browser type and version, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this website."}
                                </Body1>
                            </li>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"Profile Data includes your username and passwords, your interests, preferences, feedback and survey responses."}
                                </Body1>
                            </li>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"Usage Data includes information about how you use our website and our products and/or services."}
                                </Body1>
                            </li>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"Marketing and Communications Data includes your preferences in receiving marketing from us and our third-parties, your communication preferences, and your responses to surveys, competitions and promotions."}
                                </Body1>
                            </li>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"Financial Data includes your billing information, bank account, mobile wallet and payment card details."}
                                </Body1>
                            </li>
                        </ul>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            How is your personal data collected
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"We collect most of this personal information through:"}
                        </Body1>
                        <ul>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"Direct interactions: directly from you by email, mobile application, post, over the phone and/or via our website, including personal data you provide when you:\napply for our services;\nsubscribe to our service or publications;\nrequest marketing to be sent to you;\nenter a competition, promotion or survey; or\ngive us feedback or contact us."}
                                </Body1>
                            </li>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"Automated technologies or interactions: as you interact with our website, we may automatically collect Technical Data about your equipment, browsing actions and patterns. We may collect this personal data by using cookies, server logs and other similar technologies. For more information on our use of cookies, please see our Cookies Policy."}
                                </Body1>
                            </li>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"Third parties or publicly available sources: which may include Companies House and the Electoral Register based inside the UK."}
                                </Body1>
                            </li>
                        </ul>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            How we use your personal data
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:"}
                        </Body1>
                        <ul>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"where we need to perform the contract we are about to enter into or have entered into with you."}
                                </Body1>
                            </li>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests."}
                                </Body1>
                            </li>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"Where we need to comply with a legal obligation."}
                                </Body1>
                            </li>
                        </ul>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"Generally, we do not rely on consent as a legal basis for processing your personal data although we will get your consent before sending third party direct marketing communications to you via email or text message. You have the right to withdraw consent to marketing at any time by contacting us."}
                        </Body1>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            Purposes for which we will use your personal data
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.\n\nNote that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below."}
                        </Body1>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            Marketing
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising."}
                        </Body1>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            Promotional offers from us
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing).\n\nYou will receive marketing communications from us if you have requested information from us or purchased services from us and you have not opted out of receiving that marketing."}
                        </Body1>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            Third-party marketing
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"We will get your express opt-in consent before we share your personal data with any third party for marketing purposes."}
                        </Body1>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            Opting out
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"You can ask us or third parties to stop sending you marketing messages at any time by following the opt-out links on any marketing message sent to you.\n\nWhere you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a service experience or other transactions."}
                        </Body1>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            Change of purpose
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.\nIf we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.\n\nPlease note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law."}
                        </Body1>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            Data security
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.\n\nWe have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so."}
                        </Body1>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            Data Retention
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"We will only retain your personal information for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.\n\nTo determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.\n\nBy law we have to keep basic information about our customers (including Contact, Identity and Financial) for six years after they cease being customers for tax purposes.\n\nIn some circumstances you can ask us to delete your data: see your legal rights below for further information.\n\nIn some circumstances we will anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you."}
                        </Body1>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            Who we share your personal information with
                        </Heading4>
                        <ul>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"We may disclose and exchange information with law enforcement agencies and regulatory bodies to comply with our legal and regulatory obligations."}
                                </Body1>
                            </li>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"We may also need to share some personal information with other parties, such as potential buyers of some or all of our business or during a re-structuring. Usually, information will be anonymised but this may not always be possible. The recipient of the information will be bound by confidentiality obligations."}
                                </Body1>
                            </li>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"This website may include links to third-party websites, such as our Facebook, Twitter, Instagram and/or LinkedIn social media pages (as of “Last Updated” date). Clicking on those links may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy policies. You should read any policies and other statements on such websites carefully."}
                                </Body1>
                            </li>
                        </ul>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            Your legal rights
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"Under certain circumstances, you have rights under data protection laws in relation to your personal data. Please click on the links below to find out more about these rights:"}
                        </Body1>
                        <ul>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"Request access to your personal data: this enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it."}
                                </Body1>
                            </li>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"Request correction of your personal data: this enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us."}
                                </Body1>
                            </li>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"Request erasure of your personal data: this enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request."}
                                </Body1>
                            </li>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"Object to processing of your personal data:where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms."}
                                </Body1>
                            </li>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"Request restriction of processing your personal data:this enables you to ask us to suspend the processing of your personal data in the following scenarios:\nIf you want us to establish the data's accuracy.\nWhere our use of the data is unlawful but you do not want us to erase it.\nWhere you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.\nYou have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it."}
                                </Body1>
                            </li>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"Request transfer of your personal data: we will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you."}
                                </Body1>
                            </li>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"Right to withdraw consent where we are relying on consent to process your personal data:this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent."}
                                </Body1>
                            </li>
                        </ul>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"If you wish to exercise any of the rights set out above, please contact us."}
                        </Body1>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            No fee usually required
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances."}
                        </Body1>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            What we may need from you
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response."}
                        </Body1>
                    </GridItem>
                </VerticalFlexLayout>
            </Box>
        </Container>
        <Footer/>
    </>)
}

export default PrivacyPolicy
