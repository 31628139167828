import React, {FC} from "react"
import Contractor from "models/Contractor"
import {VerticalFlexLayout} from "components/common/FlexLayout"
import strings from "res/strings"
import {Tags} from "components/common/Tags"
import {locationString} from "util/Utilities";

interface Props {
    contractor: Contractor
}

const ContractorDetails: FC<Props> = (props) => {
    const contractor = props.contractor

    return (
        <VerticalFlexLayout spacing={2}>
            {Boolean(contractor.expertise?.length) &&
            <Tags label={strings.expertise} items={contractor.expertise}/>}

            {Boolean(contractor.experiences?.length) &&
            <Tags label={strings.experiences} items={contractor.experiences}/>}

            {Boolean(contractor.equipment) &&
            <Tags label={strings.equipment} items={Object.keys(contractor.equipment)}/>}

            {Boolean(contractor.specialization?.length) &&
            <Tags label={strings.specialization} items={contractor.specialization}/>}

            {Boolean(contractor.languages?.length) &&
            <Tags label={strings.languages} items={contractor.languages}/>}

            {contractor.location &&
            <Tags label={strings.hometown} items={[locationString(contractor.location)]}/>}
        </VerticalFlexLayout>
    )
}

export default ContractorDetails
