import React, {forwardRef} from "react";
import {TransitionProps} from "@mui/material/transitions";
import {Slide} from "@mui/material";

const DialogTransition = function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return (
        <Slide
            ref={ref}
            direction="up"
            {...props} />
    )
}

export default forwardRef(DialogTransition)
