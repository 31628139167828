import React, {FC} from "react"
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator"
import strings from "res/strings"
import {GridItem, VerticalFlexLayout} from "components/common/FlexLayout";
import {Body1, Body2, Heading1, Heading4} from "components/common/Typography";
import {Box, Container} from "@mui/material";
import colors from "res/colors";
import Footer from "components/common/Footer";

const TermsConditions: FC = () => {
    return (<>
        <HtmlMetaDecorator
            title={strings.termsConditions}/>

        <Container maxWidth={"lg"}>
            <Box px={2} py={6}>
                <VerticalFlexLayout spacing={6} sx={{whiteSpace: "pre-wrap"}}>
                    <Heading1>
                        Terms and Conditions
                    </Heading1>
                    <Body2 color={colors.copy}>
                        Last updated: 26 JAN 2022
                    </Body2>
                    <Body1 color={colors.copy} align={"justify"}>
                        {"These terms of use (together with the documents referred to in it) let you know the terms on which you can use our website http://www.taomedia.io/ (our site). That includes accessing, browsing, or registering to use our site. We recommend that you print a copy of these terms of use for future reference. By using our site, you confirm that you accept these terms of use and that you agree to comply with them. If you do not agree to these terms of use, you must not use our site."}
                    </Body1>
                    <GridItem>
                        <Heading4 pb={2}>
                            Other applicable terms
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"These terms of use refer to the following additional terms, which also apply to your use of our site:"}
                        </Body1>
                        <ul>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"Our Privacy Notice, which lets you know the terms on which we process any personal data we collect from you, that you provide to us or that you receive from other sources. By using our site, you consent to us collecting your data, the terms of the Privacy Policy and you confirm that all the data you provide us with is accurate; and"}
                                </Body1>
                            </li>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"Our Cookie Policy, which gives you information about the cookies on our site."}
                                </Body1>
                            </li>
                        </ul>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            Information about us
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"Our site is operated by Tao Media Limited (we or us). We are incorporated and registered in England and Wales with company number 12921480 and have our registered office at 71-75 Shelton Street, London, WC2H 9JQ, England.\n\nTo contact us, please write us an email to hello@taomedia.io."}
                        </Body1>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            No reliance on information
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"The content on our site is provided for general information only. It’s not intended to be taken as advice on which you should make any decisions. If you want advice, you must get it from a professional before doing, or not doing, anything on the basis of the content on our site.\n\nAlthough we try to make sure the information on our site is current, we make no representations, warranties or guarantees, either expressed or implied, that the content on our site is accurate, complete or up-to-date."}
                        </Body1>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            Changes to these terms
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"We may revise these terms of use at any time by amending this page. Please check it from time to time to take notice of any changes we could make, as they’re binding on you."}
                        </Body1>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            Changes to our site
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"We may update our site from time to time, and may change its content at any time. However, please note that any of the content on our site may be out of date at any given time, and we’re under no obligation to update it. We do not guarantee that our site, or any content on it, will be free from errors or omissions."}
                        </Body1>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            Accessing our site
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"Our site is made available to you free of charge. We don’t guarantee that it, or any content on it, will always be available or uninterrupted. Your access to our site is permitted on a temporary basis. We can suspend, withdraw, discontinue or change all or any part of our site without notice. We are not liable to you if, for any reason, our site is unavailable at any time or for any period.\n\nYou’re responsible for making the arrangements necessary to access our site. You’re also responsible for making sure that all persons who access our site through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them."}
                        </Body1>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            Intellectual property rights
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"We’re the owner or the licensee of all intellectual property rights contained on our site and in the material published on it. Those works are protected by laws and treaties around the world, including in relation to copyright and trademarks. All such rights are reserved.\n\nYou may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others to content posted on our site.\n\nYou must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.\n\nOur status (and that of any identified contributors) as the authors of content on our site must always be acknowledged.\n\nYou must not use any part of the content on our site for commercial purposes without obtaining a license to do so from us.\n\nIf you print off, copy or download any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our request, return or destroy any copies of the materials you have made."}
                        </Body1>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            Limitation of our liability
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"Nothing in these terms of use excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation, or any other liability that cannot be excluded or limited by English law.\n\nTo the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may apply to our site or any content on it – expressed or implied.\n\nWe are not liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, in connection with any of the following (including but not limited to):"}
                        </Body1>
                        <ul>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"Use of, or inability to use, our site; or"}
                                </Body1>
                            </li>
                            <li>
                                <Body1 color={colors.copy} align={"justify"}>
                                    {"Use of or reliance on any content displayed on our site.\n\n"}
                                </Body1>
                            </li>
                        </ul>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"Please note that we only provide our site for domestic and private use. You agree not to use our site for any commercial or business purposes and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity if you do.\n\nWe will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of our site or to you downloading of any content on it, or on any website linked to it.\n\nOur site sometimes contains links to other sites and resources provided by third parties. These links are provided for your information only. We have no control over the contents of those sites or resources. We assume no responsibility for the content of websites linked on our site. Such links should not be interpreted as endorsement by us of those linked websites. We will not be liable for any loss or damage that may arise from your use of them."}
                        </Body1>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            Malware
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"We do not guarantee that our site will be secure or free from bugs or malware. It’s up to you to configure your information technology, computer programmes and platform to access our site, and you should use your own virus protection software.\n\nYou must not misuse our site by knowingly introducing trojans, worms, logic bombs, any other malware (including viruses) or other material which is malicious or technologically harmful or detrimental, in any way to us, or any third party. You must not attempt to gain unauthorized access to our site, the server on which our site is stored, or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990 and possibly other laws. We will report any such breach to the relevant law enforcement authorities and we will cooperate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately."}
                        </Body1>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            Applicable law
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"Please note that these terms of use, its subject matter and its formation, are governed by English law. You and we both agree that the courts of England and Wales will have exclusive jurisdiction."}
                        </Body1>
                    </GridItem>
                    <GridItem>
                        <Heading4 pb={2}>
                            Trade marks
                        </Heading4>
                        <Body1 color={colors.copy} align={"justify"}>
                            {"You are not permitted to use any trademarks registered by Tao Media without our approval."}
                        </Body1>
                    </GridItem>
                </VerticalFlexLayout>
            </Box>
        </Container>
        <Footer/>
    </>)
}

export default TermsConditions
