import React, {FC} from "react";
import {FormHelperText, MenuItem, Select, SelectProps} from "@mui/material";
import {FieldError} from "react-hook-form/dist/types/errors";
import {Typography} from "components/common/Typography";

type Props = SelectProps & {
    // TODO: Support other types, at least KVP
    items: string[]
    fieldError?: Partial<FieldError>
}

const LookupFields: FC<Props> = (props) => {
    const {fieldError, ...selectProps} = props
    const isError = Boolean(props.fieldError)

    return (<>
        {props.label &&
        <Typography
            component={"label"}
            sx={{lineHeight: "20px", fontSize: 14}}>
            {props.label}
        </Typography>}
        <Select
            fullWidth
            required
            error={isError}
            {...selectProps}>
            {props.items.map(c => (
                <MenuItem key={c} value={c}>{c}</MenuItem>
            ))}
        </Select>
        {isError &&
        <FormHelperText error={true}>
            {fieldError?.message}
        </FormHelperText>}
    </>)
}

export default LookupFields
