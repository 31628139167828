import React, {ChangeEvent, FC} from "react"
import {Avatar, Badge, Box, Paper} from "@mui/material";
import Details from "components/contractor/ContractorDetails";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import colors from "res/colors";
import {Body1, Body2, Heading4, Heading5} from "components/common/Typography";
import {handleFieldErrors, moneyString} from "util/Utilities";
import Contractor from "models/Contractor";
import strings from "res/strings";
import DisplayField from "components/common/DisplayField";
import StarIcon from "components/common/icons/StarIcon";
import Api from "data/remote/Api";
import {useAuth} from "contexts/AuthContext";
import EditIcon from "components/common/icons/EditIcon";
import IconButton from "components/common/IconButton";
import {useAlert} from "contexts/AlertContext";
import ErrorResponse from "data/remote/models/ErrorResponse";
import Button from "components/common/Button";
import {useLiveChat} from "components/common/LiveChat";

interface Props {
    contractor: Contractor
}

const Profile: FC<Props> = (props) => {
    const {identity} = useAuth()
    const alert = useAlert()
    const chat = useLiveChat()
    const contractor = props.contractor

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.files?.length) {
            const file = e.currentTarget.files[0]
            if (file.size > (10 * 1024 * 1024)) {
                e.currentTarget.value = ""
                alert.enqueue({
                    message: "Maximum allowed file size is 10 MB.",
                    severity: "error"
                })
            } else {
                await Api.media
                    .getProfilePictureUploadInstructionAsync(identity.username, {
                        id: "",
                        title: "Profile Picture",
                        contentType: file!.type,
                        filename: file!.name,
                        contentLength: file!.size,
                        type: "Picture",
                        url: ""
                    })
                    .then(async data => {
                        if (data) {
                            await Api.media
                                .uploadAsync(data, file)
                                .then(() => window.location.reload())
                                .catch(res => handleFieldErrors(res.data as ErrorResponse))
                        }
                    })
                    .catch(res => handleFieldErrors(res.data as ErrorResponse))
            }
        }
    }

    return (
        <HorizontalFlexLayout spacing={2}>
            <GridItem xs={12} md={4} lg={3}>
                <Paper variant={"outlined"}>
                    <Box p={2}>
                        <VerticalFlexLayout spacing={4}>
                            <GridItem alignSelf={"center"}>
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                    badgeContent={
                                        <IconButton
                                            variant={"contained"}
                                            component={"label"}
                                            sx={{
                                                display: contractor.id === identity.username ? "flex" : "none",
                                                borderRadius: "50%",
                                                width: 32,
                                                height: 32
                                            }}>
                                            <EditIcon size={16}/>
                                            <input
                                                hidden
                                                type="file"
                                                accept="image/*"
                                                onChange={handleFileChange}/>
                                        </IconButton>
                                    }>
                                    <Avatar
                                        variant={"circular"}
                                        src={contractor.image}
                                        alt={contractor.name}
                                        sx={{
                                            margin: "auto",
                                            width: 180,
                                            height: 180,
                                            backgroundColor:
                                            colors.pearlWhite,
                                            color: colors.text
                                        }}>
                                        {contractor.name?.slice(0, 2)}
                                    </Avatar>
                                </Badge>
                            </GridItem>
                            <HorizontalFlexLayout
                                justify={"center"}
                                alignItems={"center"}
                                spacing={1}
                                wrap={"nowrap"}>
                                <Heading5>
                                    {contractor.agentRating?.toFixed(1) ?? "-"}
                                </Heading5>
                                <StarIcon
                                    color={colors.primary}/>
                            </HorizontalFlexLayout>
                            <GridItem>
                                <Heading4 align={"center"}>
                                    {contractor.name}
                                </Heading4>
                                <Body1
                                    paddingY={1}
                                    color={colors.disabledText}
                                    align={"center"}>
                                    {contractor.title}
                                </Body1>
                                <Body2
                                    color={colors.disabledText}
                                    align={"center"}>
                                    {`${moneyString(contractor.rate?.rate)} / ${contractor.rate?.rateInterval ?? "-"}`}
                                </Body2>
                            </GridItem>
                            <Button
                                variant={"contained"}
                                fullWidth
                                onClick={() => chat.start(contractor.id)}>
                                {strings.liveChat}
                            </Button>
                        </VerticalFlexLayout>
                    </Box>
                </Paper>
            </GridItem>
            <GridItem grow={1}>
                <Paper variant={"outlined"}>
                    <Box p={2}>
                        <VerticalFlexLayout spacing={2}>
                            <Heading5>
                                {strings.details}
                            </Heading5>
                            <DisplayField
                                size={"h6"}
                                title={strings.taoMediaId}
                                content={contractor.taoMediaId ?? '-'}/>
                            <DisplayField
                                size={"h6"}
                                title={strings.employer}
                                content={contractor.employer ?? '-'}/>
                            <DisplayField
                                size={"h6"}
                                title={strings.nationality}
                                content={contractor.nationality ?? '-'}/>
                            <DisplayField
                                size={"h6"}
                                title={strings.phone}
                                content={contractor.phone ?? '-'}/>
                            <DisplayField
                                size={"h6"}
                                title={strings.email}
                                content={contractor.email ?? '-'}/>
                        </VerticalFlexLayout>
                    </Box>
                </Paper>
            </GridItem>
            {contractor.bankAccount &&
            <GridItem grow={1}>
                <Paper variant={"outlined"}>
                    <Box p={2}>
                        <VerticalFlexLayout spacing={2}>
                            <Heading5>
                                {strings.bankDetails}
                            </Heading5>
                            <DisplayField
                                size={"h6"}
                                title={strings.name}
                                content={contractor.bankAccount.name ?? '-'}/>
                            <DisplayField
                                size={"h6"}
                                title={strings.iban}
                                content={contractor.bankAccount.iban ?? '-'}/>
                        </VerticalFlexLayout>
                    </Box>
                </Paper>
            </GridItem>}
            {Boolean(contractor.portfolio && Object.keys(contractor.portfolio).length) &&
            <GridItem grow={1}>
                <Paper variant={"outlined"}>
                    <Box p={2}>
                        <VerticalFlexLayout spacing={2}>
                            <Heading5>
                                {strings.portfolio}
                            </Heading5>
                            {Object.keys(contractor.portfolio).map((p, i) =>
                                <DisplayField
                                    key={i}
                                    size={"h6"}
                                    title={p}
                                    content={contractor.portfolio[p].url}/>
                            )}
                        </VerticalFlexLayout>
                    </Box>
                </Paper>
            </GridItem>}
            <GridItem grow={1}>
                <Paper variant={"outlined"}>
                    <Box p={2}>
                        <Details
                            contractor={contractor}/>
                    </Box>
                </Paper>
            </GridItem>
        </HorizontalFlexLayout>
    )
}

export default Profile
