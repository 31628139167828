import React from "react";
import IconProps from "components/common/icons/IconProps";

const DownloadIcon: React.FC<IconProps> = (props) => (
    <div className={"svg-container"}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size ?? "24"}
            height={props.size ?? "24"}
            viewBox="0 0 24 24">
            <path
                d="M13 11.641L14.121 10.521C14.3095 10.3387 14.562 10.2377 14.8242 10.2398C15.0864 10.2419 15.3373 10.3469 15.5228 10.5322C15.7084 10.7175 15.8137 10.9682 15.8162 11.2304C15.8186 11.4926 15.718 11.7453 15.536 11.934L12.707 14.763C12.6143 14.8562 12.5042 14.9301 12.3829 14.9806C12.2615 15.0311 12.1314 15.057 12 15.057C11.8686 15.057 11.7385 15.0311 11.6171 14.9806C11.4958 14.9301 11.3857 14.8562 11.293 14.763L8.464 11.934C8.37116 11.841 8.29753 11.7307 8.24734 11.6092C8.19714 11.4878 8.17135 11.3577 8.17144 11.2263C8.17154 11.0949 8.19751 10.9648 8.24788 10.8435C8.29825 10.7221 8.37202 10.6118 8.465 10.519C8.55798 10.4262 8.66833 10.3525 8.78976 10.3023C8.91119 10.2521 9.04131 10.2264 9.17271 10.2264C9.3041 10.2265 9.43419 10.2525 9.55555 10.3029C9.67691 10.3532 9.78716 10.427 9.88 10.52L11 11.641V6C11 5.73478 11.1054 5.48043 11.2929 5.29289C11.4804 5.10536 11.7348 5 12 5C12.2652 5 12.5196 5.10536 12.7071 5.29289C12.8946 5.48043 13 5.73478 13 6V11.641ZM6 17H18C18.2652 17 18.5196 17.1054 18.7071 17.2929C18.8946 17.4804 19 17.7348 19 18C19 18.2652 18.8946 18.5196 18.7071 18.7071C18.5196 18.8946 18.2652 19 18 19H6C5.73478 19 5.48043 18.8946 5.29289 18.7071C5.10536 18.5196 5 18.2652 5 18C5 17.7348 5.10536 17.4804 5.29289 17.2929C5.48043 17.1054 5.73478 17 6 17Z"
                fill={props.color ?? "black"}/>
        </svg>
    </div>
)

export default DownloadIcon
