import React from "react";
import IconProps from "components/common/icons/IconProps";

const ArrowUpIcon: React.FC<IconProps> = (props) => (
    <div className={"svg-container"}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size ?? "24"}
            height={props.size ?? "24"}
            viewBox="0 0 24 24">
            <path
                d="M12.071 10.328L7.12098 15.278C6.93238 15.4602 6.67978 15.561 6.41758 15.5587C6.15538 15.5564 5.90457 15.4512 5.71916 15.2658C5.53375 15.0804 5.42859 14.8296 5.42631 14.5674C5.42403 14.3052 5.52482 14.0526 5.70698 13.864L11.364 8.207C11.5515 8.01953 11.8058 7.91422 12.071 7.91422C12.3361 7.91422 12.5905 8.01953 12.778 8.207L18.435 13.864C18.6171 14.0526 18.7179 14.3052 18.7157 14.5674C18.7134 14.8296 18.6082 15.0804 18.4228 15.2658C18.2374 15.4512 17.9866 15.5564 17.7244 15.5587C17.4622 15.561 17.2096 15.4602 17.021 15.278L12.071 10.328Z"
                fill={props.color ?? "black"}/>
        </svg>
    </div>
)

export default ArrowUpIcon
