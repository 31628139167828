import React, {FC, useEffect, useState} from "react";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import {Box, Breadcrumbs, Paper, Tab, Tabs} from "@mui/material";
import {Body2, Heading3, Heading4, Heading5, Heading6} from "components/common/Typography";
import Button from "components/common/Button";
import colors from "res/colors";
import Status from "components/common/Status";
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator";
import {Link, useParams} from "react-router-dom";
import CrewMemberDetailsCard from "components/crewMember/CrewMemberDetailsCard";
import Offer from "models/Offer";
import DataNotFound from "components/common/DataNotFound";
import Api from "data/remote/Api";
import {handleFieldErrors, locationString, moneyString, toShortDate} from "util/Utilities";
import ErrorResponse from "data/remote/models/ErrorResponse";
import Loading from "components/common/Loading";
import strings from "res/strings";
import RejectionReasonDialog from "components/common/RejectionReasonDialog";
import DisplayField from "components/common/DisplayField";
import MessagingControl from "components/MessagingControl";
import {useAlert} from "contexts/AlertContext";

const OfferDetails: FC = () => {
    const {id, jobId} = useParams()
    const alert = useAlert()

    const [offer, setOffer] = useState<Offer>()
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [value, setValue] = React.useState(0)

    useEffect(() => {
        void async function fetchData() {
            if (id && jobId) {
                await Api.offers.getOffer(id, jobId)
                    .then(o => setOffer(o))
                    .catch(res => handleFieldErrors(res.data as ErrorResponse))
            }

            setLoading(false)
        }()
    }, [id, jobId])

    const toggleOpen = () => {
        setOpen(ps => !ps)
    }

    const acceptOffer = async () => {
        await Api.offers.updateStatus(offer!.id, offer!.jobId, "accept")
            .then(() => {
                alert.enqueue({
                    message: `Offer accepted!`,
                    severity: "success"
                })
                setOffer(ps => ps ? {...ps, status: "accepted"} : undefined)
            })
            .catch(res => handleFieldErrors(res.data as ErrorResponse))
    }

    const rejectOffer = async (reason: string) => {
        await Api.offers.updateStatus(offer!.id, offer!.jobId, "reject", reason)
            .then(() => {
                alert.enqueue({
                    message: `Offer rejected!`,
                    severity: "success"
                })
                setOffer(ps => ps ? {...ps, status: "rejected", rejectionReason: reason} : undefined)
                setOpen(ps => !ps)
            })
            .catch(res => handleFieldErrors(res.data as ErrorResponse))
    }

    const completeJob = async () => {
        await Api.jobs.updateStatus(offer!.job!.id, undefined, "complete")
            .then(() => {
                alert.enqueue({
                    message: `Assignment completed!`,
                    severity: "success"
                })

                setOffer(ps => ps && ({...ps, status: "completed"}))
            })
            .catch(res => handleFieldErrors(res.data as ErrorResponse))
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    if (loading) {
        return (<Loading/>)
    } else if (!offer) {
        return (<DataNotFound/>)
    }

    const actionButtons = offer.status === "pending" ?
        <HorizontalFlexLayout spacing={2}>
            <GridItem xs={6} sm={"auto"}>
                <Button
                    fullWidth
                    variant={"outlined"}
                    color={"secondary"}
                    onClick={toggleOpen}>
                    {strings.reject}
                </Button>
            </GridItem>
            <GridItem xs={6} sm={"auto"}>
                <Button
                    fullWidth
                    onClick={acceptOffer}
                    variant={"contained"}>
                    {strings.approve}
                </Button>
            </GridItem>
        </HorizontalFlexLayout> : undefined

    return (<>
        <HtmlMetaDecorator title={offer.name}/>

        <VerticalFlexLayout
            height={"100%"}
            wrap={"nowrap"}>
            <Box p={2} sx={{backgroundColor: "white"}}>
                <VerticalFlexLayout spacing={2}>
                    <Breadcrumbs
                        separator=">"
                        sx={{fontSize: "small"}}>
                        <Link to={"/contractor"}>
                            <Body2>
                                {strings.myAssignments}
                            </Body2>
                        </Link>
                        <Body2>
                            {strings.assignmentDetails}
                        </Body2>
                    </Breadcrumbs>
                    <HorizontalFlexLayout
                        spacing={2}
                        justify={"flex-end"}>
                        <GridItem sx={{flexGrow: 1}}>
                            <Heading3>{offer.name}</Heading3>
                            <Status value={offer.status}/>
                        </GridItem>
                        {value === 0 && actionButtons &&
                        <GridItem display={{xs: 'none', sm: 'block'}}>
                            {actionButtons}
                        </GridItem>}
                        {offer.status === "active" &&
                        <Button
                            variant={"contained"}
                            onClick={completeJob}>
                            Mark as Completed
                        </Button>}
                    </HorizontalFlexLayout>
                </VerticalFlexLayout>
            </Box>
            <Box bgcolor={"white"} px={2}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label={strings.details} sx={{padding: 2}}/>
                    <Tab label={strings.messagesHistory}/>
                </Tabs>
            </Box>
            {value === 0 &&
            <GridItem sx={{backgroundColor: "#F9F9F9", flexGrow: 1}}>
                <Box p={2}>
                    <VerticalFlexLayout spacing={4}>
                        <HorizontalFlexLayout spacing={4}>
                            <GridItem>
                                <Heading6 color={colors.disabledText}>
                                    {strings.overallBudget}
                                </Heading6>
                                <Heading4>
                                    {moneyString(offer.budget)}
                                </Heading4>
                            </GridItem>
                            <GridItem>
                                <Heading6 color={colors.disabledText}>
                                    {strings.deadline}
                                </Heading6>
                                <Heading4>
                                    {toShortDate(offer.job.deadline)}
                                </Heading4>
                            </GridItem>
                        </HorizontalFlexLayout>
                        <HorizontalFlexLayout spacing={2}>
                            <GridItem xs={12} md={4} lg={3}>
                                <Paper variant={"outlined"}>
                                    <Box p={2}>
                                        <VerticalFlexLayout spacing={2}>
                                            <Heading5>
                                                {strings.projectDetails}
                                            </Heading5>
                                            <DisplayField
                                                size={"h6"}
                                                title={strings.projectType}
                                                content={offer.job.type ?? "-"}/>
                                            <DisplayField
                                                size={"h6"}
                                                title={strings.location}
                                                content={locationString(offer.job.location)}/>
                                        </VerticalFlexLayout>
                                    </Box>
                                </Paper>
                            </GridItem>
                            <GridItem xs={12} md={8} lg={9}>
                                <Paper
                                    variant={"outlined"}
                                    sx={{height: "100%"}}>
                                    <Box p={2}>
                                        <DisplayField
                                            title={strings.notes}
                                            content={offer.notes ?? "-"}/>
                                    </Box>
                                </Paper>
                            </GridItem>
                            <GridItem xs={12}>
                                <Heading6 color={colors.disabledText}>
                                    {strings.crewMembers}
                                </Heading6>
                            </GridItem>
                            {offer.crew.map(c => (
                                <GridItem key={c.id} xs={12} md={6} lg={4}>
                                    <CrewMemberDetailsCard
                                        crewMember={c}/>
                                </GridItem>
                            ))}
                        </HorizontalFlexLayout>
                    </VerticalFlexLayout>
                </Box>
            </GridItem>}
            {value === 1 &&
            <GridItem height={"100%"} zIndex={2}>
                <MessagingControl
                    kind={offer.status === "active" ? "job" : "offer"}
                    companyId={offer.companyId}
                    offerId={offer.id}
                    jobId={offer.jobId}/>
            </GridItem>}
            {value === 0 && actionButtons &&
            <GridItem sx={{
                zIndex: 2,
                display: {xs: 'block', sm: 'none'},
                backgroundColor: "white",
                position: "sticky",
                bottom: 0
            }}>
                <Box p={2}>
                    {actionButtons}
                </Box>
            </GridItem>}
        </VerticalFlexLayout>

        <RejectionReasonDialog
            open={open}
            onReject={rejectOffer}
            onCancel={toggleOpen}/>
    </>)
}

export default OfferDetails
