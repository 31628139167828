import React, {FC, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom"
import {Box, Container} from "@mui/material";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import Button from "components/common/Button";
import {Body1, Body2, Heading1, Heading2, Heading5, Heading6} from "components/common/Typography";
import colors from "res/colors";
import ContactUs from "components/common/ContactUs";
import Footer from "components/common/Footer";
import paths from "res/paths";
import RatioContainer from "components/common/RatioContainer";
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator";

const howTos = [
    {
        image: "/assets/how-to-1.jpg",
        name: "Hire Freelancers",
        text: "Have a brief? We'll vet and connect you with the perfect videographers, sound engineers, photographers, and more.",
        subText: "Starting at $250"
    },
    {
        image: "/assets/how-to-2.jpg",
        name: "Buy Packages",
        text: "Want a hands-off experience? Browse our bundles for all-in-one media support & content creation.",
        subText: "Starting at $550"
    },
    {
        image: "/assets/how-to-3.jpg",
        name: "Hire Equipment",
        text: "Need the best equipment for your shoot? Rent state-of-the-art gear in your local area.",
        subText: "SUPPLIED BY CLIENT"
    }
]

const feedbacks = [
    {
        image: "/assets/sandy-ayoub.jpg",
        name: "Sandy Ayoub",
        text: "Working with TAO was a breeze! Their team made it easy to produce high-quality content for our clients. We highly recommend them for their professionalism and efficiency."
    },
    {
        image: "/assets/omar-sarieddine.jpg",
        name: "Omar Sarieddine",
        text: "TAO Media is a lifesaver! They were always available, even under short notice and during peak demand. We can always count on them to deliver top-notch results."
    }
]

const talents = [
    {
        image: "/assets/charbel-matar.jpg",
        name: "Charbel Matar",
        text: "Charbel is a highly experienced cinematographer with over a decade of work in the production industry. He has a diverse background, having contributed to the creation of various types of content including series, short films, music videos, documentaries, and corporate films."
    },
    {
        image: "/assets/nivine-afiouni.jpg",
        name: "Nivine Afiouni",
        text: "Nivine is a seasoned media coach who works with leading politicians and executives in both the public and private sectors. Her areas of expertise include training in public speaking, media relations, crisis management, digital reputation management, and nation branding. She has a wealth of experience in helping clients to effectively communicate and represent themselves in the public sphere."
    },
    {
        image: "/assets/hamzah-dheyab.jpg",
        name: "Hamzah Dheyab",
        text: "Hamzah is a skilled and professional warzone camera operator. He willingly faces the risks of being caught in crossfire, detained, or kidnapped in order to provide new coverage to regional and international channels in Yemen, Libya, and Ukraine. He has a proven track record of capturing footage in challenging and dangerous locations during his career."
    }
]

const Home: FC = () => {
    const {hash} = useLocation();
    const navigate = useNavigate()

    useEffect(() => {
        const id = hash?.replace('#', '')
        if (id) {
            setTimeout(() => document.getElementById(id)?.scrollIntoView({behavior: "smooth"}), 10)
        } else {
            window.scrollTo(0, 0)
        }
    }, [hash])

    return (<>
        <HtmlMetaDecorator title={"Home"}/>

        <VerticalFlexLayout sx={{backgroundColor: "white"}}>
            <GridItem sx={{position: "relative"}}>
                <video
                    autoPlay loop
                    muted preload="auto"
                    style={{
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0
                    }}>
                    <source
                        type="video/mp4"
                        src={process.env.PUBLIC_URL + "/assets/hero_bg.mp4"}/>
                </video>
                <VerticalFlexLayout>
                    <Box py={24}>
                        <Container maxWidth={"sm"}>
                            <VerticalFlexLayout
                                spacing={6}
                                alignItems={"center"}>
                                <GridItem zIndex={1}>
                                    <Heading1
                                        color={"white"}
                                        align={"center"}>
                                        Source trusted media freelancers in minutes
                                    </Heading1>
                                </GridItem>
                                <GridItem zIndex={1}>
                                    <Body1 color={"white"}>
                                        {"Create inspiring content for your brand faster"}
                                    </Body1>
                                </GridItem>
                                <Button
                                    sx={{borderRadius: 24}}
                                    variant={"contained"}
                                    onClick={() => navigate(paths.signUp)}>
                                    Start a Project
                                </Button>
                            </VerticalFlexLayout>
                        </Container>
                    </Box>
                </VerticalFlexLayout>
            </GridItem>
            <GridItem id={"how-to"}>
                <Box py={8} bgcolor={"#ededed"}>
                    <Container maxWidth={"lg"}>
                        <VerticalFlexLayout
                            spacing={2}
                            alignItems={"center"}>
                            <Heading2 align={"center"}>
                                Content creation made easy
                            </Heading2>
                            <Body1 paddingBottom={2}>
                                {"Whether it’s a creative requirement or a post-production service, or we have the solution for it."}
                            </Body1>
                            <HorizontalFlexLayout
                                spacing={4}
                                justify={"center"}>
                                {howTos.map(t => (
                                    <GridItem key={t.name} xs={12} sm={6} md={4}>
                                        <Box
                                            position={"relative"}
                                            height={"100%"}
                                            bgcolor={"white"}
                                            borderRadius={4}
                                            overflow={"hidden"}
                                            className={"feedback-card"}>
                                            <RatioContainer ratio={16 / 9}>
                                                <img src={process.env.PUBLIC_URL + t.image}
                                                     alt={t.name} style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    objectPosition: "center",
                                                    objectFit: "cover"
                                                }}/>
                                            </RatioContainer>
                                            <Heading5
                                                align={"center"}
                                                padding={2}>
                                                {t.name}
                                            </Heading5>
                                            <Body2
                                                color={colors.copy}
                                                align={"center"}
                                                paddingX={2}
                                                paddingBottom={2}>
                                                {t.text}
                                            </Body2>
                                            <Box
                                                width={"100%"}
                                                position={"absolute"}
                                                top={8}
                                                display={"flex"}
                                                justifyContent={"center"}>
                                                <Heading6
                                                    color={"white"}
                                                    bgcolor={"#00000085"}
                                                    borderRadius={25}
                                                    padding={"4px 8px"}>
                                                    {t.subText}
                                                </Heading6>
                                            </Box>
                                        </Box>
                                    </GridItem>
                                ))}
                            </HorizontalFlexLayout>
                        </VerticalFlexLayout>
                    </Container>
                </Box>
            </GridItem>
            <Box py={8} bgcolor={"#ededed"}>
                <Container maxWidth={"lg"}>
                    <VerticalFlexLayout
                        spacing={2}
                        alignItems={"center"}>
                        <Heading2 align={"center"}>
                            Spotlight on top talent
                        </Heading2>
                        <Body1 paddingBottom={2}>
                            {"Our services reduces the time to search and find the right talent to no time. Try us out."}
                        </Body1>
                        <HorizontalFlexLayout
                            spacing={4}
                            justify={"center"}>
                            {talents.map(t => (
                                <GridItem key={t.name} xs={12} sm={6} md={4}>
                                    <Box
                                        height={"100%"}
                                        bgcolor={"white"}
                                        borderRadius={4}
                                        overflow={"hidden"}
                                        className={"feedback-card"}>
                                        <RatioContainer ratio={16 / 9}>
                                            <img src={process.env.PUBLIC_URL + t.image}
                                                 alt={t.name} style={{
                                                width: "100%",
                                                height: "100%",
                                                objectPosition: "center",
                                                objectFit: "cover"
                                            }}/>
                                        </RatioContainer>
                                        <Heading5
                                            align={"center"}
                                            padding={2}>
                                            {t.name}
                                        </Heading5>
                                        <Body2
                                            color={colors.copy}
                                            align={"center"}
                                            paddingX={2}
                                            paddingBottom={2}>
                                            {t.text}
                                        </Body2>
                                    </Box>
                                </GridItem>
                            ))}
                        </HorizontalFlexLayout>
                    </VerticalFlexLayout>
                </Container>
            </Box>
            <GridItem id={"inspiration"} sx={{display: "flex", width: "100%"}}>
                <Box py={8} width={"100%"}>
                    <Container maxWidth={"lg"}>
                        <VerticalFlexLayout
                            spacing={2}
                            alignItems={"center"}>
                            <Heading2 align={"center"}>
                                Be inspired by our best videos
                            </Heading2>
                            <Body1 paddingBottom={2}>
                                {"Bring your story to life through the power of video. Start creating today."}
                            </Body1>
                            <GridItem width={"100%"}>
                                <HorizontalFlexLayout spacing={2} justify={"center"}>
                                    {["bwU5ZNEmqa8", "o6dYwnPSz6s", "Tckl7kmGShw"].map(i => (
                                        <GridItem key={i} xs={12} sm={6} md={4}>
                                            <RatioContainer ratio={16 / 9}>
                                                <iframe
                                                    style={{width: "100%", height: "100%", borderRadius: 16}}
                                                    src={`https://www.youtube-nocookie.com/embed/${i}?controls=0`}
                                                    title="YouTube video player" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowFullScreen/>
                                            </RatioContainer>
                                        </GridItem>
                                    ))}
                                </HorizontalFlexLayout>
                            </GridItem>
                        </VerticalFlexLayout>
                    </Container>
                </Box>
            </GridItem>
            <Box py={8} bgcolor={"#ededed"}>
                <Container maxWidth={"lg"}>
                    <VerticalFlexLayout
                        spacing={2}
                        alignItems={"center"}>
                        <Heading2 align={"center"}>
                            What our clients say
                        </Heading2>
                        <Body1 paddingBottom={2}>
                            {"Whether it’s a specific creative requirement or a post-production edit service, we have a solution that fits."}
                        </Body1>
                        <HorizontalFlexLayout
                            spacing={4}
                            justify={"center"}>
                            {feedbacks.map(t => (
                                <GridItem key={t.name} xs={12} sm={6} md={4}>
                                    <VerticalFlexLayout
                                        height={"100%"}
                                        bgcolor={"white"}
                                        borderRadius={4}
                                        overflow={"hidden"}
                                        className={"feedback-card"}>
                                        <img src={process.env.PUBLIC_URL + "/assets/quote.png"}
                                             alt={"Quote"}
                                             style={{
                                                 width: "32px",
                                                 padding: "0 8px",
                                                 paddingTop: "4px",
                                                 objectPosition: "center",
                                                 objectFit: "cover"
                                             }}/>
                                        <GridItem grow={1}>
                                            <Body2
                                                color={colors.copy}
                                                align={"center"}
                                                paddingX={2}
                                                paddingBottom={8}>
                                                {t.text}
                                            </Body2>
                                        </GridItem>
                                        <Box
                                            bgcolor={colors.primary}
                                            textAlign={"center"}>
                                            <img src={process.env.PUBLIC_URL + t.image}
                                                 alt={t.name}
                                                 style={{
                                                     width: 96,
                                                     height: 96,
                                                     borderRadius: "50%",
                                                     objectPosition: "center",
                                                     objectFit: "cover",
                                                     marginTop: -48
                                                 }}/>
                                            <Heading5
                                                align={"center"}
                                                paddingX={2}>
                                                {t.name}
                                            </Heading5>
                                            <Body2
                                                color={colors.disabledText}
                                                align={"center"}
                                                paddingX={2}
                                                paddingY={1}>
                                                Client
                                            </Body2>
                                        </Box>
                                    </VerticalFlexLayout>
                                </GridItem>
                            ))}
                        </HorizontalFlexLayout>
                    </VerticalFlexLayout>
                </Container>
            </Box>
            <GridItem
                id={"about"}
                sx={{
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/background-1.png"})`
                }}>
                <Box py={12} sx={{backgroundColor: "#0009"}}>
                    <Container maxWidth={"md"}>
                        <VerticalFlexLayout
                            spacing={4}
                            alignItems={"center"}>
                            <Heading2
                                color={"white"}
                                align={"center"}>
                                Our innovation story
                            </Heading2>
                            <Body1 color={"white"}>
                                {"In October 2020, the pandemic was raging and nearly every sector was disrupted. Travel restrictions meant media crews couldn’t get around and the reduced trading worldwide turned many internal media professionals into freelancers. The UAE and Saudi markets needed quick access to great media talent. And so, Tao Media was born."}
                            </Body1>
                            <Body1 color={"white"}>
                                {"Our mission is to make it easy for brands to find top-tier media professionals and support. Over time, we want to harness machine learning and AI to automate what our internal team of media gurus are doing now - vetting every single freelancer and brief to ensure a perfect fit. Our direct experience with content requests across all businesses from SMEs to production houses means that we know how to cut out all the back and forth. The result is a shorter procurement process, high-quality content output, and great client satisfaction."}
                            </Body1>
                        </VerticalFlexLayout>
                    </Container>
                </Box>
            </GridItem>
            <Container maxWidth={"xl"}>
                <Box py={8}>
                    <VerticalFlexLayout
                        spacing={6}
                        alignItems={"center"}>
                        <Heading2 align={"center"}>
                            Why choose us?
                        </Heading2>
                        <HorizontalFlexLayout
                            spacing={4}
                            alignItems={"center"}>
                            {[
                                {img: "/assets/choose-us-1.png", content: "Manage projects and organize paperwork"},
                                {
                                    img: "/assets/choose-us-2.png",
                                    content: "Effortlessly pay your freelancers in 190+ countries"
                                },
                                {
                                    img: "/assets/choose-us-3.png",
                                    content: "Track and analyze your team's spend and productivity"
                                }
                            ].map((i =>
                                    <GridItem key={i.img} xs={12} sm={6} md={4} grow={1}>
                                        <HorizontalFlexLayout
                                            spacing={2}
                                            wrap={"nowrap"}
                                            alignItems={"center"}>
                                            <img src={process.env.PUBLIC_URL + i.img}
                                                 alt="choose tao media" width={48} height={48}/>
                                            <Body1>{i.content}</Body1>
                                        </HorizontalFlexLayout>
                                    </GridItem>
                            ))}
                        </HorizontalFlexLayout>
                    </VerticalFlexLayout>
                </Box>
            </Container>
            <GridItem id={"contact-us"}>
                <Box py={8} sx={{backgroundColor: colors.primary}}>
                    <Container maxWidth={"xl"}>
                        <VerticalFlexLayout
                            spacing={6}
                            alignItems={"center"}>
                            <Heading2 align={"center"}>
                                Contact Us
                            </Heading2>
                            <ContactUs/>
                        </VerticalFlexLayout>
                    </Container>
                </Box>
            </GridItem>
            <Footer/>
        </VerticalFlexLayout>
    </>)
}

export default Home
