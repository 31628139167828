import React from "react";
import IconProps from "components/common/icons/IconProps";

const CommentIcon: React.FC<IconProps> = (props) => (
    <div className={"svg-container"}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size ?? "24"}
            height={props.size ?? "24"}
            viewBox="0 0 24 24">
            <path
                d="M5 2.565H19C19.7956 2.565 20.5587 2.88107 21.1213 3.44368C21.6839 4.00629 22 4.76935 22 5.565V13.565C22 14.3606 21.6839 15.1237 21.1213 15.6863C20.5587 16.2489 19.7956 16.565 19 16.565H12.042L5.598 21.373C5.44922 21.484 5.27248 21.5514 5.08756 21.5677C4.90265 21.5839 4.71686 21.5484 4.551 21.465C4.38513 21.3817 4.24574 21.2538 4.14843 21.0957C4.05111 20.9377 3.99972 20.7556 4 20.57V16.564C3.46957 16.564 2.96086 16.3533 2.58579 15.9782C2.21071 15.6031 2 15.0944 2 14.564V5.564C2 4.76835 2.31607 4.00529 2.87868 3.44268C3.44129 2.88007 4.20435 2.564 5 2.564V2.565Z"
                fill={props.color ?? "black"}/>
        </svg>
    </div>
)

export default CommentIcon
