import React, {FC} from "react"
import {Box, Container, Divider, Tooltip} from "@mui/material"
import {HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout"
import {Body1, Body2, Heading1, Heading2, Heading3, Heading4, Heading5, Heading6} from "components/common/Typography"
import {Tags} from "components/common/Tags"
import colors from "res/colors";
import InputField from "components/common/InputField";
import ArrowLeftIcon from "components/common/icons/ArrowLeftIcon";
import CloseIcon from "components/common/icons/CloseIcon";
import MenuIcon from "components/common/icons/MenuIcon";
import SearchIcon from "components/common/icons/SearchIcon";
import StarIcon from "components/common/icons/StarIcon";
import AddPersonIcon from "components/common/icons/AddPersonIcon";
import CompanyIcon from "components/common/icons/CompanyIcon";
import EditIcon from "components/common/icons/EditIcon";
import PersonIcon from "components/common/icons/PersonIcon";
import PlusIcon from "components/common/icons/PlusIcon";
import TaskIcon from "components/common/icons/TaskIcon";
import TrashIcon from "components/common/icons/TrashIcon";
import Button from "components/common/Button";
import IconButton from "components/common/IconButton";
import SendIcon from "components/common/icons/SendIcon";
import MoreIcon from "components/common/icons/MoreIcon";
import TwitterIcon from "components/common/icons/TwitterIcon";
import FacebookIcon from "components/common/icons/FacebookIcon";
import InstagramIcon from "components/common/icons/InstagramIcon";
import LinkedInIcon from "components/common/icons/LinkedInIcon";
import CommentIcon from "components/common/icons/CommentIcon";
import DownloadIcon from "components/common/icons/DownloadIcon";
import ArrowDownIcon from "components/common/icons/ArrowDownIcon";
import ArrowUpIcon from "components/common/icons/ArrowUpIcon";
import {useAlert} from "contexts/AlertContext";
import SettingsIcon from "components/common/icons/SettingsIcon";

const icons = [
    <AddPersonIcon/>,
    <ArrowUpIcon/>,
    <ArrowDownIcon/>,
    <ArrowLeftIcon/>,
    <CloseIcon/>,
    <CommentIcon/>,
    <CompanyIcon/>,
    <DownloadIcon/>,
    <EditIcon/>,
    <MenuIcon/>,
    <MoreIcon/>,
    <PersonIcon/>,
    <PlusIcon/>,
    <SearchIcon/>,
    <StarIcon/>,
    <TaskIcon/>,
    <TrashIcon/>,
    <SendIcon/>,
    <TwitterIcon/>,
    <FacebookIcon/>,
    <InstagramIcon/>,
    <LinkedInIcon/>,
    <SettingsIcon/>
]

const UiKit: FC = () => {
    const alert = useAlert()
    const _colors = colors as Record<string, string>

    return (
        <Container style={{padding: 32}}>
            <VerticalFlexLayout spacing={4}>
                <Heading1>Heading 1: Diodrum - Welcome to TAO Media</Heading1>
                <Heading2>Heading 2: Diodrum - Welcome to TAO Media</Heading2>
                <Heading3>Heading 3: Diodrum - Welcome to TAO Media</Heading3>
                <Heading4>Heading 4: Diodrum - Welcome to TAO Media</Heading4>
                <Heading5>Heading 5: Diodrum - Welcome to TAO Media</Heading5>
                <Heading6>Heading 6: Heebo - Welcome to TAO Media</Heading6>
                <Body1>Body 1: Heebo - Welcome to TAO Media</Body1>
                <Body2>Body 2: Heebo - Welcome to TAO Media</Body2>
                <Divider/>
                <Tags label={"Tags"} items={["Red", "Yellow", "Blue", "White"]}/>
                <Divider/>
                <HorizontalFlexLayout spacing={1}>
                    {Object.keys(_colors).map((k, i) => (
                        <Tooltip
                            key={i}
                            title={`${k} (${_colors[k]})`}
                            onClick={async () => {
                                await navigator.clipboard.writeText(_colors[k])
                                alert.enqueue({
                                    message: "Color code copied to clipboard.",
                                    severity: "success"
                                })
                            }}>
                            <Box p={3} bgcolor={_colors[k]}/>
                        </Tooltip>
                    ))}
                </HorizontalFlexLayout>
                <Divider/>
                <HorizontalFlexLayout spacing={2}>
                    <InputField label={"Text Field"}/>
                    <InputField label={"Text Field"} value={"TAO Media"} disabled/>
                    <InputField label={"Text Field"} value={"TAO Media"} focused/>
                    <InputField label={"Text Field"} value={"TAO Media"} fieldError={{message: "Error Message"}}/>
                    <InputField label={"Text Field"} value={"TAO Media"} focused
                                fieldError={{message: "Error Message"}}/>
                    <InputField label={"Multiline"} multiline value={"TAO Media\n\nTAO Media"}/>
                </HorizontalFlexLayout>
                <Divider/>
                <HorizontalFlexLayout spacing={2}>
                    {icons.map((icon, index) => (
                        <Tooltip
                            key={index}
                            title={`${icon.type.name}`}
                            onClick={async () => {
                                await navigator.clipboard.writeText(`${icon.type.name}`)
                                alert.enqueue({
                                    message: "Component name copied to clipboard.",
                                    severity: "success"
                                })
                            }}>
                            <Box>{icon}</Box>
                        </Tooltip>
                    ))}
                </HorizontalFlexLayout>
                <Divider/>
                <HorizontalFlexLayout spacing={2}>
                    <Button color={"secondary"}>
                        Text Button
                    </Button>
                    <Button variant={"outlined"} color={"error"}>
                        Outlined Button
                    </Button>
                    <Button variant={"outlined"} color={"secondary"}>
                        Outlined Button
                    </Button>
                    <Button variant={"contained"} color={"secondary"}>
                        Contained Button
                    </Button>
                    <Button variant={"contained"}>
                        Contained Button
                    </Button>
                    <Button variant={"contained"} isBusy>
                        Contained Button
                    </Button>
                    <Button variant={"outlined"} color={"secondary"} isBusy>
                        Outlined Button
                    </Button>
                    <IconButton variant={"outlined"} color={"error"}>
                        <TrashIcon color={colors.error}/>
                    </IconButton>
                    <IconButton variant={"outlined"} color={"secondary"}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton variant={"contained"}>
                        <AddPersonIcon/>
                    </IconButton>
                </HorizontalFlexLayout>
            </VerticalFlexLayout>
        </Container>
    )
}

export default UiKit
