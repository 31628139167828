import React, {FC} from "react"
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator"
import DataNotFound from "components/common/DataNotFound";

const NotFound: FC = () => {
    return (<>
        <HtmlMetaDecorator title="404"/>
        <DataNotFound/>
    </>)
}

export default NotFound
