import React, {PropsWithChildren} from "react"
import {Helmet} from "react-helmet"
import strings from "res/strings"

interface Props {
    title?: string
    description?: string
}

const HtmlMetaDecorator = (props: PropsWithChildren<Props>) => {
    let title = (props.title ? `${props.title} - ` : '') + strings.appName
    if (title.length > 55) {
        title = title.slice(0, 52) + "..."
    }

    let description = props.description || strings.appDescription
    if (description.length > 200) {
        description = description.slice(0, 197) + "..."
    }

    return (
        <Helmet>
            <title>{title}</title>
            <meta key="description" name="description" content={description}/>
            {props.children}
        </Helmet>
    )
}

export default HtmlMetaDecorator
