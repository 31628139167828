import BaseApi from "util/api/BaseApi";
import Contractor from "models/Contractor";
import List from "models/List";
import CompanyUser from "models/CompanyUser";

class UsersApi extends BaseApi {
    async getContractorProfile(id: string): Promise<Contractor | undefined> {
        return await this.get<Contractor>(`users/${id}`)
    }

    async getContractors(search: string = ""): Promise<List<Contractor> | undefined> {
        const url = this.buildUrl("users", {search})
        return await this.get<List<Contractor>>(url)
    }

    async getCompanies(): Promise<List<CompanyUser> | undefined> {
        return await this.get<List<CompanyUser>>("users/company")
    }

    async getAgents(): Promise<List<CompanyUser> | undefined> {
        return await this.get<List<CompanyUser>>("users/agents")
    }

    async updateContractorProfile(contractor: Contractor): Promise<Contractor | undefined> {
        return await this.put<Contractor>(`users/${contractor.id}`, contractor)
    }
}

export default UsersApi
