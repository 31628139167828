import BaseApi from "util/api/BaseApi";
import Job from "models/Job";
import List from "models/List";

class JobsApi extends BaseApi {
    async getJobsAsync(status: string, companyId: string = ""): Promise<List<Job> | undefined> {
        const url = this.buildUrl(`jobs`, {
            companyId,
            status: status === "all" ? undefined : status
        })
        return await this.get<List<Job>>(url)
    }

    async getJobAsync(id: string, companyId: string = ""): Promise<Job | undefined> {
        const url = this.buildUrl(`jobs/${id}`, {companyId})
        return await this.get<Job>(url)
    }

    async createJobAsync(job: Job): Promise<Job | undefined> {
        return await this.post<Job>("jobs", job)
    }

    async updateJobAsync(job: Job): Promise<Job | undefined> {
        return await this.put<Job>(`jobs/${job.id}`, job)
    }

    async deleteDocumentAsync(
        jobId: string,
        documentId: string,
        companyId?: string
    ): Promise<Job | undefined> {
        const url = this.buildUrl(`jobs/${jobId}/documents/${documentId}`, {companyId})
        return await this.delete<Job>(url)
    }

    async requestProposalAsync(id: string, companyId: string = ""): Promise<Job | undefined> {
        const url = this.buildUrl(`jobs/${id}/proposal`, {companyId})
        return await this.post<Job>(url, {companyId})
    }

    async updateStatus(id: string, companyId: string | undefined, status: "paid" | "complete"): Promise<any> {
        return await this.post<any>(`jobs/${id}/${status}`, {companyId})
    }
}

export default JobsApi
