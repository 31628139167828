import React, {createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useState} from "react";
import Api from "data/remote/Api";
import {useAuth} from "contexts/AuthContext";
import {handleFieldErrors} from "util/Utilities";
import ErrorResponse from "data/remote/models/ErrorResponse";

// TODO: Connection lasts for 10+ minutes, so need to handle refreshing the
//  connection (MessagingContext.tsx:48 WebSocket is already in CLOSING or CLOSED state.).
// TODO: Show "Agent" for messages without SenderId.
// TODO:

type Action = "sendMessage" | "messageSent" | "getMessages"

interface SendInput {
    action: Action
    topicId: string
    messageId: string
    content?: string
}

interface MessagingContext {
    addCallback: (id: string, callback: (e: MessageEvent) => void) => void
    removeCallback: (id: string) => void
    send: (input: SendInput) => void
    close: () => void
}

const messagingContext = createContext<MessagingContext>({
    addCallback: () => {
    },
    removeCallback: () => {
    },
    send: async () => {
    },
    close: () => {
    }
})

export const useMessaging = () => useContext<MessagingContext>(messagingContext)

interface CallbackState {
    id: string
    callback: (e: MessageEvent) => void
}

let callbacks: CallbackState[] = []

// TODO: This might not be the best way to implement the Messaging.
const MessagingProvider: FC<PropsWithChildren> = (props) => {
    const {identity} = useAuth()

    const [ws, setWs] = useState<WebSocket>()

    // TODO: MessagingContext.tsx:150 Uncaught (in promise)
    //  DOMException: Failed to execute 'send' on 'WebSocket': Still in CONNECTING state.
    //  show loading and onopen set it as false

    // TODO: If user is not in a messaging page or a messages is not related
    //  to the current thread, then show a notification in the nav bar.

    const onMessage = useCallback((e: MessageEvent) => {
        console.log("Messaging Response")
        console.log(e)
        if (e) {
            const data = JSON.parse(e.data)
            if (data.message === "Too Many Requests") {
                window.location.replace(process.env.PUBLIC_URL + "/error")
            }

            callbacks.forEach(c => {
                // TODO: not good!
                if (data?.messageId?.startsWith(c.id) || data?.data?.topicId === c.id) {
                    c.callback(e)
                }

                if (data?.action === "messageSent" && c.id === "NewMessage") {
                    c.callback(e)
                }
            })

            // TODO: if no callback for a message it should go to a pool?!?!
        }
    }, [])

    const connect = useCallback(async () => {
        if (identity.username) {
            await Api.messaging
                .getConnectAsync()
                .then(c => {
                    if (c?.url) {
                        const socket = new WebSocket(c!.url)
                        socket.onmessage = onMessage
                        setWs(socket)
                    }
                })
                .catch(res => handleFieldErrors(res.data as ErrorResponse))
        } else {
            setWs(undefined)
            callbacks = []
        }
    }, [identity, onMessage])

    const close = useCallback(() => {
        setWs(ps => {
            ps?.close()
            return undefined
        })

        callbacks = []
    }, [])

    useEffect(() => {
        void async function fetchData() {
            await connect()
        }()

        return () => close()

        // TODO: clear current states on user change, and reconnect with new
        //  (no reconnect, let the user do an action so the topic id is renewed)
    }, [connect, close])

    const send = async (input: SendInput) => {
        if (!ws || [WebSocket.CLOSED, WebSocket.CLOSING].includes(ws.readyState)) {
            await connect()
        }

        const {action, messageId, ...data} = input
        ws?.send(JSON.stringify({action, messageId, data}))
        console.log("Messaging Request")
        console.log(input)
    }

    const addCallback = (id: string, callback: (e: MessageEvent) => void) => {
        removeCallback(id)
        callbacks.push({id, callback})
    }

    const removeCallback = (id: string) => {
        callbacks = callbacks.filter(c => c.id !== id)
    }

    return (
        <messagingContext.Provider value={{
            send, close, addCallback, removeCallback
        }}>
            {props.children}
        </messagingContext.Provider>
    )
}

export default MessagingProvider
