import React, {FC} from "react"
import {Box} from "@mui/material"
import {HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout"
import colors from "res/colors"
import {Body2} from "components/common/Typography";

export type PasswordValidationStatus = 'invalid' | 'mismatch' | 'valid'

interface Props {
    password?: string
    confirm?: string
    onChange: (status: PasswordValidationStatus) => void
}

const PasswordValidation: FC<Props> = (props: Props) => {
    const password = props.password?.trim() ?? ""
    const isValidLength = password.length > 7
    const isValidDigit = /(?=.*[0-9])(?=.*[/^$*.[\]{}()?\-"!@#%&\\,><':;|_~`+= ]).*/.test(password)
    const isValidCase = /(?=.*[a-z])(?=.*[A-Z]).*/.test(password)

    let status: PasswordValidationStatus = "valid"
    if (!isValidCase || !isValidLength || !isValidDigit) {
        status = "invalid"
    } else if (props.password !== props.confirm) {
        status = "mismatch"
    }
    props.onChange(status)

    return (
        <VerticalFlexLayout spacing={2}>
            <Body2>
                In order to create a highly secure password follow these requirements:
            </Body2>
            <Box
                p={2}
                bgcolor={colors.pearlWhite}
                borderRadius={1}>
                <VerticalFlexLayout spacing={2}>
                    <HorizontalFlexLayout
                        spacing={2}
                        wrap={"nowrap"}
                        alignItems={"center"}>
                        <Box
                            width={10}
                            height={10}
                            borderRadius={"50%"}
                            bgcolor={isValidLength ? "forestgreen" : "#E0E0E0"}/>
                        <Body2
                            color={isValidLength ? colors.text : colors.disabledText}>
                            Password must be at least
                            <span style={{fontWeight: 500, color: "inherit"}}>
                            {" 8 characters long"}
                        </span>
                        </Body2>
                    </HorizontalFlexLayout>
                    <HorizontalFlexLayout
                        spacing={2}
                        wrap={"nowrap"}
                        alignItems={"center"}>
                        <Box
                            width={10}
                            height={10}
                            borderRadius={"50%"}
                            bgcolor={isValidDigit ? "forestgreen" : "#E0E0E0"}/>
                        <Body2
                            color={isValidDigit ? colors.text : colors.disabledText}>
                            Password must contain at least
                            <span style={{fontWeight: 500, color: "inherit"}}> 1 number</span> and
                            <span style={{fontWeight: 500, color: "inherit"}}> 1 special characters</span>
                        </Body2>
                    </HorizontalFlexLayout>
                    <HorizontalFlexLayout
                        spacing={2}
                        wrap={"nowrap"}
                        alignItems={"center"}>
                        <Box
                            width={10}
                            height={10}
                            borderRadius={"50%"}
                            bgcolor={isValidCase ? "forestgreen" : "#E0E0E0"}/>
                        <Body2
                            color={isValidCase ? colors.text : colors.disabledText}>
                            Password must contain both
                            <span style={{fontWeight: 500, color: "inherit"}}> uppercase</span> and
                            <span style={{fontWeight: 500, color: "inherit"}}> lowercase characters</span>
                        </Body2>
                    </HorizontalFlexLayout>
                </VerticalFlexLayout>
            </Box>
            <Body2>
                By signing up, you are agreeing to TAO Media's Terms and Conditions.
            </Body2>
        </VerticalFlexLayout>
    )
}

export default PasswordValidation
