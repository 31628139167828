import React from "react";
import IconProps from "components/common/icons/IconProps";

const ArrowLeftIcon: React.FC<IconProps> = (props) => (
    <div className={"svg-container"}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size ?? "24"}
            height={props.size ?? "24"}
            viewBox="0 0 24 24">
            <path
                d="M10.4138 11.6447L15.3638 16.5947C15.4593 16.687 15.5355 16.7973 15.5879 16.9193C15.6403 17.0413 15.6679 17.1725 15.669 17.3053C15.6702 17.4381 15.6449 17.5698 15.5946 17.6927C15.5443 17.8156 15.4701 17.9272 15.3762 18.0211C15.2823 18.115 15.1706 18.1893 15.0477 18.2395C14.9248 18.2898 14.7932 18.3151 14.6604 18.314C14.5276 18.3128 14.3964 18.2852 14.2744 18.2328C14.1524 18.1804 14.042 18.1042 13.9498 18.0087L8.29279 12.3517C8.10532 12.1642 8 11.9099 8 11.6447C8 11.3795 8.10532 11.1252 8.29279 10.9377L13.9498 5.28071C14.1384 5.09855 14.391 4.99776 14.6532 5.00004C14.9154 5.00232 15.1662 5.10749 15.3516 5.29289C15.537 5.4783 15.6422 5.72911 15.6445 5.99131C15.6467 6.25351 15.5459 6.50611 15.3638 6.69471L10.4138 11.6447Z"
                fill={props.color ?? "black"}/>
        </svg>
    </div>
)

export default ArrowLeftIcon
