import BaseApi from "util/api/BaseApi";
import MessagingTopic from "models/MessagingTopic";
import TopicType from "models/types/TopicType";
import List from "models/List";

interface MessagingConnection {
    url: string
}

interface GetTopicInput {
    kind: TopicType
    userId?: string
    jobId?: string
    companyId?: string
    offerId?: string
    proposalId?: string
    contractorId?: string
}

class MessagingApi extends BaseApi {
    async getConnectAsync(): Promise<MessagingConnection | undefined> {
        return await this.get<MessagingConnection>("ws")
    }

    async getConnection(): Promise<MessagingConnection | undefined> {
        return await this.get<MessagingConnection>("ws")
    }

    async getTopic(input: GetTopicInput): Promise<MessagingTopic | undefined> {
        const url = this.buildUrl(`messaging/topics/${input.kind}`,
            {...input, topic: undefined})
        return await this.get<MessagingTopic>(url)
    }

    async getTopics(types: TopicType[]): Promise<List<MessagingTopic> | undefined> {
        const url = `messaging/topics?${types.map(t => `types=${t}&`).join("")}`
        return await this.get<List<MessagingTopic>>(url)
    }
}

export default MessagingApi
