import React, {createContext, FC, PropsWithChildren, useContext, useState} from "react";
import {Box, Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme} from "@mui/material";
import {GridItem, HorizontalFlexLayout} from "components/common/FlexLayout";
import IconButton from "components/common/IconButton";
import CloseIcon from "components/common/icons/CloseIcon";
import {useAuth} from "contexts/AuthContext";
import strings from "res/strings";
import MessagingControl from "components/MessagingControl";
import CommentIcon from "components/common/icons/CommentIcon";
import colors from "res/colors";

interface State {
    userId?: string
    companyId?: string
    open: boolean
}

interface LiveChatContext {
    start: (userId?: string, companyId?: string) => void
}

const liveChatContext = createContext<LiveChatContext>({
    start: () => {
    }
})

export const useLiveChat = () => useContext<LiveChatContext>(liveChatContext)

const LiveChat: FC<PropsWithChildren> = (props) => {
    const {identity} = useAuth()
    const theme = useTheme()
    const isFullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    // TODO: A single state is used here like this to avoid rendering this component
    //  twice, once for setting the UserId and another to set the Open.
    const [state, setState] = useState<State>({open: false})

    const start = (userId?: string, companyId?: string) =>
        setState({userId, companyId, open: true})

    return (
        <liveChatContext.Provider value={{start}}>
            {props.children}
            {(identity.roles.includes("company") || identity.roles.includes("contractor")) &&
            <Box onClick={() => start()} p={2} sx={{
                zIndex: 1,
                backgroundColor: colors.primary,
                position: 'fixed',
                borderRadius: "50%",
                cursor: "pointer",
                bottom: 16,
                right: 16
            }}>
                <CommentIcon/>
            </Box>}
            {Boolean(identity.username) &&
            <Dialog
                open={state.open}
                fullWidth
                fullScreen={isFullScreen}
                maxWidth={"sm"}
                scroll={"paper"}
                PaperProps={{
                    sx: {height: "100%"}
                }}>
                <DialogTitle sx={{padding: 1}}>
                    <HorizontalFlexLayout
                        spacing={1}
                        wrap={"nowrap"}
                        alignItems={"center"}>
                        <GridItem grow={1}>
                            <Box px={1}>
                                {strings.liveChat}
                            </Box>
                        </GridItem>
                        <IconButton
                            onClick={() => setState(ps => ({...ps, open: false}))}>
                            <CloseIcon/>
                        </IconButton>
                    </HorizontalFlexLayout>
                </DialogTitle>
                <DialogContent sx={{padding: 0}}>
                    <MessagingControl
                        kind={"user"}
                        userId={state.userId}
                        companyId={state.companyId}/>
                </DialogContent>
            </Dialog>}
        </liveChatContext.Provider>
    )
}

export default LiveChat
