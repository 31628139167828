const colors = {
    primary: "#F9D100",
    secondary: "#101820",
    error: "#D00000",
    success: "#09745B",
    info: "#106AF1",
    pearlWhite: "#F9F9F9",
    text: "#1C1C1C",
    copy: "#303030",
    disabledText: "#8E918F"
}

export default colors
