import React, {CSSProperties, ReactElement} from "react";
import {Grid, GridDirection, GridProps, GridSize, GridSpacing, GridWrap} from "@mui/material";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

interface Props extends GridProps {
    children: React.ReactNode
    alignContent?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around'
    alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline'
    justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly'
    direction?: GridDirection
    spacing?: GridSpacing
    wrap?: GridWrap

    container?: boolean
    item?: boolean
    zeroMinWidth?: boolean

    className?: string
    style?: CSSProperties
    sx?: SxProps<Theme>

    lg?: boolean | GridSize
    md?: boolean | GridSize
    sm?: boolean | GridSize
    xl?: boolean | GridSize
    xs?: boolean | GridSize

    grow?: number
}

const FlexLayout: React.FC<Props> = (props) => (
    <Grid
        justifyContent={"flex-start"}
        alignItems={"stretch"}
        {...props}
        container>
        {renderChildren(props.children as ReactElement)}
    </Grid>
)

const VerticalFlexLayout: React.FC<Props> = (props) => (
    <Grid
        {...props}
        container
        direction="column"
        justifyContent={props.justify ?? "flex-start"}
        alignItems={props.alignItems ?? "stretch"}>
        {renderChildren(props.children as ReactElement)}
    </Grid>
)

const HorizontalFlexLayout: React.FC<Props> = (props) => (
    <Grid
        {...props}
        container
        direction="row"
        justifyContent={props.justify ?? "flex-start"}
        alignItems={props.alignItems ?? "stretch"}>
        {renderChildren(props.children as ReactElement)}
    </Grid>
)

const GridItem: React.FC<Props> = (props) => (
    <Grid item sx={{flexGrow: props.grow}} {...props}>
        {props.children}
    </Grid>
)

function renderChildren(children?: ReactElement) {
    return React.Children.map(children, (child) => {
        if (!child || child.type === Grid || child.type === GridItem) {
            return (child);
        } else {
            return (
                <Grid item>
                    {child}
                </Grid>
            );
        }
    })
}

export {
    FlexLayout,
    GridItem,
    VerticalFlexLayout,
    HorizontalFlexLayout,
}
