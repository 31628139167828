import React, {FC} from "react"
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator"
import {Box} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Job from "models/Job";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import {Heading3} from "components/common/Typography";
import strings from "res/strings";
import JobsTable from "components/job/JobsTable";

const Jobs: FC = () => {
    const navigate = useNavigate()

    const onClick = (job: Job) => {
        navigate(`/console/assignments/${job.companyId}/${job.id}`)
    }

    return (<>
        <HtmlMetaDecorator title={strings.assignments}/>

        <VerticalFlexLayout
            height={"100%"}
            wrap={"nowrap"}>
            <Box p={2} sx={{backgroundColor: "white"}}>
                <HorizontalFlexLayout
                    spacing={2}
                    alignItems={"center"}
                    justify={"flex-end"}>
                    <GridItem grow={2}>
                        <Heading3>
                            {strings.assignments}
                        </Heading3>
                    </GridItem>
                </HorizontalFlexLayout>
            </Box>
            <GridItem grow={1}>
                <JobsTable
                    isAdmin
                    onClick={onClick}/>
            </GridItem>
        </VerticalFlexLayout>
    </>)
}

export default Jobs
