import BaseApi from "util/api/BaseApi";
import List from "models/List";
import Proposal from "models/Proposal";

class ProposalsApi extends BaseApi {
    async getProposalAsync(id: string, jobId: string = "", companyId: string = ""):
        Promise<Proposal | undefined> {
        const url = this.buildUrl(`proposals/${id}`, {jobId, companyId})
        return await this.get<Proposal>(url)
    }

    async getJobProposalsAsync(jobId: string = "", companyId: string = ""):
        Promise<List<Proposal> | undefined> {
        const url = this.buildUrl("proposals", {jobId, companyId})
        return await this.get<List<Proposal>>(url)
    }

    async createProposalAsync(proposal: Proposal): Promise<Proposal | undefined> {
        return await this.post<Proposal>(`proposals`, proposal)
    }

    async updateProposalAsync(proposal: Proposal): Promise<Proposal | undefined> {
        proposal.jobId = proposal.job.id
        proposal.companyId = proposal.job.companyId
        return await this.put<Proposal>(`proposals/${proposal.id}`, proposal)
    }

    async updateStatus(id: string, jobId: string, companyId: string, status: "submit" | "accept" | "reject", reason: string = ""):
        Promise<any> {
        return await this.post<any>(`proposals/${id}/${status}`, {
            jobId, companyId, reason
        })
    }
}

export default ProposalsApi
