import React, {FC, useCallback, useEffect, useRef, useState} from "react";
import {useMessaging} from "contexts/MessagingContext";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import {Box, Container, Divider} from "@mui/material";
import colors from "res/colors";
import {Body2} from "components/common/Typography";
import InputField from "components/common/InputField";
import IconButton from "components/common/IconButton";
import SendIcon from "components/common/icons/SendIcon";
import Api from "data/remote/Api";
import {getUuid, handleFieldErrors} from "util/Utilities";
import ErrorResponse from "data/remote/models/ErrorResponse";
import {useAuth} from "contexts/AuthContext";
import MessagingTopic from "models/MessagingTopic";
import TopicType from "models/types/TopicType";
import Message from "models/Message";

const MessagingControl: FC<{
    kind: TopicType
    userId?: string
    jobId?: string
    companyId?: string
    offerId?: string
    proposalId?: string
    contractorId?: string
    topic?: MessagingTopic
}> = (props) => {
    const {identity} = useAuth()
    const messaging = useMessaging()

    const end = useRef<HTMLDivElement>(null)

    const [messages, setMessages] = useState<Message[]>([])
    const [message, setMessage] = useState<string>()
    const [topic, setTopic] = useState<MessagingTopic>()

    const onMessage = useCallback((e: MessageEvent) => {
        if (e) {
            console.log(e)
            // TODO: use this for model, better to understand later.
            const data = JSON.parse(e.data)
            console.log(data)

            try {
                // if status === ok or error
                // if duplicate message id try regenrate once and send again.

                if (data.action === "getMessages") {
                    if (data.data?.messages?.length) {
                        setMessages(data.data.messages.sort((a: Message, b: Message) => a.created.localeCompare(b.created)))
                    }
                } else if (data.action === "messageSent") {
                    // maybe useEffect is no longer needed?!
                    setMessages(ps => ps.find(m => m.id === data.data.id) ? ps : [...ps, data.data])
                } else {

                }
                setTimeout(() => end.current?.scrollIntoView({behavior: "smooth"}), 100)
            } catch (ex) {
                console.log(ex)
            }
        }
    }, [])

    const loadMessages = useCallback(async () => {
        setMessages([])

        const t = props.topic ?? await Api.messaging
            .getTopic(props)
            .then(t => {
                setTopic(t)
                return t
            })
            .catch(res => {
                handleFieldErrors(res.data as ErrorResponse)
                return undefined
            })

        if (t) {
            messaging.addCallback(t.id, onMessage)
            messaging.send({
                action: "getMessages",
                messageId: `${t.id}#${getUuid()}`,
                topicId: t.id
            })
        }

        setTopic(t)
    }, [props, messaging, onMessage])

    useEffect(() => {
        void async function fetchData() {
            await loadMessages()
        }()
    }, [loadMessages])

    async function send() {
        if (message?.trim()) {
            messaging.send({
                action: "sendMessage",
                messageId: getUuid(),
                topicId: topic!.id,
                content: message?.trim()
            })

            setMessage("")
        }
    }

    const getName = (id: string) => {
        return id ? topic?.users.find(u => u.id === id)?.name ?? "" : "TAO Media Agent"
    }

    return (
        <VerticalFlexLayout
            wrap={"nowrap"}
            sx={{
                height: "100%",
                backgroundSize: "160px",
                backgroundImage: "url(chat-bg.jpg)"
            }}>
            <Divider/>
            <GridItem sx={{flexGrow: 1, height: 250, overflowY: "auto"}}>
                <Container maxWidth={"lg"}>
                    <Box py={2}>
                        <VerticalFlexLayout spacing={2}>
                            {messages.map(m => (
                                <GridItem key={m.id} sx={{
                                    width: "100%",
                                    direction: m.sender === identity.username ? "rtl" : "ltr"
                                }}>
                                    <Box p={1} pb={0} sx={{
                                        backgroundColor: m.sender === identity.username ? colors.primary : "#eeeeee",
                                        borderRadius: 2,
                                        maxWidth: "65%"
                                    }}>
                                        {m.sender !== identity.username &&
                                        <Body2
                                            fontSize={12}
                                            fontWeight={600}
                                            color={"#456e4c"}
                                            whiteSpace={"pre-wrap"}
                                            sx={{direction: "ltr"}}>
                                            {getName(m.sender)}
                                        </Body2>}
                                        <Body2
                                            whiteSpace={"pre-wrap"}
                                            sx={{direction: "ltr"}}>
                                            {m.content}
                                        </Body2>
                                        <Body2
                                            color={"#6d6d6d"}
                                            fontSize={12}
                                            align={"right"}
                                            sx={{direction: "ltr"}}>
                                            {new Date(m.created).toLocaleString()}
                                        </Body2>
                                    </Box>
                                </GridItem>
                            ))}
                            <div ref={end}/>
                        </VerticalFlexLayout>
                    </Box>
                </Container>
            </GridItem>
            <Divider/>
            <Box p={2} bgcolor={"white"}>
                <HorizontalFlexLayout
                    spacing={2}
                    wrap={"nowrap"}>
                    <GridItem sx={{flexGrow: 1}}>
                        <InputField
                            disabled={!topic}
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                            autoFocus
                            placeholder={"Type a message"}
                            onKeyDown={async e => {
                                if (e.key === "Enter") {
                                    await send()
                                }
                            }}/>
                    </GridItem>
                    <IconButton
                        disabled={!Boolean(message?.trim())}
                        variant={"contained"}
                        onClick={send}>
                        <SendIcon/>
                    </IconButton>
                </HorizontalFlexLayout>
            </Box>
        </VerticalFlexLayout>
    )
}

export default MessagingControl