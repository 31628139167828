import React, {FC, useEffect, useState} from "react"
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator"
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import {Box, Breadcrumbs, Dialog, DialogContent, DialogTitle, Tab, Tabs} from "@mui/material";
import Details from "components/job/JobDetails";
import Job from "models/Job";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import DataNotFound from "components/common/DataNotFound";
import {Body2, Heading3, Heading4} from "components/common/Typography";
import Status from "components/common/Status";
import Proposal from "models/Proposal";
import RejectionReasonDialog from "components/common/RejectionReasonDialog";
import IconButton from "components/common/IconButton";
import Button from "components/common/Button";
import EditIcon from "components/common/icons/EditIcon";
import Api from "data/remote/Api";
import {handleFieldErrors} from "util/Utilities";
import ErrorResponse from "data/remote/models/ErrorResponse";
import Loading from "components/common/Loading";
import CloseIcon from "components/common/icons/CloseIcon";
import CreateOffer from "pages/admin/CreateOffer";
import ProposalsList from "components/proposal/ProposalsList";
import OffersList2 from "components/offer/OffersList2";
import CreateProposal from "pages/admin/CreateProposal";
import strings from "res/strings";
import MessagingControl from "components/MessagingControl";
import {useAlert} from "contexts/AlertContext";
import colors from "res/colors";
import {cachedCompanies} from "routers/AdminRouter";
import paths from "res/paths";

const JobDetails: FC = () => {
    const {id, companyId} = useParams()
    const navigate = useNavigate()
    const {hash} = useLocation()
    const alert = useAlert()

    const [job, setJob] = useState<Job>()
    const [loading, setLoading] = useState(true)
    const [value, setValue] = React.useState(0)
    const [proposal, setProposal] = useState<Proposal>()
    const [searchOpen, setSearchOpen] = useState(false)
    const [proposalOpen, setProposalOpen] = useState(false)

    // TODO: Find a better way to do this.
    const [title, setTitle] = useState<string>()

    useEffect(() => {
        void async function fetchData() {
            if (hash === "#offers") {
                setValue(1)
            } else if (hash === "#proposals") {
                setValue(2)
            } else if (hash === "#messages") {
                setValue(3)
            } else {
                setValue(0)
            }

            if (id && companyId) {
                await Api.jobs.getJobAsync(id, companyId)
                    .then(j => setJob(j))
                    .catch(res => handleFieldErrors(res.data as ErrorResponse))
            }

            setLoading(false)
        }()
    }, [id, companyId, hash])

    const changeStatus = async (status: "paid" | "complete") => {
        await Api.jobs.updateStatus(job!.id, job!.companyId, status)
            .then(() => {
                alert.enqueue({
                    message: `Assignment ${status === "paid" ? status : "completed"}!`,
                    severity: "success"
                })

                setJob(ps => ps && ({...ps, status: status === "paid" ? status : "completed"}))
            })
            .catch(res => handleFieldErrors(res.data as ErrorResponse))
    }

    if (loading) {
        return (<Loading/>)
    } else if (!job) {
        return (<DataNotFound/>)
    }

    const companyName = cachedCompanies.find(c => c.companyId === job.companyId)?.companyName
    const modificationAllowed = job.status === "draft" || job.status === "pending"

    return (<>
        <HtmlMetaDecorator
            title={job.name}/>

        <VerticalFlexLayout
            height={"100%"}
            wrap={"nowrap"}>
            <Box bgcolor={"white"} px={2} pt={2}>
                <VerticalFlexLayout spacing={2}>
                    <Breadcrumbs
                        separator=">"
                        sx={{fontSize: "small"}}>
                        <Link to={paths.adminAssignments}>
                            <Body2>
                                {strings.assignments}
                            </Body2>
                        </Link>
                        <Body2>
                            {strings.assignmentDetails}
                        </Body2>
                    </Breadcrumbs>
                    <Heading3>{job.name}</Heading3>
                    {companyName &&
                    <Body2 color={colors.disabledText}>
                        {companyName}
                    </Body2>}
                    <Status value={job.status}/>
                    <HorizontalFlexLayout spacing={2}>
                        <GridItem grow={1}>
                            <Tabs value={value}>
                                {/* TODO: Make the #offers type safe */}
                                {/* TODO: clicking this will reload every tab every time, there should be some condition to skip the reloading*/}
                                <Tab label="Details" sx={{padding: 3}} onClick={() => navigate("", {replace: true})}/>
                                <Tab label="Offers" onClick={() => navigate("#offers", {replace: true})}/>
                                <Tab label="Proposals" onClick={() => navigate("#proposals", {replace: true})}/>
                                <Tab label={strings.messagesHistory}
                                     onClick={() => navigate("#messages", {replace: true})}/>
                            </Tabs>
                        </GridItem>

                        {value === 0 && modificationAllowed &&
                        <IconButton
                            color={"secondary"}
                            variant={"outlined"}
                            onClick={() => navigate(`/console/assignments/${job.companyId}/${job.id}/edit`)}>
                            <EditIcon/>
                        </IconButton>}

                        {value === 0 && job.status === "active" &&
                        <Button
                            variant={"contained"}
                            onClick={() => changeStatus("complete")}>
                            Mark as Completed
                        </Button>}

                        {value === 0 && job.status === "completed" &&
                        <Button
                            variant={"contained"}
                            onClick={() => changeStatus("paid")}>
                            Mark as Paid
                        </Button>}

                        {value === 1 && modificationAllowed &&
                        <Button variant={"contained"} onClick={() => setSearchOpen(p => !p)}>
                            Create Offer
                        </Button>}

                        {value === 2 && modificationAllowed &&
                        <Button variant={"contained"} onClick={() => {
                            setProposalOpen(true)
                        }}>
                            Create Proposal
                        </Button>}
                    </HorizontalFlexLayout>
                </VerticalFlexLayout>
            </Box>

            {value === 0 &&
            <GridItem sx={{backgroundColor: "#F9F9F9", flexGrow: 1}}>
                <Box p={2}>
                    <Details job={job}/>
                </Box>
            </GridItem>}

            {value === 1 &&
            <GridItem sx={{backgroundColor: "#F9F9F9", flexGrow: 1}}>
                <Box p={2}
                     boxSizing={"border-box"}
                     height={"100%"}>
                    <OffersList2
                        jobId={job.id}
                        onClick={o => {
                            navigate(`/console/assignments/${o.companyId}/${o.jobId}/offer/${o.id}/${o.contractorId}`)
                        }}/>
                </Box>
            </GridItem>}

            {value === 2 &&
            <GridItem grow={1}>
                <Box
                    boxSizing={"border-box"}
                    height={"100%"}>
                    <ProposalsList
                        onClick={p => {
                            navigate(`/console/assignments/${p.job.companyId}/${p.job.id}/proposal/${p.id}`)
                        }}
                        jobId={job.id}
                        companyId={job.companyId}/>
                </Box>
            </GridItem>}

            {value === 3 &&
            <GridItem height={"100%"} zIndex={2}>
                <MessagingControl
                    kind={job.proposalId ? "job" : "newJob"}
                    jobId={job.id}
                    companyId={job.companyId}/>
            </GridItem>}
        </VerticalFlexLayout>

        <RejectionReasonDialog
            open={Boolean(proposal)}
            onReject={async () => {
                alert.enqueue({
                    message: strings.proposalRejectedSuccessMessage,
                    severity: "success"
                })
                setProposal(undefined)
            }}
            onCancel={() => setProposal(undefined)}/>

        <Dialog
            open={searchOpen}
            fullWidth
            PaperProps={{
                sx: {height: "100%"}
            }}
            maxWidth={"xl"}>
            <DialogTitle>
                <HorizontalFlexLayout wrap={"nowrap"}>
                    <GridItem sx={{flexGrow: 1}}>
                        <Heading4>{title}</Heading4>
                    </GridItem>
                    <IconButton
                        onClick={() => setSearchOpen(false)}>
                        <CloseIcon/>
                    </IconButton>
                </HorizontalFlexLayout>
            </DialogTitle>
            <DialogContent dividers>
                <CreateOffer
                    job={job}
                    changeTitle={t => setTitle(t)}
                    onComplete={o => {
                        setSearchOpen(false)
                        navigate(`/console/assignments/${o.companyId}/${o.jobId}/offer/${o.id}/${o.contractorId}`)
                    }}/>
            </DialogContent>
        </Dialog>

        <Dialog
            open={proposalOpen}
            fullWidth
            PaperProps={{
                sx: {height: "100%"}
            }}
            maxWidth={"xl"}>
            <DialogTitle>
                <HorizontalFlexLayout wrap={"nowrap"}>
                    <GridItem sx={{flexGrow: 1}}>
                        <Heading4>Selecting Offers</Heading4>
                    </GridItem>
                    <IconButton
                        onClick={() => setProposalOpen(false)}>
                        <CloseIcon/>
                    </IconButton>
                </HorizontalFlexLayout>
            </DialogTitle>
            <DialogContent dividers>
                <CreateProposal
                    job={job}
                    onSuccess={p => navigate(`/console/assignments/${job.companyId}/${job.id}/proposal/${p.id}`)}/>
            </DialogContent>
        </Dialog>
    </>)
}

export default JobDetails
