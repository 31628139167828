import React, {FC, useEffect, useState} from "react"
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator"
import {Box, Dialog, DialogActions, DialogContent} from "@mui/material";
import {useParams} from "react-router-dom";
import Api from "data/remote/Api";
import {handleFieldErrors} from "util/Utilities";
import ErrorResponse from "data/remote/models/ErrorResponse";
import Contractor from "models/Contractor";
import Loading from "components/common/Loading";
import DataNotFound from "components/common/DataNotFound";
import ContractorProfile from "components/contractor/Profile";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import IconButton from "components/common/IconButton";
import CloseIcon from "components/common/icons/CloseIcon";
import {Body1, Heading3, Heading4} from "components/common/Typography";
import colors from "res/colors";
import UpsertProfile from "components/contractor/UpsertProfile";
import EditIcon from "components/common/icons/EditIcon";
import strings from "res/strings";
import DialogTransition from "components/common/DialogTransition";
import {useAlert} from "contexts/AlertContext";
import Button from "components/common/Button";

const Profile: FC = () => {
    const {id} = useParams()
    const alert = useAlert()

    const [contractor, setContractor] = useState<Contractor>()
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [resetOpen, setResetOpen] = useState(false)

    useEffect(() => {
        void async function fetchData() {
            if (id) {
                await Api.users
                    .getContractorProfile(id)
                    .then(c => setContractor(c))
                    .catch(res => handleFieldErrors(res.data as ErrorResponse))
            }

            setLoading(false)
        }()
    }, [id])

    if (loading) {
        return (<Loading/>)
    } else if (!contractor) {
        return (<DataNotFound/>)
    }

    return (<>
        <HtmlMetaDecorator
            title={contractor.name}/>

        <VerticalFlexLayout
            height={"100%"}
            wrap={"nowrap"}>
            <Box p={2} sx={{backgroundColor: "white"}}>
                <HorizontalFlexLayout spacing={2}>
                    <GridItem sx={{flexGrow: 1}}>
                        <Heading3>
                            {strings.profile}
                        </Heading3>
                    </GridItem>
                    <Button
                        variant={"outlined"}
                        color={"secondary"}
                        fullWidth
                        onClick={() => setResetOpen(true)}>
                        {strings.resetPassword}
                    </Button>
                    <IconButton
                        variant={"outlined"}
                        color={"secondary"}
                        onClick={() => setOpen(true)}>
                        <EditIcon/>
                    </IconButton>
                </HorizontalFlexLayout>
            </Box>
            <GridItem sx={{backgroundColor: "#F9F9F9", flexGrow: 1}}>
                <Box p={2}>
                    <ContractorProfile
                        contractor={contractor}/>
                </Box>
            </GridItem>
        </VerticalFlexLayout>

        <Dialog
            open={open}
            fullScreen
            scroll={"paper"}
            TransitionComponent={DialogTransition}
            onClose={() => setOpen(false)}>
            <DialogActions sx={{padding: 2}}>
                <HorizontalFlexLayout spacing={2} wrap={"nowrap"}>
                    <GridItem sx={{flexGrow: 1}}>
                        <Heading4>{contractor.name}</Heading4>
                    </GridItem>
                    <IconButton
                        onClick={() => setOpen(false)}>
                        <CloseIcon/>
                    </IconButton>
                </HorizontalFlexLayout>
            </DialogActions>
            <DialogContent
                dividers
                sx={{backgroundColor: colors.pearlWhite}}>
                <UpsertProfile
                    isAdmin
                    contractor={contractor}
                    onSuccess={c => {
                        setContractor(c)
                        setOpen(false)
                    }}/>
            </DialogContent>
        </Dialog>

        <Dialog
            open={resetOpen}
            fullWidth
            maxWidth={"sm"}
            TransitionComponent={DialogTransition}
            onClose={() => setResetOpen(false)}>
            <DialogContent>
                <VerticalFlexLayout spacing={2}>
                    <Heading4>{strings.resetPassword}</Heading4>
                    <Body1>
                        Are you sure you want to reset the contractor's password?
                    </Body1>
                    <HorizontalFlexLayout spacing={2}>
                        <GridItem xs={6}>
                            <Button
                                variant={"outlined"}
                                color={"secondary"}
                                fullWidth
                                onClick={() => setResetOpen(false)}>
                                {strings.cancel}
                            </Button>
                        </GridItem>
                        <GridItem xs={6}>
                            <Button
                                variant={"contained"}
                                color={"error"}
                                fullWidth
                                onClick={async () => {
                                    await Api.auth
                                        .resetPasswordAsync(contractor?.attributes.email)
                                        .then(() => {
                                            alert.enqueue({
                                                message: "Password was reset successfully.",
                                                severity: "success"
                                            })
                                            setResetOpen(false)
                                        })
                                        .catch(res => handleFieldErrors(res.data as ErrorResponse))
                                }}>
                                {strings.reset}
                            </Button>
                        </GridItem>
                    </HorizontalFlexLayout>
                </VerticalFlexLayout>
            </DialogContent>
        </Dialog>
    </>)
}

export default Profile
