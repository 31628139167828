import React, {FC, useState,} from "react"
import {Box, Paper} from "@mui/material";
import Button from "components/common/Button";
import ContractorHeader from "components/contractor/ContractorHeader";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import strings from "res/strings";
import {Controller, useForm, FormProvider} from "react-hook-form";
import InputField from "components/common/InputField";
import BudgetField from "components/common/BudgetField";
import Job from "models/Job";
import Offer from "models/Offer";
import Api from "data/remote/Api";
import {handleFieldErrors, moneyString} from "util/Utilities";
import ErrorResponse from "data/remote/models/ErrorResponse";
import OffersList2 from "components/offer/OffersList2";
import {Body2} from "components/common/Typography";
import CloseIcon from "components/common/icons/CloseIcon";
import Proposal from "models/Proposal";

type Stage = "offers" | "proposal"

interface Props {
    job?: Job
    proposal?: Proposal
    onSuccess: (proposal: Proposal) => void
}

// TODO: The code is fragile.
const CreateProposal: FC<Props> = (props) => {
    const form = useForm<Offer>({})

    const [stage, setStage] = useState<Stage>("offers")
    const [loading, setLoading] = useState(false)
    const [selectedOffers, setSelectedOffers] = useState<Offer[]>([])

    const onSubmitOffer = form.handleSubmit(async fields => {
        if (loading) {
            return
        }

        form.clearErrors()
        fields.deadline = new Date(fields.deadline).toISOString()

        if (fields.budget?.value) {
            fields.budget.value = Number.parseFloat(fields.budget.value.toString())
        } else {
            fields.budget.currency = ""
        }

        setLoading(true)

        await Api.offers
            .createOffer(fields)
            .then(o => {
                fields.id = o!.id
                fields.status = "draft"
                setStage("offers")
            })
            .catch(res => {
                handleFieldErrors(res.data as ErrorResponse, form.setError)
            })

        setLoading(false)
    })

    if (stage === "offers") {
        return (
            <VerticalFlexLayout spacing={2}>
                <Body2>Please select the offers you want to add to the proposal:</Body2>
                {/* this  is same as Table 2, move to compoennt*/}
                <GridItem xs={12} width={"100%"}>
                    <HorizontalFlexLayout spacing={2}>
                        {selectedOffers.map(o => (
                            <GridItem key={o.id} xs={12} md={6}>
                                <Paper variant={"outlined"} onClick={() => {
                                    setSelectedOffers(ps => ps.filter(o1 => o.id !== o1.id))
                                }}>
                                    <Box p={1}>
                                        <HorizontalFlexLayout
                                            spacing={2}
                                            wrap={"nowrap"}
                                            sx={{cursor: "pointer"}}
                                            alignItems={"center"}>
                                            <GridItem grow={1}>
                                                <ContractorHeader
                                                    contractor={o.contractor}
                                                    subtitle={`${o.name} (${moneyString(o.budget)})`}/>
                                            </GridItem>
                                            <CloseIcon/>
                                        </HorizontalFlexLayout>
                                    </Box>
                                </Paper>
                            </GridItem>
                        ))}
                    </HorizontalFlexLayout>
                </GridItem>
                <OffersList2
                    defaultStatus={"accepted"}
                    jobId={props.job?.id ?? props.proposal?.job.id}
                    onClick={o => {
                        if (!selectedOffers.find(o1 => o1.id === o.id)) {
                            setSelectedOffers(ps => [...ps, o])
                        }
                    }}
                />
                <GridItem xs={12}>
                    <HorizontalFlexLayout justify={"flex-end"}>
                        <Button
                            disabled={!selectedOffers.length}
                            variant={"contained"}
                            onClick={async () => {
                                if (props.proposal) {
                                    const proposal = props.proposal
                                    proposal.offers = [...proposal.offers, ...selectedOffers]
                                    proposal.jobId = proposal.job.id
                                    proposal.companyId = proposal.job.companyId
                                    await Api.proposals.updateProposalAsync(proposal)
                                        .then(p => {
                                            props.onSuccess(p as Proposal)
                                        })
                                        .catch(res => {
                                            handleFieldErrors(res.data as ErrorResponse)
                                        })
                                } else {
                                    const proposal = {
                                        ...props.job,
                                        jobId: props.job!.id,
                                        budget: props.job?.budget,
                                        deadline: props.job?.deadline,
                                        offerIds: selectedOffers.map(o => o.id)
                                    } as unknown as Proposal

                                    await Api.proposals.createProposalAsync(proposal)
                                        .then(p => {
                                            proposal.id = p!.id
                                            props.onSuccess(proposal)
                                        })
                                        .catch(res => {
                                            handleFieldErrors(res.data as ErrorResponse)
                                        })
                                }
                            }}>
                            Next
                        </Button>
                    </HorizontalFlexLayout>
                </GridItem>
            </VerticalFlexLayout>
        )
    } else {
        return (
            <VerticalFlexLayout spacing={4}>
                <FormProvider {...form}>
                    <form onSubmit={onSubmitOffer}>
                        <HorizontalFlexLayout spacing={2}>
                            <GridItem xs={12}>
                                <Controller
                                    name="name"
                                    control={form.control}
                                    render={({field, fieldState: {error}}) => (
                                        <InputField
                                            label={"Name"}
                                            placeholder={strings.jobNamePlaceholder}
                                            required
                                            fieldError={error}
                                            {...field} />
                                    )}/>
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <Controller
                                    name="deadline"
                                    control={form.control}
                                    render={({field, fieldState: {error}}) => (
                                        <InputField
                                            label={strings.deadline}
                                            type={"date"}
                                            required
                                            fieldError={error}
                                            {...field} />
                                    )}/>
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <BudgetField
                                    namespace="budget"
                                    label={strings.budget}/>
                            </GridItem>
                            <GridItem xs={12}>
                                <Controller
                                    name="notes"
                                    control={form.control}
                                    render={({field, fieldState: {error}}) => (
                                        <InputField
                                            label={strings.notes}
                                            multiline
                                            fieldError={error}
                                            {...field} />
                                    )}/>
                            </GridItem>
                            <GridItem xs={12}>
                                <HorizontalFlexLayout spacing={2}>
                                    <GridItem grow={1}>
                                        <Button
                                            variant={"outlined"}
                                            color={"secondary"}
                                            onClick={() => setStage("offers")}>
                                            Back
                                        </Button>
                                    </GridItem>
                                    <Button
                                        isBusy={loading}
                                        type="submit"
                                        variant={"contained"}>
                                        {strings.next}
                                    </Button>
                                </HorizontalFlexLayout>
                            </GridItem>
                        </HorizontalFlexLayout>
                    </form>
                </FormProvider>
            </VerticalFlexLayout>
        )
    }
}

export default CreateProposal
