import React, {FC, useState} from "react"
import {TextField, Typography} from "@mui/material"
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout"
import Button from "components/common/Button"
import PasswordValidation, {PasswordValidationStatus} from "components/account/PasswordValidation";
import strings from "res/strings";

interface Props {
    type: 'new' | 'reset' | 'change'
    onSubmit: (password: string, code?: string) => Promise<void>
    code?: string
}

const PasswordValidationControl: FC<Props> = (props: Props) => {
    const [code, setCode] = useState<string | undefined>(props.code)
    const [password, setPassword] = useState<string>()
    const [confirm, setConfirm] = useState<string>()
    const [codeError, setCodeError] = useState<string>()
    const [validation, setValidation] = useState<PasswordValidationStatus>('invalid')
    const [passwordError, setPasswordError] = useState<string>()
    const [confirmError, setConfirmError] = useState<string>()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCodeError(undefined)
        setCode(e.target.value)
    }

    const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
        setPasswordError(undefined)
    }

    const onConfirmChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmError(undefined)
        setConfirm(e.target.value)
    }

    const onSubmit = async () => {
        if (isLoading) {
            return
        }

        if (props.type !== "new" && (code?.length ?? 0) < 6) {
            setCodeError("Please enter a valid value!")
            return
        }

        if (validation === "invalid") {
            setPasswordError(strings.invalidPasswordMessage)
            return
        } else if (validation === "mismatch") {
            setConfirmError(strings.passwordMismatchMessage)
            return
        }

        setIsLoading(true)
        await props.onSubmit(password!, code)
        setIsLoading(false)
    }

    let title = "Create Password"
    if (props.type === "reset") {
        title = "Reset Password"
    } else if (props.type === "change") {
        title = "Change Password"
    }

    return (
        <VerticalFlexLayout spacing={3}>
            <Typography variant={"h6"}>{title}</Typography>
            {props.type !== "new" &&
            <GridItem style={{width: "100%"}} hidden={Boolean(props.code)}>
                <HorizontalFlexLayout spacing={3}>
                    <GridItem xs={12} sm={6}>
                        <Typography variant={"subtitle1"}>
                            {props.type === "change" ? "Old Password" : "Verification Code"}
                        </Typography>
                        <TextField
                            value={code}
                            name="code"
                            type="password"
                            variant="outlined"
                            required
                            fullWidth
                            error={Boolean(codeError)}
                            helperText={codeError}
                            onChange={onCodeChange}
                        />
                    </GridItem>
                </HorizontalFlexLayout>
            </GridItem>}

            <PasswordValidation
                password={password}
                confirm={confirm}
                onChange={s => setValidation(s)}/>

            <HorizontalFlexLayout spacing={3}>
                <GridItem xs={12} sm={6}>
                    <Typography variant={"subtitle1"}>
                        New Password
                    </Typography>
                    <TextField
                        value={password}
                        name="password"
                        type="password"
                        variant="outlined"
                        required
                        fullWidth
                        error={Boolean(passwordError)}
                        helperText={passwordError}
                        onChange={onPasswordChange}/>
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <Typography variant={"subtitle1"}>
                        Confirm Password
                    </Typography>
                    <TextField
                        value={confirm}
                        name="confirmPassword"
                        type="password"
                        variant="outlined"
                        required
                        fullWidth
                        error={Boolean(confirmError)}
                        helperText={confirmError}
                        onChange={onConfirmChange}/>
                </GridItem>
            </HorizontalFlexLayout>
            <Button
                type="submit"
                fullWidth
                size={"large"}
                variant="contained"
                color="primary"
                isBusy={isLoading}
                onClick={onSubmit}>
                {title}
            </Button>
        </VerticalFlexLayout>
    )
}

export default PasswordValidationControl
