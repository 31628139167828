import React, {FC} from "react";
import CrewMember from "models/CrewMember";
import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import {Body2, Heading5, Heading6} from "components/common/Typography";
import colors from "res/colors";
import IconButton from "components/common/IconButton";
import {deserializeWorkingDates, moneyString} from "util/Utilities";
import strings from "res/strings";
import TrashIcon from "components/common/icons/TrashIcon";
import EditIcon from "components/common/icons/EditIcon";
import DisplayField from "components/common/DisplayField";

type Action = "edit" | "delete"

interface Props {
    crewMember: CrewMember
    onAction?: (action: Action, crewMember: CrewMember) => void
}

const CrewMemberDetailsCard: FC<Props> = (props) => {
    const member = props.crewMember ?? {}
    const showActions = Boolean(props.onAction)

    function onAction(action: Action) {
        if (props.onAction) {
            props.onAction(action, props.crewMember)
        }
    }

    return (
        <Paper variant={"outlined"}>
            <Box p={2}>
                <VerticalFlexLayout spacing={2}>
                    <HorizontalFlexLayout
                        spacing={2}
                        wrap={"nowrap"}>
                        <GridItem sx={{flexGrow: 1}}>
                            <Heading5>
                                {member.title}
                            </Heading5>
                        </GridItem>

                        {showActions &&
                        <IconButton
                            variant={"outlined"}
                            color={"secondary"}
                            onClick={() => onAction("edit")}>
                            <EditIcon/>
                        </IconButton>}

                        {showActions &&
                        <IconButton
                            variant={"outlined"}
                            color={"error"}
                            onClick={() => onAction("delete")}>
                            <TrashIcon
                                color={colors.error}/>
                        </IconButton>}
                    </HorizontalFlexLayout>

                    <DisplayField
                        size={"h6"}
                        title={strings.rate}
                        content={`${moneyString(member.rate.rate)} / ${member.rate.rateInterval}`}/>

                    <GridItem>
                        <Heading6>{strings.workingDates}</Heading6>
                        <TableContainer>
                            <Table size="small">
                                <TableBody>
                                    {deserializeWorkingDates(member.workingDates ?? [])
                                        .map((w, i) => (
                                            <TableRow
                                                key={i}
                                                sx={{'&:last-child td': {border: 0}}}>
                                                <TableCell sx={{paddingLeft: 0}}>
                                                    <Body2>{w.date}</Body2>
                                                </TableCell>
                                                <TableCell>
                                                    <Body2>{w.from}</Body2>
                                                </TableCell>
                                                <TableCell sx={{paddingRight: 0}}>
                                                    <Body2>{w.to}</Body2>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </GridItem>

                    <DisplayField
                        size={"h6"}
                        title={strings.notes}
                        content={member.notes ?? "-"}/>
                </VerticalFlexLayout>
            </Box>
        </Paper>
    )
}

export default CrewMemberDetailsCard
