import React from "react";
import IconProps from "components/common/icons/IconProps";

const LinkedInIcon: React.FC<IconProps> = (props) => (
    <div className={"svg-container"}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size ?? "24"}
            height={props.size ?? "24"}
            viewBox="0 0 24 24">
            <path
                d="M19,3H5C3.895,3,3,3.895,3,5v14c0,1.105,0.895,2,2,2h14c1.105,0,2-0.895,2-2V5C21,3.895,20.105,3,19,3z M9,17H6.477v-7H9 V17z M7.694,8.717c-0.771,0-1.286-0.514-1.286-1.2s0.514-1.2,1.371-1.2c0.771,0,1.286,0.514,1.286,1.2S8.551,8.717,7.694,8.717z M18,17h-2.442v-3.826c0-1.058-0.651-1.302-0.895-1.302s-1.058,0.163-1.058,1.302c0,0.163,0,3.826,0,3.826h-2.523v-7h2.523v0.977 C13.93,10.407,14.581,10,15.802,10C17.023,10,18,10.977,18,13.174V17z"
                fill={props.color ?? "black"}/>
        </svg>
    </div>
)

export default LinkedInIcon
