import WorkingHour from 'models/WorkingHour'
import WorkingDatesVM from 'viewModels/WorkingDatesVM'
import {UseFormSetError} from 'react-hook-form/dist/types/form'
import {FieldValues} from 'react-hook-form/dist/types/fields'
import {ErrorResponse} from 'data/remote/models/ErrorResponse'
import Money from "models/Money";
import Location from "models/Location";
import strings from "res/strings";
import {staticEnqueueAlert} from "contexts/AlertContext";

export const getCookie = (key: string) => {
    return `${document.cookie}`
        .split('; ')
        .find((c) => c.trim().startsWith(`${key}=`))
        ?.split('=')[1]
}

export const getUuid = (): string => {
    return require("uuid").v4()
}

export const moneyString = (m: Money): string => {
    return m && m.value && m.currency ?
        `${m.value?.toFixed(2) ?? ""} ${m.currency ?? ""}` : "-"
}

export const locationString = (l: Location): string => {
    return l && l.city && l.country ?
        `${l.city ?? ""}, ${l.country ?? ""}` : "-"
}

export const toShortDate = (date: string): string => {
    const timestamp = Date.parse(date)
    return isNaN(timestamp) ? "-" : new Date(timestamp).toLocaleDateString()
}

export const toDateInput = (date: string): string => {
    return date?.split('T')[0] ?? ""
}

export const toAge = (date: string): string => {
    const seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000)

    let interval = seconds / 31536000;
    if (interval > 1) {
        return Math.floor(interval) + " years ago"
    }

    interval = seconds / 604800;
    if (interval > 1) {
        return Math.floor(interval) + " weeks ago"
    }

    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days ago"
    }

    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours ago"
    }

    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutes ago"
    }

    return "now"
}

export const truncate = (text: string, max: number = 14): string => {
    return text.length > max ? text.substring(0, max) + "..." : text
}

export const serializeWorkingDates = (input: WorkingDatesVM[]): WorkingHour[] =>
    input.map((w) => {
        const from = new Date(w.date)
        from.setHours(Number(w.from.split(':')[0]), Number(w.from.split(':')[1]))

        const to = new Date(w.date)
        to.setHours(Number(w.to.split(':')[0]), Number(w.to.split(':')[1]))

        return {
            startDate: from.toISOString(),
            endDate: to.toISOString()
        }
    })

export const deserializeWorkingDates = (
    input: WorkingHour[]
): WorkingDatesVM[] =>
    input.map((w) => ({
        date: w.startDate.split('T')[0],
        from: new Date(w.startDate).toTimeString().slice(0, 5),
        to: new Date(w.endDate).toTimeString().slice(0, 5)
    }))

interface Jwt<H, P, S> {
    header: H
    payload: P
    signature: S
}

interface JwtPayload {
    'cognito:groups': string[]
    'cognito:username': string
    'given_name': string
    'family_name': string
    email: string
}

export const decodeJwt = <H = any, P = JwtPayload, S = any>(
    base64: string
): Jwt<H, P, S> => {
    // TODO: Fix decoding header & signature; keep getting: Failed to execute 'atob' on
    //  'Window': The string to be decoded is not correctly encoded.
    let token = {
        header: {} as H,
        payload: {} as P,
        signature: {} as S
    }

    const tokens = base64.split('.')

    if (tokens.length !== 3) {
        return token
    }

    const decode = (value: string) => {
        try {
            // Replace base64 url characters
            const b64 = value.replace(/-/g, '+')
                .replace(/_/g, '/')
            const decoded = window.atob(b64)
            return JSON.parse(decoded)
        } catch (e) {
            console.warn(`Failed to decode ${value}, error: ${e}`)
            return {}
        }
    }

    token.header = decode(tokens[0])
    token.payload = decode(tokens[1])
    // TODO: Signature should be decoded into a byte array for verification, not into a string; for now, we just return an empty object
    return token
}

export const handleFieldErrors = <T extends FieldValues>(
    error: ErrorResponse,
    setError?: UseFormSetError<T>
) => {
    if (error?.errors) {
        if (setError) {
            error?.errors
                ?.filter((e) => e.field)
                .forEach((e) => {
                    // TODO: Check if the field is keyof VM.
                    // @ts-ignore
                    setError(e.namespace ? `${e.namespace}.${e.field}` : e.field, {message: e.message})
                })
        }

        const message = error?.errors
            ?.filter((e) => !setError || !e.field)
            .map((e) => `- ${e.message}`)

        if (message?.length) {
            staticEnqueueAlert({
                message: message.join("\n"),
                severity: "error"
            })
        }
    } else {
        staticEnqueueAlert({
            message: error?.message ?? strings.internalErrorMessage,
            severity: "error"
        })
    }
}
