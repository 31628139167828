import React, {FC, useState} from "react"
import {Box, Dialog, DialogContent, DialogTitle, Paper} from "@mui/material"
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout"
import {Body1, Body2, Heading3, Heading4} from "components/common/Typography";
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator";
import {toShortDate} from "util/Utilities";
import colors from "res/colors";
import strings from "res/strings";
import {useNavigate} from "react-router-dom";
import Button from "components/common/Button";
import IconButton from "components/common/IconButton";
import CloseIcon from "components/common/icons/CloseIcon";
import UpsertUserControl from "components/user/UpsertUserControl";
import {cachedCompanies} from "routers/AdminRouter";
import {useLiveChat} from "components/common/LiveChat";
import CommentIcon from "components/common/icons/CommentIcon";

const Companies: FC = () => {
    const navigate = useNavigate()
    const chat = useLiveChat()

    const [open, setOpen] = useState(false)

    return (<>
            <HtmlMetaDecorator
                title={strings.clients}/>

            <VerticalFlexLayout
                height={"100%"}
                wrap={"nowrap"}>
                <Box p={2} bgcolor={"white"}>
                    <HorizontalFlexLayout spacing={2}>
                        <GridItem grow={1}>
                            <Heading3>{strings.clients}</Heading3>
                        </GridItem>
                        <Button
                            variant={"contained"}
                            onClick={() => setOpen(true)}>
                            {strings.createClient}
                        </Button>
                    </HorizontalFlexLayout>
                </Box>
                <GridItem sx={{backgroundColor: "#F9F9F9", flexGrow: 1}}>
                    <Box p={2}>
                        <HorizontalFlexLayout spacing={2}>
                            {cachedCompanies?.map(c => (
                                <GridItem key={c.id} xs={12} md={6}>
                                    <Paper
                                        variant={"outlined"}
                                        sx={{cursor: "pointer"}}
                                        onClick={() => navigate(`/console?status=pending&companyId=${c.companyId}`)}>
                                        <Box p={2}>
                                            <VerticalFlexLayout spacing={1}>
                                                <HorizontalFlexLayout
                                                    spacing={1}
                                                    wrap={"nowrap"}>
                                                    <GridItem grow={1}>
                                                        <Heading4>{c.companyName}</Heading4>
                                                    </GridItem>
                                                    <IconButton
                                                        variant={"contained"}
                                                        onClick={e => {
                                                            e.stopPropagation()
                                                            chat.start(c.id, c.companyId)
                                                        }}>
                                                        <CommentIcon/>
                                                    </IconButton>
                                                </HorizontalFlexLayout>
                                                <Body1 color={colors.copy}>
                                                    {c.name}
                                                </Body1>
                                                <HorizontalFlexLayout
                                                    spacing={2}
                                                    justify={"space-between"}>
                                                    <Body2 color={colors.disabledText}>
                                                        {c.email}
                                                    </Body2>
                                                    <Body2 color={colors.disabledText}>
                                                        Since: {toShortDate(c.created)}
                                                    </Body2>
                                                </HorizontalFlexLayout>
                                            </VerticalFlexLayout>
                                        </Box>
                                    </Paper>
                                </GridItem>
                            ))}
                        </HorizontalFlexLayout>
                    </Box>
                </GridItem>
            </VerticalFlexLayout>

            <Dialog
                open={open}
                fullWidth
                maxWidth={"sm"}>
                <DialogTitle>
                    <HorizontalFlexLayout wrap={"nowrap"}>
                        <GridItem sx={{flexGrow: 1}}>
                            <Heading4>{strings.createClient}</Heading4>
                        </GridItem>
                        <IconButton
                            onClick={() => setOpen(false)}>
                            <CloseIcon/>
                        </IconButton>
                    </HorizontalFlexLayout>
                </DialogTitle>
                <DialogContent dividers>
                    <UpsertUserControl
                        role={"company"}
                        onSuccess={() => setOpen(false)}/>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Companies
