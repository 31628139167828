import React, {FC, useState, KeyboardEvent, useEffect, useCallback} from "react";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import InputField from "components/common/InputField";
import {Tags} from "components/common/Tags";
import CloseIcon from "components/common/icons/CloseIcon";
import {Box, Divider, IconButton, Paper} from "@mui/material";
import SearchIcon from "components/common/icons/SearchIcon";
import strings from "res/strings";
import {Body2} from "components/common/Typography";
import Contractor from "models/Contractor";
import colors from "res/colors";
import ContractorHeader from "components/contractor/ContractorHeader";
import ContractorDetails from "components/contractor/ContractorDetails";
import Button from "components/common/Button";
import Api from "data/remote/Api";
import {handleFieldErrors} from "util/Utilities";
import ErrorResponse from "data/remote/models/ErrorResponse";
import List from "models/List";

interface Props {
    label?: string
    onSelect: (contractors: Contractor) => void
}

const SearchContractor: FC<Props> = (props) => {
    const [term, setTerm] = useState<string | undefined>()
    const [terms, setTerms] = useState<string[]>([])
    const [contractors, setContractor] = useState<List<Contractor>>()

    const getContractors = useCallback(async () => {
        await Api.users
            .getContractors(terms.join(','))
            .then(c => setContractor(c))
            .catch(res => handleFieldErrors(res.data as ErrorResponse))
    }, [terms])

    useEffect(() => {
        void async function fetchData() {
            await getContractors()
        }()
    }, [terms, getContractors])

    const onSearchKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            addTerm()
        }
    }

    const addTerm = () => {
        if (term) {
            setTerms(ps => [...ps, term])
            setTerm("")
        }
    }

    const removeTerm = (term: string) => {
        setTerms(ps => ps.filter(t => t !== term))
    }

    return (
        <VerticalFlexLayout
            spacing={4}
            height={"100%"}
            wrap={"nowrap"}>
            <HorizontalFlexLayout
                spacing={2}
                alignItems={"center"}>
                <GridItem xs={12}>
                    <InputField
                        placeholder={strings.search}
                        value={term}
                        onChange={e => setTerm(e.target.value)}
                        onKeyDown={onSearchKeyDown}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    onClick={addTerm}
                                    sx={{padding: "4px"}}
                                    edge="end">
                                    <SearchIcon/>
                                </IconButton>
                            )
                        }}/>
                </GridItem>
            </HorizontalFlexLayout>

            {Boolean(terms?.length) &&
            <Tags
                label={strings.filter}
                items={terms}
                onClick={removeTerm}
                icon={<CloseIcon size={16}/>}
                iconPosition={"end"}/>}

            {contractors &&
            <Body2 color={colors.disabledText}>
                {`Showing ${contractors?.count}/${contractors?.total} freelancers`}
            </Body2>}

            <HorizontalFlexLayout spacing={2} alignItems={"stretch"}>
                {contractors?.items.map(c => (
                    <GridItem key={c.id} xs={12} lg={6}>
                        <Paper variant={"outlined"} sx={{height: "100%"}}>
                            <Box p={2}>
                                <VerticalFlexLayout spacing={2}>
                                    <HorizontalFlexLayout
                                        spacing={2}
                                        wrap={"nowrap"}
                                        alignItems={"center"}>
                                        <GridItem sx={{flexGrow: 1}}>
                                            <ContractorHeader contractor={c} stretch/>
                                        </GridItem>
                                        <Button
                                            variant={"contained"}
                                            onClick={() => props.onSelect(c)}>
                                            {props.label ?? strings.select}
                                        </Button>
                                    </HorizontalFlexLayout>
                                    <Divider/>
                                    <ContractorDetails contractor={c}/>
                                </VerticalFlexLayout>
                            </Box>
                        </Paper>
                    </GridItem>
                ))}
            </HorizontalFlexLayout>
        </VerticalFlexLayout>
    )
}

export default SearchContractor
