import React, {FC, PropsWithChildren} from 'react'
import {Routes, Route, useNavigate} from "react-router-dom"
import NotFound from "pages/NotFound"
import AppBarContainer from "components/common/AppBarContainer";
import Jobs from "pages/company/Jobs";
import JobDetails from "pages/company/JobDetails";
import UpsertJob from "pages/company/UpsertJob";
import Messages from "pages/Messages";
import {Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import strings from "res/strings";
import TaskIcon from "components/common/icons/TaskIcon";
import CommentIcon from "components/common/icons/CommentIcon";

const CompanyRouterWrapper: FC<PropsWithChildren> = (props) => {
    let navigate = useNavigate()

    const list = (
        <Box>
            <List>
                <ListItem>
                    <ListItemButton onClick={() => navigate("/company/jobs")}>
                        <ListItemIcon>
                            <TaskIcon/>
                        </ListItemIcon>
                        <ListItemText primary={strings.assignments}/>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton onClick={() => navigate("/company/chats")}>
                        <ListItemIcon>
                            <CommentIcon/>
                        </ListItemIcon>
                        <ListItemText primary={"Chats"}/>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    )

    return (
        <AppBarContainer drawerList={list}>
            {props.children}
        </AppBarContainer>
    )
}

const CompanyRouter: FC = () => {
    return (
        <CompanyRouterWrapper>
            <Routes>
                <Route path="/jobs/create" element={<UpsertJob/>}/>
                <Route path="/jobs/edit/:id" element={<UpsertJob/>}/>
                <Route path="/jobs/:id" element={<JobDetails/>}/>
                <Route path="/jobs" element={<Jobs/>}/>
                <Route path="chats" element={<Messages/>}/>
                <Route path="/" element={<Jobs/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </CompanyRouterWrapper>
    )
}

export default CompanyRouter
