import React, {useState} from "react"
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import InputField from "components/common/InputField";
import strings from "res/strings";
import {useForm, FormProvider, Controller} from "react-hook-form";
import Button from "components/common/Button";
import {Body2} from "components/common/Typography";
import {useAlert} from "contexts/AlertContext";
import Api from "data/remote/Api";
import {handleFieldErrors} from "util/Utilities";
import ErrorResponse from "data/remote/models/ErrorResponse";
import ReCAPTCHA from "react-google-recaptcha";

interface ContactUsVm {
    firstName: string
    lastName: string
    email: string
    phone: string
    message: string
    token: string
}

const ContactUs: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState<string>()

    const alert = useAlert()
    const form = useForm<ContactUsVm>()

    const onSubmit = form.handleSubmit(async fields => {
        if (loading) {
            return
        }

        form.clearErrors()
        setLoading(true)

        fields.token = token ?? ""

        await Api.general
            .contactUsAsync(fields)
            .then(() => {
                alert.enqueue({
                    message: strings.successContactUsMessage,
                    severity: "success"
                })

                form.reset({
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    message: ""
                })

                setToken(undefined)
            })
            .catch(res => handleFieldErrors(res.data as ErrorResponse, form.setError))

        setLoading(false)
    })

    return (
        <FormProvider {...form}>
            <form onSubmit={onSubmit}>
                <HorizontalFlexLayout
                    justify={"center"}
                    spacing={4}>
                    <GridItem xs={12} sm={6}>
                        <Controller
                            name="firstName"
                            control={form.control}
                            render={({field, fieldState: {error}}) => (
                                <InputField
                                    label={strings.firstName}
                                    required
                                    fieldError={error}
                                    {...field} />
                            )}/>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                        <Controller
                            name="lastName"
                            control={form.control}
                            render={({field, fieldState: {error}}) => (
                                <InputField
                                    label={strings.lastName}
                                    required
                                    fieldError={error}
                                    {...field} />
                            )}/>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                        <Controller
                            name="email"
                            control={form.control}
                            render={({field, fieldState: {error}}) => (
                                <InputField
                                    label={strings.email}
                                    required
                                    fieldError={error}
                                    {...field} />
                            )}/>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                        <Controller
                            name="phone"
                            control={form.control}
                            render={({field, fieldState: {error}}) => (
                                <InputField
                                    label={strings.phone}
                                    type={"number"}
                                    required
                                    fieldError={error}
                                    {...field} />
                            )}/>
                    </GridItem>
                    <GridItem xs={12}>
                        <Controller
                            name="message"
                            control={form.control}
                            render={({field, fieldState: {error}}) => (
                                <InputField
                                    label={strings.writeYourMessage}
                                    multiline
                                    required
                                    fieldError={error}
                                    {...field} />
                            )}/>
                    </GridItem>
                    <VerticalFlexLayout spacing={2}>
                        <Body2>{"TAO Media Limited is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below to say how you would like us to contact you:"}</Body2>
                        <Body2>{"You can unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our Privacy Policy."}</Body2>
                        <Body2>{"By clicking submit below, you consent to allow TAO Media Limited to store and process the personal information submitted above to provide you the content requested."}</Body2>
                    </VerticalFlexLayout>
                    <GridItem>
                        <Button
                            isBusy={loading}
                            type={"submit"}
                            size={"large"}
                            color={"secondary"}
                            variant={"contained"}
                            sx={{
                                borderRadius: 24,
                                display: Boolean(token) ? "auto" : "none"
                            }}>
                            {strings.submit}
                        </Button>
                        <ReCAPTCHA
                            hidden={Boolean(token)}
                            sitekey="6LcDMB8kAAAAAJTiTXqnpSt9Et6FocAWYjGxqFH0"
                            onChange={(t: string | null) => setToken(t ?? undefined)}
                            onExpired={() => setToken(undefined)}
                            onError={() => {
                                setToken(undefined)
                                alert.enqueue({
                                    message: strings.internalErrorMessage,
                                    severity: "error"
                                })
                            }}/>
                    </GridItem>
                </HorizontalFlexLayout>
            </form>
        </FormProvider>
    )
}

export default ContactUs
