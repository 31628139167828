import React, {FC, useEffect, useState} from "react";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import {Box, Breadcrumbs, Dialog, DialogActions, DialogContent, Menu, MenuItem, Paper, Tab, Tabs} from "@mui/material";
import {Body1, Body2, Heading3, Heading4, Heading5, Heading6} from "components/common/Typography";
import Button from "components/common/Button";
import colors from "res/colors";
import Status from "components/common/Status";
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator";
import {Link, useNavigate, useParams} from "react-router-dom";
import CrewMemberDetailsCard from "components/crewMember/CrewMemberDetailsCard";
import Offer from "models/Offer";
import DataNotFound from "components/common/DataNotFound";
import Api from "data/remote/Api";
import {handleFieldErrors, locationString, moneyString, toShortDate} from "util/Utilities";
import ErrorResponse from "data/remote/models/ErrorResponse";
import Loading from "components/common/Loading";
import strings from "res/strings";
import DisplayField from "components/common/DisplayField";
import IconButton from "components/common/IconButton";
import MoreIcon from "components/common/icons/MoreIcon";
import AddPersonIcon from "components/common/icons/AddPersonIcon";
import UpsertCrewMemberControl from "components/crewMember/UpsertCrewMemberControl";
import CrewMember from "models/CrewMember";
import MessagingControl from "components/MessagingControl";
import {useAlert} from "contexts/AlertContext";
import EditIcon from "components/common/icons/EditIcon";
import UpsertOfferControl from "components/offer/UpsertOfferControl";
import CloseIcon from "components/common/icons/CloseIcon";
import paths from "res/paths";

const OfferDetails: FC = () => {
    const {offerId, jobId, contractorId} = useParams()
    const navigate = useNavigate()
    const alert = useAlert()

    const [offer, setOffer] = useState<Offer>()
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const [crewMember, setCrewMember] = useState<CrewMember>()
    const [value, setValue] = React.useState(0)

    useEffect(() => {
        void async function fetchData() {
            if (offerId && jobId) {
                await Api.offers
                    .getOffer(offerId, jobId, contractorId)
                    .then(o => setOffer(o))
                    .catch(res => handleFieldErrors(res.data as ErrorResponse))
            }

            setLoading(false)
        }()
    }, [offerId, jobId, contractorId])

    const upsertMember = (member: CrewMember) => {
        setOffer(ps => !ps ? undefined : {
            ...ps,
            crew: ps.crew.find(c => c.id === member.id) ?
                ps.crew.map(c => c.id === member.id ? member : c) :
                [...ps.crew, member]
        })
    }

    const removeMember = async () => {
        await Api.offers
            .deleteCrewMember(crewMember!.id, offer!.id, offer!.jobId)
            .then(() => {
                setOffer(ps => !ps ? undefined : {
                    ...ps,
                    crew: ps.crew.filter(c => c.id !== crewMember!.id)
                })
                onClose()
            })
            .catch(res => handleFieldErrors(res.data as ErrorResponse))
    }

    const setMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const closeMenu = () => {
        setAnchorEl(null)
    }

    const onClose = () => {
        setOpen(false)
        setDeleteOpen(false)
        setCrewMember(undefined)
    }

    const sendToContractor = async () => {
        await Api.offers
            .sendToContractor(offer!.id, offer!.jobId, offer!.contractorId)
            .then(() => {
                alert.enqueue({
                    message: `Offer sent to contractor successfully!!`,
                    severity: "success"
                })
                setOffer(ps => ({...ps!, status: "pending"}))
            })
            .catch(res => handleFieldErrors(res.data as ErrorResponse))
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    const completeJob = async () => {
        await Api.jobs.updateStatus(offer!.job.id, offer!.job.companyId, "complete")
            .then(() => {
                alert.enqueue({
                    message: `Assignment completed!`,
                    severity: "success"
                })

                setOffer(ps => ps && ({...ps, status: "completed"}))
            })
            .catch(res => handleFieldErrors(res.data as ErrorResponse))
    }

    const acceptOffer = async () => {
        await Api.offers
            .updateStatus(offer!.id, offer!.jobId, "accept", undefined, offer!.contractorId)
            .then(() => {
                alert.enqueue({
                    message: `Offer accepted!`,
                    severity: "success"
                })
                setOffer(ps => ps ? {...ps, status: "accepted"} : undefined)
            })
            .catch(res => handleFieldErrors(res.data as ErrorResponse))
    }

    if (loading) {
        return (<Loading/>)
    } else if (!offer) {
        return (<DataNotFound/>)
    }

    const modificationAllowed = offer.status === "draft" || offer.status === "rejected"

    return (<>
        <HtmlMetaDecorator title={offer.name}/>

        <VerticalFlexLayout
            height={"100%"}
            wrap={"nowrap"}>
            <Box p={2} sx={{backgroundColor: "white"}}>
                <VerticalFlexLayout spacing={2}>
                    <Breadcrumbs
                        separator=">"
                        sx={{fontSize: "small"}}>
                        <Link to={paths.adminAssignments}>
                            <Body2>
                                {strings.assignments}
                            </Body2>
                        </Link>
                        <Link to={paths.adminJobDetails(offer.job.companyId, offer.jobId)}>
                            <Body2>
                                {strings.assignmentDetails}
                            </Body2>
                        </Link>
                        <Link to={paths.adminJobDetails(offer.job.companyId, offer.jobId, "#offers")}>
                            <Body2>
                                {strings.offers}
                            </Body2>
                        </Link>
                        <Body2>
                            {strings.offerDetails}
                        </Body2>
                    </Breadcrumbs>
                    <HorizontalFlexLayout spacing={2}>
                        <GridItem sx={{flexGrow: 1}}>
                            <Heading3>{offer.name}</Heading3>
                        </GridItem>
                        {["pending", "rejected"].includes(offer.status) &&
                        <Button
                            onClick={acceptOffer}
                            variant={"contained"}>
                            {strings.approve}
                        </Button>}
                        {offer.status === "active" &&
                        <Button
                            variant={"contained"}
                            onClick={completeJob}>
                            Mark as Completed
                        </Button>}
                        {modificationAllowed &&
                        <IconButton
                            color={"secondary"}
                            variant={"outlined"}
                            onClick={() => setEditOpen(true)}>
                            <EditIcon/>
                        </IconButton>}
                        {modificationAllowed &&
                        <Button
                            onClick={sendToContractor}
                            variant={"contained"}>
                            {strings.SendToContractor}
                        </Button>}
                        <IconButton onClick={setMenu}>
                            <MoreIcon/>
                        </IconButton>
                    </HorizontalFlexLayout>
                    <Status value={offer.status}/>
                    {Boolean(offer.status === "rejected" && offer.rejectionReason) &&
                    <Body2 color={colors.error}>
                        {offer.rejectionReason}
                    </Body2>}
                </VerticalFlexLayout>
            </Box>
            <Box bgcolor={"white"} px={2}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label={strings.details} sx={{padding: 2}}/>
                    <Tab label={strings.messagesHistory}/>
                </Tabs>
            </Box>
            {value === 0 &&
            <GridItem sx={{flexGrow: 1, backgroundColor: "#F9F9F9"}}>
                <Box p={2}>
                    <VerticalFlexLayout spacing={4}>
                        <HorizontalFlexLayout spacing={4}>
                            <GridItem>
                                <Heading6 color={colors.disabledText}>
                                    {strings.overallBudget}
                                </Heading6>
                                <Heading4>
                                    {moneyString(offer.budget)}
                                </Heading4>
                            </GridItem>
                            <GridItem>
                                <Heading6 color={colors.disabledText}>
                                    {strings.deadline}
                                </Heading6>
                                <Heading4>
                                    {toShortDate(offer.job.deadline)}
                                </Heading4>
                            </GridItem>
                        </HorizontalFlexLayout>
                        <HorizontalFlexLayout spacing={2}>
                            <GridItem xs={12} md={4} lg={3}>
                                <Paper variant={"outlined"}>
                                    <Box p={2}>
                                        <VerticalFlexLayout spacing={2}>
                                            <Heading5>
                                                {strings.projectDetails}
                                            </Heading5>
                                            <DisplayField
                                                size={"h6"}
                                                title={strings.projectType}
                                                content={offer.job.type ?? "-"}/>
                                            <DisplayField
                                                size={"h6"}
                                                title={strings.location}
                                                content={locationString(offer.job.location)}/>
                                        </VerticalFlexLayout>
                                    </Box>
                                </Paper>
                            </GridItem>
                            <GridItem xs={12} md={8} lg={9}>
                                <Paper
                                    variant={"outlined"}
                                    sx={{height: "100%"}}>
                                    <Box p={2}>
                                        <DisplayField
                                            title={strings.notes}
                                            content={offer.notes ?? "-"}/>
                                    </Box>
                                </Paper>
                            </GridItem>
                            <GridItem xs={12}>
                                <HorizontalFlexLayout
                                    spacing={2}
                                    alignItems={"center"}
                                    wrap={"nowrap"}>
                                    <GridItem grow={1}>
                                        <Heading6 color={colors.disabledText}>
                                            {strings.crewMembers}
                                        </Heading6>
                                    </GridItem>
                                    {modificationAllowed &&
                                    <IconButton
                                        variant={"contained"}
                                        onClick={() => setOpen(true)}>
                                        <AddPersonIcon/>
                                    </IconButton>}
                                </HorizontalFlexLayout>

                            </GridItem>
                            {offer.crew.length ? offer.crew.map(c => (
                                    <GridItem key={c.id} xs={12} md={6} lg={4}>
                                        <CrewMemberDetailsCard
                                            crewMember={c}
                                            onAction={!modificationAllowed ? undefined :
                                                (a, c) => {
                                                    setCrewMember(c)
                                                    if (a === "edit") {
                                                        setOpen(true)
                                                    } else {
                                                        setDeleteOpen(true)
                                                    }
                                                }}/>
                                    </GridItem>
                                ))
                                :
                                <GridItem xs={12}>
                                    <Heading4 align={"center"}>
                                        {strings.agentNoCewMembersTitle}
                                    </Heading4>
                                    <Body1 align={"center"}>
                                        {strings.agentNoCewMembersMessage}
                                    </Body1>
                                </GridItem>}
                        </HorizontalFlexLayout>
                    </VerticalFlexLayout>
                </Box>
            </GridItem>}
            {value === 1 &&
            <GridItem height={"100%"} zIndex={2}>
                <MessagingControl
                    kind={"offer"}
                    offerId={offer.id}
                    jobId={offer.jobId}
                    userId={offer.contractorId}
                    contractorId={offer.contractorId}/>
            </GridItem>}
        </VerticalFlexLayout>

        <Menu
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}>
            <MenuItem
                onClick={() => {
                    navigate("/console/contractors/" + offer?.contractorId)
                    closeMenu()
                }}>
                {strings.contractorProfile}
            </MenuItem>
        </Menu>

        <Dialog
            open={editOpen}
            onClose={() => setEditOpen(false)}
            fullWidth
            maxWidth={"md"}>
            <DialogActions sx={{padding: 2}}>
                <HorizontalFlexLayout spacing={2} wrap={"nowrap"}>
                    <GridItem sx={{flexGrow: 1}}>
                        <Heading4>{offer.name}</Heading4>
                    </GridItem>
                    <IconButton
                        onClick={() => setEditOpen(false)}>
                        <CloseIcon/>
                    </IconButton>
                </HorizontalFlexLayout>
            </DialogActions>
            <DialogContent dividers>
                <UpsertOfferControl
                    offer={offer}
                    onComplete={o => {
                        setOffer(o)
                        setEditOpen(false)
                    }}/>
            </DialogContent>
        </Dialog>

        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth={"sm"}>
            <DialogContent>
                <UpsertCrewMemberControl
                    offer={offer!}
                    crewMember={crewMember}
                    onSuccess={c => {
                        upsertMember(c)
                        setCrewMember(undefined)
                        setOpen(false)
                    }}
                    onCancel={() => {
                        setOpen(false)
                        setCrewMember(undefined)
                    }}/>
            </DialogContent>
        </Dialog>

        <Dialog
            open={deleteOpen}
            fullWidth
            maxWidth={"xs"}
            onClose={onClose}>
            <DialogContent>
                <VerticalFlexLayout spacing={4}>
                    <Heading4>
                        {strings.removeCrewMemberTitle}
                    </Heading4>
                    <Body1>
                        {strings.removeCrewMemberMessage}
                    </Body1>
                    <HorizontalFlexLayout spacing={2}>
                        <GridItem xs={6}>
                            <Button
                                fullWidth
                                onClick={onClose}
                                variant={"outlined"}
                                color={"secondary"}>
                                {strings.cancel}
                            </Button>
                        </GridItem>
                        <GridItem xs={6}>
                            <Button
                                fullWidth
                                onClick={removeMember}
                                variant={"contained"}
                                color={"error"}>
                                {strings.remove}
                            </Button>
                        </GridItem>
                    </HorizontalFlexLayout>
                </VerticalFlexLayout>
            </DialogContent>
        </Dialog>
    </>)
}

export default OfferDetails
