import React, {FC, useState} from "react"
import {Dialog, DialogContent} from "@mui/material"
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout"
import {Heading4} from "components/common/Typography";
import InputField from "components/common/InputField";
import Button from "components/common/Button";
import strings from "res/strings";
import {useForm, FormProvider, Controller} from "react-hook-form";

interface Props {
    open: boolean
    onReject: (reason: string) => Promise<void>
    onCancel: () => void
}

const RejectionReasonDialog: FC<Props> = (props) => {
    const [loading, setLoading] = useState(false)
    const form = useForm<{ reason: string }>()

    const onSubmit = form.handleSubmit(async fields => {
        if (loading) {
            return
        }

        form.clearErrors()
        setLoading(true)
        await props.onReject(fields.reason)
        setLoading(false)
    })

    const toggleOpen = () => {
        props.onCancel()
    }

    return (
        <Dialog
            open={props.open}
            fullWidth
            maxWidth={"xs"}
            onClose={toggleOpen}>
            <DialogContent>
                <FormProvider {...form}>
                    <form onSubmit={onSubmit}>
                        <VerticalFlexLayout spacing={3}>
                            <Heading4>
                                {strings.rejectionDialogTitle}
                            </Heading4>
                            <Controller
                                name="reason"
                                control={form.control}
                                render={({field, fieldState: {error}}) => (
                                    <InputField
                                        placeholder={strings.rejectionReasonPlaceholder}
                                        multiline
                                        required
                                        fieldError={error}
                                        {...field} />
                                )}/>
                            <HorizontalFlexLayout spacing={2}>
                                <GridItem xs={6}>
                                    <Button
                                        fullWidth
                                        onClick={toggleOpen}
                                        variant={"outlined"}
                                        color={"secondary"}>
                                        {strings.cancel}
                                    </Button>
                                </GridItem>
                                <GridItem xs={6}>
                                    <Button
                                        type={"submit"}
                                        fullWidth
                                        variant={"contained"}
                                        color={"error"}
                                        isBusy={loading}>
                                        {strings.reject}
                                    </Button>
                                </GridItem>
                            </HorizontalFlexLayout>
                        </VerticalFlexLayout>
                    </form>
                </FormProvider>
            </DialogContent>
        </Dialog>
    )
}

export default RejectionReasonDialog
