import React, {FC} from "react"
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator"
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import {Box} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {Heading3} from "components/common/Typography";
import Offer from "models/Offer";
import strings from "res/strings";
import OffersList from "components/offer/OffersList";

const Offers: FC = () => {
    const navigate = useNavigate()

    const onClick = (o: Offer) => {
        navigate(`/contractor/offers/${(o as unknown as Offer).jobId}/${o.id}`)
    }

    return (<>
        <HtmlMetaDecorator title={strings.myAssignments}/>

        <VerticalFlexLayout
            height={"100%"}
            wrap={"nowrap"}>
            <Box p={2} sx={{backgroundColor: "white"}}>
                <HorizontalFlexLayout spacing={2}>
                    <GridItem grow={1}>
                        <Heading3>
                            {strings.myAssignments}
                        </Heading3>
                    </GridItem>
                </HorizontalFlexLayout>
            </Box>
            <GridItem grow={1}>
                <OffersList
                    onClick={onClick}/>
            </GridItem>
        </VerticalFlexLayout>
    </>)
}

export default Offers
