import React from "react";
import IconProps from "components/common/icons/IconProps";

const StarIcon: React.FC<IconProps> = (props) => (
    <div className={"svg-container"}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size ?? "24"}
            height={props.size ?? "24"}
            viewBox="0 0 24 24">
            <path
                d="M11.9998 18.379L5.82677 21.624L7.00577 14.75L2.00977 9.882L8.91177 8.879L11.9998 2.624L15.0868 8.879L21.9888 9.882L16.9938 14.75L18.1738 21.624L11.9998 18.379Z"
                fill={props.color ?? "black"}/>
        </svg>
    </div>
)

export default StarIcon
