import React from 'react'
import './App.css'
import Router from "routers/Router"
import {createTheme, Theme, ThemeProvider} from "@mui/material"
import colors from "res/colors"
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator"
import AuthProvider from "contexts/AuthContext";
import MessagingProvider from "contexts/MessagingContext";
import LiveChat from "components/common/LiveChat";
import AlertProvider from "contexts/AlertContext";

const theme: Theme = createTheme({
    palette: {
        primary: {
            main: colors.primary,
        },
        secondary: {
            main: colors.secondary,
        },
        error: {
            main: colors.error
        }
    },
    typography: {
        fontFamily: "Heebo",
        h1: {
            fontFamily: 'Diodrum Cyrillic',
            fontWeight: "700",
            fontStyle: "normal",
            lineHeight: "58px",
            textTransform: "capitalize",
            color: colors.text,
            fontSize: 45
        },
        h2: {
            fontFamily: 'Diodrum Cyrillic',
            fontWeight: "700",
            fontStyle: "normal",
            lineHeight: "44px",
            textTransform: "capitalize",
            color: colors.text,
            fontSize: 36
        },
        h3: {
            fontFamily: 'Diodrum Cyrillic',
            fontWeight: "600",
            fontStyle: "normal",
            lineHeight: "40px",
            color: colors.text,
            fontSize: 30
        },
        h4: {
            fontFamily: 'Diodrum Cyrillic',
            fontWeight: "600",
            fontStyle: "normal",
            lineHeight: "34px",
            color: colors.text,
            fontSize: 26
        },
        h5: {
            fontFamily: 'Diodrum Cyrillic',
            fontWeight: "500",
            fontStyle: "normal",
            lineHeight: "28px",
            color: colors.text,
            fontSize: 22
        },
        h6: {
            fontFamily: 'Heebo',
            fontWeight: "500",
            fontStyle: "normal",
            lineHeight: "28px",
            color: colors.text,
            fontSize: 16
        },
        body1: {
            fontFamily: 'Heebo',
            fontWeight: "400",
            fontStyle: "normal",
            lineHeight: "28px",
            color: colors.text,
            fontSize: 18
        },
        body2: {
            fontFamily: 'Heebo',
            fontWeight: "300",
            fontStyle: "normal",
            lineHeight: "24px",
            color: colors.text,
            fontSize: 15
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.02)",
                    backgroundColor: "white",
                    // zIndex: theme.zIndex.drawer + 1,
                    borderRadius: 0
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontFamily: 'Heebo',
                    fontWeight: "400",
                    fontStyle: "normal",
                    lineHeight: "28px",
                    color: colors.copy,
                    backgroundColor: "white",
                    fontSize: 16,
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    padding: "8px 10px"
                },
                multiline: {
                    padding: 0,
                    minHeight: 80,
                    alignItems: "start",
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    lineHeight: "23px",
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    boxShadow: "none"
                }
            }
        }
    }
})

const setGoogleAnalytics = () => {
    // @ts-ignore
    window.dataLayer = window.dataLayer || []

    function gtag(key: string, value: any) {
        // @ts-ignore
        dataLayer.push(arguments)
    }

    gtag('js', new Date())
    gtag('config', 'G-C8GYV5SP5K');
}

function App() {
    setGoogleAnalytics()

    return (<>
        <HtmlMetaDecorator>
            <script async src={"https://www.googletagmanager.com/gtag/js?id=G-C8GYV5SP5K"}/>
        </HtmlMetaDecorator>

        <ThemeProvider theme={{...theme}}>
            <AlertProvider>
                <AuthProvider>
                    <MessagingProvider>
                        <LiveChat>
                            <Router/>
                        </LiveChat>
                    </MessagingProvider>
                </AuthProvider>
            </AlertProvider>
        </ThemeProvider>
    </>)
}

export default App
