import React, {FC, useEffect, useState} from "react"
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator"
import UpsertJobControl from "components/job/UpsertJobControl";
import {useNavigate, useParams} from "react-router-dom";
import Job from "models/Job";
import Api from "data/remote/Api";
import {handleFieldErrors} from "util/Utilities";
import ErrorResponse from "data/remote/models/ErrorResponse";
import Loading from "components/common/Loading";
import NotFound from "pages/NotFound";
import strings from "res/strings";
import {useAlert} from "contexts/AlertContext";
import {GridItem, VerticalFlexLayout} from "components/common/FlexLayout";

const UpsertJob: FC = () => {
    const {id, companyId} = useParams()
    const navigation = useNavigate()
    const alert = useAlert()

    const [loading, setLoading] = useState(Boolean(id))
    const [job, setJob] = useState<Job>()

    useEffect(() => {
        void async function fetchData() {
            if (id) {
                await Api.jobs.getJobAsync(id, companyId)
                    .then(j => setJob(j as Job))
                    .catch(res => handleFieldErrors(res.data as ErrorResponse))
            }

            setLoading(false)
        }()
    }, [id, companyId])

    const onSuccess = (job: Job) => {
        alert.enqueue({
            message: job.status === "draft" ? "Assignment saved successfully" :
                "Assignment created successfully",
            severity: "success"
        })

        navigation(`/console/assignments/${companyId}/${job.id}`)
    }

    if (loading) {
        return (<Loading/>)
    } else if (id && !job) {
        return (<NotFound/>)
    }

    return (<>
        <HtmlMetaDecorator title={strings.startAProject}/>

        <VerticalFlexLayout
            height={"100%"}
            wrap={"nowrap"}>
            <GridItem sx={{
                backgroundColor: "#F9F9F9",
                flexGrow: 1
            }}>
                <UpsertJobControl
                    isAdmin
                    job={job}
                    companyId={companyId}
                    onSuccess={onSuccess}/>
            </GridItem>
        </VerticalFlexLayout>
    </>)
}

export default UpsertJob
