import React, {FC} from "react"
import {GridItem, HorizontalFlexLayout} from "components/common/FlexLayout";
import InputField from "components/common/InputField";
import {Controller, useFormContext} from "react-hook-form";
import strings from "res/strings";
import Location from "models/Location";
import LookupFields from "components/common/LookupFields";
import countries from "res/countries";

interface Props {
    withLabel?: boolean
}

const LocationField: FC<Props> = (props) => {
    const {control} = useFormContext<{ location: Location }>()

    return (
        <HorizontalFlexLayout
            spacing={2}>
            <GridItem xs={12} sm={6}>
                <Controller
                    name="location.city"
                    control={control}
                    render={({field, fieldState: {error}}) => (
                        <InputField
                            label={props.withLabel ? strings.city : undefined}
                            placeholder={props.withLabel ? undefined : strings.city}
                            required
                            fieldError={error}
                            {...field} />
                    )}/>
            </GridItem>
            <GridItem xs={12} sm={6}>
                <Controller
                    name="location.country"
                    control={control}
                    render={({field, fieldState: {error}}) => (
                        <LookupFields
                            label={props.withLabel ? strings.country : undefined}
                            items={countries}
                            required
                            fieldError={error}
                            {...field}/>
                    )}/>
            </GridItem>
        </HorizontalFlexLayout>
    )
}

export default LocationField
