import React, {FC} from "react"
import {Body2, Heading5, Heading6} from "components/common/Typography";
import colors from "res/colors";

interface Props {
    title: string
    content?: string
    size?: "h5" | "h6"
}

const DisplayField: FC<Props> = (props) => {
    return (<>
        {props.size === "h6" ?
            <Heading6>
                {props.title}
            </Heading6>
            :
            <Heading5>
                {props.title}
            </Heading5>
        }
        {Boolean(props.content) &&
        <Body2
            color={colors.copy}
            sx={{
                paddingTop: 1,
                whiteSpace: "pre-wrap"
            }}>
            {props.content}
        </Body2>}
    </>)
}

export default DisplayField
