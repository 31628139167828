import React, {FC, useEffect, useState} from "react"
import {Box, Dialog, DialogContent, DialogTitle, Paper} from "@mui/material"
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout"
import {Body2, Heading3, Heading4} from "components/common/Typography";
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator";
import colors from "res/colors";
import Button from "components/common/Button";
import IconButton from "components/common/IconButton";
import CloseIcon from "components/common/icons/CloseIcon";
import UpsertUserControl from "components/user/UpsertUserControl";
import {handleFieldErrors, toShortDate} from "util/Utilities";
import Api from "data/remote/Api";
import ErrorResponse from "data/remote/models/ErrorResponse";
import CompanyUser from "models/CompanyUser";

const Users: FC = () => {
    const [users, setUsers] = useState<CompanyUser[]>([])
    const [open, setOpen] = useState(false)

    useEffect(() => {
        void async function fetchData() {
            await Api.users
                .getAgents()
                .then(data => {
                    if (data) {
                        setUsers(data.items)
                    }
                })
                .catch(res => handleFieldErrors(res.data as ErrorResponse))
        }()
    }, [])

    return (<>
            <HtmlMetaDecorator
                title={"Agents"}/>

            <VerticalFlexLayout
                height={"100%"}
                wrap={"nowrap"}>
                <Box p={2} bgcolor={"white"}>
                    <HorizontalFlexLayout spacing={2}>
                        <GridItem grow={1}>
                            <Heading3>Agents</Heading3>
                        </GridItem>
                        <Button
                            variant={"contained"}
                            onClick={() => setOpen(true)}>
                            Create Agents
                        </Button>
                    </HorizontalFlexLayout>
                </Box>
                <GridItem sx={{backgroundColor: "#F9F9F9", flexGrow: 1}}>
                    <Box p={4}>
                        <HorizontalFlexLayout spacing={2}>
                            {users?.map(c => (
                                <GridItem key={c.id} xs={12} md={6}>
                                    <Paper variant={"outlined"}>
                                        <Box p={2}>
                                            <VerticalFlexLayout spacing={1}>
                                                <Heading4>{c.name}</Heading4>
                                                <HorizontalFlexLayout
                                                    spacing={2}
                                                    justify={"space-between"}>
                                                    <Body2 color={colors.disabledText}>
                                                        {c.email}
                                                    </Body2>
                                                    <Body2 color={colors.disabledText}>
                                                        Since: {toShortDate(c.created)}
                                                    </Body2>
                                                </HorizontalFlexLayout>
                                            </VerticalFlexLayout>
                                        </Box>
                                    </Paper>
                                </GridItem>
                            ))}
                        </HorizontalFlexLayout>
                    </Box>
                </GridItem>
            </VerticalFlexLayout>

            <Dialog
                open={open}
                fullWidth
                maxWidth={"sm"}>
                <DialogTitle>
                    <HorizontalFlexLayout wrap={"nowrap"}>
                        <GridItem sx={{flexGrow: 1}}>
                            <Heading4>Create Agent</Heading4>
                        </GridItem>
                        <IconButton
                            onClick={() => setOpen(false)}>
                            <CloseIcon/>
                        </IconButton>
                    </HorizontalFlexLayout>
                </DialogTitle>
                <DialogContent dividers>
                    <UpsertUserControl
                        role={"agent"}
                        onSuccess={() => setOpen(false)}/>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Users
