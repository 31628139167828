import React, {ReactElement} from "react"
import {Body2} from "components/common/Typography"
import colors from "res/colors"
import {FlexLayout, HorizontalFlexLayout} from "components/common/FlexLayout"
import {Box} from "@mui/material";

interface BaseProps {
    // TODO: Restrict it to SVG components only.
    icon?: ReactElement
    iconPosition?: 'start' | 'end'
    onClick?: (value: string) => void
}

interface TagsProps extends BaseProps {
    label?: string
    items: string[]
}

interface TagProps extends BaseProps {
    children: string
}

export const Tag: React.FC<TagProps> = (props) => {
    function onClick() {
        if (props.onClick) {
            props.onClick(props.children)
        }
    }

    const dir = props.iconPosition === "end" ? 'row-reverse' : 'row'
    const style = {
        borderRadius: "4px",
        backgroundColor: "#F4F4F4",
        padding: "2px 8px",
        cursor: props.onClick ? "pointer" : "default"
    }

    return (
        <Box onClick={onClick} sx={style}>
            <FlexLayout spacing={1} alignItems={"center"} direction={dir}>
                {props.icon && props.icon}
                <Body2 sx={{fontWeight: 500}}>
                    {props.children}
                </Body2>
            </FlexLayout>
        </Box>
    )
}

export const Tags: React.FC<TagsProps> = (props) => (
    <HorizontalFlexLayout spacing={1} alignItems={"center"}>
        {props.label &&
        <Body2 color={colors.disabledText}>
            {`${props.label}:`}
        </Body2>}
        {Boolean(props.items?.length) &&
        props.items.map(i => (<Tag key={i} {...props}>{i}</Tag>))}
    </HorizontalFlexLayout>
)
