import React, {FC} from "react";
import {Box, Container} from "@mui/material";
import colors from "res/colors";
import {HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import LinkedInIcon from "components/common/icons/LinkedInIcon";
import InstagramIcon from "components/common/icons/InstagramIcon";
import FacebookIcon from "components/common/icons/FacebookIcon";
import TwitterIcon from "components/common/icons/TwitterIcon";
import {Link} from "react-router-dom";
import paths from "res/paths";
import {Body1, Body2} from "components/common/Typography";
import strings from "res/strings";

const Footer: FC = () => {
    return (
        <footer>
            <Box py={4} sx={{backgroundColor: colors.secondary}}>
                <Container maxWidth={"xl"}>
                    <VerticalFlexLayout spacing={4}>
                        <HorizontalFlexLayout
                            spacing={3}
                            justify={"center"}
                            wrap={"nowrap"}>
                            <a rel="noreferrer" target={"_blank"}
                               href={"https://www.linkedin.com/company/taomedia/"}>
                                <LinkedInIcon color={"#fff"} size={32}/>
                            </a>
                            <a rel="noreferrer" target={"_blank"}
                               href={"https://www.instagram.com/taomedialtd/"}>
                                <InstagramIcon color={"#fff"} size={32}/>
                            </a>
                            <a rel="noreferrer" target={"_blank"}
                               href={"https://www.facebook.com/taomedialtd"}>
                                <FacebookIcon color={"#fff"} size={32}/>
                            </a>
                            <a rel="noreferrer" target={"_blank"}
                               href={"https://twitter.com/TAOMediaLtd"}>
                                <TwitterIcon color={"#fff"} size={32}/>
                            </a>
                        </HorizontalFlexLayout>
                        <HorizontalFlexLayout
                            spacing={4}
                            justify={"center"}>
                            <Link to={paths.termsConditions}>
                                <Body1 color={"white"}>
                                    {strings.termsConditions}
                                </Body1>
                            </Link>
                            <Link to={paths.privacyPolicy}>
                                <Body1 color={"white"}>
                                    {strings.privacyPolicy}
                                </Body1>
                            </Link>
                        </HorizontalFlexLayout>
                        <Body2
                            align={"center"}
                            color={"white"}>
                            Copyright © 2022 TAO Media Ltd. All Rights Reserved.
                        </Body2>
                    </VerticalFlexLayout>
                </Container>
            </Box>
        </footer>
    )
}

export default Footer
