import React, {FC, useState} from "react"
import {Link} from "react-router-dom"
import {GridItem, VerticalFlexLayout} from "components/common/FlexLayout"
import {Controller, useForm} from "react-hook-form"
import Button from "components/common/Button"
import strings from "res/strings"
import paths from "res/paths";
import Api from "data/remote/Api";
import InputField from "components/common/InputField";
import ConfirmSignUpControl from "components/account/ConfirmSignUpControl";
import ErrorResponse from "data/remote/models/ErrorResponse";
import {handleFieldErrors} from "util/Utilities";
import {useAuth} from "contexts/AuthContext";
import {useAlert} from "contexts/AlertContext";
import PasswordValidation, {PasswordValidationStatus} from "components/account/PasswordValidation";
import {Body1} from "components/common/Typography";

interface Fields {
    username: string
    password: string
    newPassword?: string
    confirmPassword?: string
    newPasswordRequired?: boolean
}

const SignInControl: FC = () => {
    const {update} = useAuth()
    const alert = useAlert()

    const [isLoading, setIsLoading] = useState(false)
    const [credentials, setCredentials] = useState<Fields>()
    const [validation, setValidation] = useState<PasswordValidationStatus>('invalid')

    const {control, handleSubmit, watch, clearErrors, setError} = useForm<Fields>()

    const onSubmit = handleSubmit(async fields => {
        if (credentials?.newPasswordRequired) {
            if (validation === "invalid") {
                setError("newPassword", {
                    message: strings.invalidPasswordMessage
                })
                return
            } else if (validation === "mismatch") {
                setError("confirmPassword", {
                    message: strings.passwordMismatchMessage
                })
                return
            }
        }

        setIsLoading(true)
        clearErrors()

        await Api.auth.signInAsync(fields.username, fields.password, fields.newPassword)
            .then(() => update())
            .catch(res => {
                const error = res.data as ErrorResponse

                if (error.code === "user_not_confirmed") {
                    setCredentials(fields)
                } else if (error.code === "incorrect_credentials") {
                    alert.enqueue({
                        message: error.message,
                        severity: "error"
                    })
                } else if (error.errors?.find(e => e.field === "newPassword")) {
                    fields.newPasswordRequired = true
                    setCredentials(fields)
                } else {
                    handleFieldErrors(res.data as ErrorResponse, setError)
                }

                setIsLoading(false)
            })
    })

    if (!credentials || credentials.newPasswordRequired) {
        return (
            <form onSubmit={onSubmit}>
                <VerticalFlexLayout spacing={3}>
                    <GridItem hidden={credentials?.newPasswordRequired}>
                        <Controller
                            name="username"
                            control={control}
                            render={({field, fieldState: {error}}) => (
                                <InputField
                                    label={strings.emailAddress}
                                    type="email"
                                    required
                                    autoFocus
                                    fieldError={error}
                                    {...field} />
                            )}/>
                    </GridItem>

                    <GridItem hidden={credentials?.newPasswordRequired}>
                        <Controller
                            name="password"
                            control={control}
                            render={({field, fieldState: {error}}) => (
                                <InputField
                                    label={strings.password}
                                    type="password"
                                    required
                                    fieldError={error}
                                    {...field} />
                            )}/>
                    </GridItem>

                    <GridItem hidden={credentials?.newPasswordRequired}>
                        <Link to={paths.forgotPassword}>
                            {strings.forgotPassword}
                        </Link>
                    </GridItem>

                    {credentials?.newPasswordRequired && [
                        <Body1>
                            {"You need to update your password because this is the first time you are signing in."}
                        </Body1>,
                        <Controller
                            name="newPassword"
                            control={control}
                            render={({field, fieldState: {error}}) => (
                                <InputField
                                    label={strings.newPassword}
                                    type="password"
                                    required
                                    fieldError={error}
                                    {...field} />
                            )}/>,
                        <Controller
                            name="confirmPassword"
                            control={control}
                            render={({field, fieldState: {error}}) => (
                                <InputField
                                    label={strings.confirmPassword}
                                    type="password"
                                    required
                                    fieldError={error}
                                    {...field} />
                            )}/>,
                        <PasswordValidation
                            password={watch("newPassword")}
                            confirm={watch("confirmPassword")}
                            onChange={s => setValidation(s)}/>
                    ]}


                    <Button
                        type="submit"
                        fullWidth
                        size={"large"}
                        isBusy={isLoading}
                        variant="contained"
                        color="primary">
                        {strings.signIn}
                    </Button>
                </VerticalFlexLayout>
            </form>
        )
    } else {
        return (
            <ConfirmSignUpControl {...credentials}/>
        )
    }
}

export default SignInControl
