import React, {FC, SyntheticEvent, useState} from "react"
import {VerticalFlexLayout} from "components/common/FlexLayout"
import {Controller, useForm} from "react-hook-form"
import Button from "components/common/Button"
import strings from "res/strings"
import Api from "data/remote/Api";
import InputField from "components/common/InputField";
import ConfirmSignUpControl from "components/account/ConfirmSignUpControl";
import PasswordValidation, {PasswordValidationStatus} from "components/account/PasswordValidation";
import ErrorResponse from "data/remote/models/ErrorResponse";
import {handleFieldErrors} from "util/Utilities";
import {Tab, Tabs} from "@mui/material";
import {Body2} from "components/common/Typography";
import colors from "res/colors";
import PersonIcon from "components/common/icons/PersonIcon";
import CompanyIcon from "components/common/icons/CompanyIcon";
import paths from "res/paths";
import {Link, useSearchParams} from "react-router-dom";

type Role = "contractor" | "company"

interface Fields {
    email: string
    password: string
    confirmPassword: string
    attributes: {
        "custom:company-name": string
        given_name: string
        family_name: string
    }
}

const SignUpControl: FC = () => {
    const [params] = useSearchParams()
    let roleParam = params.get("role") as Role
    if (!["contractor", "company"].includes(roleParam)) {
        roleParam = "company"
    }

    const [isLoading, setIsLoading] = useState(false)
    const [username, setUsername] = useState<string>()
    const [validation, setValidation] = useState<PasswordValidationStatus>('invalid')
    const [role, setRole] = useState<Role>(roleParam)
    const {control, handleSubmit, watch, clearErrors, setError} = useForm<Fields>()

    const onSubmit = handleSubmit(async fields => {
        if (validation === "invalid") {
            setError("password", {
                message: strings.invalidPasswordMessage
            })
            return
        } else if (validation === "mismatch") {
            setError("confirmPassword", {
                message: strings.passwordMismatchMessage
            })
            return
        }

        setIsLoading(true)
        clearErrors()

        // TODO: Send the name and other details too.
        await Api.auth
            .signUpAsync(
                role,
                fields.email,
                fields.password,
                fields.attributes,
                fields.attributes["custom:company-name"]
            )
            .then(() => {
                setUsername(fields.email)
            })
            .catch(res => {
                handleFieldErrors(res.data as ErrorResponse, setError)
            })

        setIsLoading(false)
    })

    const handleChange = (event: SyntheticEvent, newValue: Role) =>
        setRole(newValue)

    if (!username) {
        return (
            <form onSubmit={onSubmit}>
                <VerticalFlexLayout spacing={3}>
                    <Tabs
                        value={role}
                        onChange={handleChange}
                        variant={"fullWidth"}>
                        <Tab
                            label="Client"
                            value={"company"}
                            icon={<CompanyIcon color={role === "company" ? colors.primary : undefined}/>}
                            iconPosition={"start"}/>
                        <Tab
                            label="Talent"
                            value={"contractor"}
                            icon={<PersonIcon color={role === "contractor" ? colors.primary : undefined}/>}
                            iconPosition={"start"}/>
                    </Tabs>

                    <Body2 color={colors.copy}>
                        {role === "company" ? "Find the best talents and complete your project!"
                            : "Get access to new projects and clients!"}
                    </Body2>

                    <Controller
                        name="attributes.given_name"
                        control={control}
                        render={({field, fieldState: {error}}) => (
                            <InputField
                                label={strings.firstName}
                                required
                                autoFocus
                                fieldError={error}
                                {...field} />
                        )}/>

                    <Controller
                        name="attributes.family_name"
                        control={control}
                        render={({field, fieldState: {error}}) => (
                            <InputField
                                label={strings.lastName}
                                required
                                fieldError={error}
                                {...field} />
                        )}/>

                    <Controller
                        name="attributes.custom:company-name"
                        control={control}
                        render={({field, fieldState: {error}}) => (
                            <InputField
                                label={role === "company" ? strings.companyName : strings.employer}
                                required={role === "company"}
                                fieldError={error}
                                {...field} />
                        )}/>

                    <Controller
                        name="email"
                        control={control}
                        render={({field, fieldState: {error}}) => (
                            <InputField
                                label={strings.emailAddress}
                                type="email"
                                required
                                fieldError={error}
                                {...field} />
                        )}/>

                    <Controller
                        name="password"
                        control={control}
                        render={({field, fieldState: {error}}) => (
                            <InputField
                                label={strings.password}
                                type="password"
                                required
                                fieldError={error}
                                {...field} />
                        )}/>

                    <Controller
                        name="confirmPassword"
                        control={control}
                        render={({field, fieldState: {error}}) => (
                            <InputField
                                label={strings.confirmPassword}
                                type="password"
                                required
                                fieldError={error}
                                {...field} />
                        )}/>

                    <PasswordValidation
                        password={watch("password")}
                        confirm={watch("confirmPassword")}
                        onChange={s => setValidation(s)}/>

                    <Button
                        type="submit"
                        fullWidth
                        size={"large"}
                        isBusy={isLoading}
                        variant="contained"
                        color="primary">
                        {strings.signUp}
                    </Button>

                    <Body2
                        color={colors.copy}
                        align={"center"}>
                        {strings.alreadyUser}
                        <Link
                            to={paths.signIn}
                            style={{paddingLeft: "8px"}}>
                            {strings.signIn}
                        </Link>
                    </Body2>
                </VerticalFlexLayout>
            </form>
        )
    } else {
        return (
            <ConfirmSignUpControl
                username={username}
                password={watch("password")}/>
        )
    }
}

export default SignUpControl
