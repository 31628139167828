import React, {ElementType} from "react"
import {Button as MuiButton, CircularProgress} from "@mui/material"
import {ButtonProps} from "@mui/material/Button/Button"

interface Props extends ButtonProps {
    isBusy?: boolean
    component?: ElementType
}

const Button: React.FC<Props> = (props) => {
    const {isBusy, children, ...buttonProps} = props

    return (
        <MuiButton {...buttonProps}>
            {isBusy ? <CircularProgress size={24}/> : children}
        </MuiButton>
    )
}

export default Button
