import React, {FC, PropsWithChildren, useEffect} from 'react'
import {Routes, Route, useNavigate} from "react-router-dom"
import NotFound from "pages/NotFound"
import AppBarContainer from "components/common/AppBarContainer";
import {Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import CompanyIcon from "components/common/icons/CompanyIcon";
import PersonIcon from "components/common/icons/PersonIcon";
import TaskIcon from "components/common/icons/TaskIcon";
import strings from "res/strings";
import paths from "res/paths";
import Companies from "pages/admin/companies/Companies";
import Contractors from "pages/admin/contractors/Contractors";
import Jobs from "pages/admin/Jobs";
import JobDetails from "pages/admin/JobDetails";
import OfferDetails from "pages/admin/offers/OfferDetails";
import ProposalDetails from "pages/admin/proposals/ProposalDetails";
import UpsertJob from "pages/admin/jobs/UpsertJob";
import Profile from "pages/admin/contractors/Profile";
import Users from "pages/admin/Users";
import SettingsIcon from "components/common/icons/SettingsIcon";
import Api from "data/remote/Api";
import {handleFieldErrors} from "util/Utilities";
import ErrorResponse from "data/remote/models/ErrorResponse";
import CompanyUser from "models/CompanyUser";
import CommentIcon from "components/common/icons/CommentIcon";
import Messages from "pages/Messages";

const AdminRouterWrapper: FC<PropsWithChildren> = (props) => {
    let navigate = useNavigate()

    const list = (
        <Box>
            <List>
                <ListItem>
                    <ListItemButton onClick={() => navigate(paths.adminClients)}>
                        <ListItemIcon>
                            <CompanyIcon/>
                        </ListItemIcon>
                        <ListItemText primary={strings.clients}/>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton onClick={() => navigate(paths.adminContractors)}>
                        <ListItemIcon>
                            <PersonIcon/>
                        </ListItemIcon>
                        <ListItemText primary={strings.contractors}/>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton onClick={() => navigate(paths.adminAssignments)}>
                        <ListItemIcon>
                            <TaskIcon/>
                        </ListItemIcon>
                        <ListItemText primary={strings.assignments}/>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton onClick={() => navigate("/console/users")}>
                        <ListItemIcon>
                            <SettingsIcon/>
                        </ListItemIcon>
                        <ListItemText primary={"Agents"}/>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton onClick={() => navigate("/console/chats")}>
                        <ListItemIcon>
                            <CommentIcon/>
                        </ListItemIcon>
                        <ListItemText primary={"Chats"}/>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    )

    return (
        <AppBarContainer drawerList={list}>
            {props.children}
        </AppBarContainer>
    )
}

export let cachedCompanies: CompanyUser[] = []

const AdminRouter: FC = () => {
    useEffect(() => {
        void async function fetchData() {
            if (!cachedCompanies.length) {
                await Api.users
                    .getCompanies()
                    .then(data => {
                        if (data) {
                            cachedCompanies = data.items
                        }
                    })
                    .catch(res => handleFieldErrors(res.data as ErrorResponse))
            }
        }()
    }, [])

    return (
        <AdminRouterWrapper>
            <Routes>
                <Route path="users" element={<Users/>}/>
                <Route path="clients" element={<Companies/>}/>
                <Route path="assignments/:companyId/:jobId/proposal/:id" element={<ProposalDetails/>}/>
                <Route path="assignments/:companyId/:jobId/offer/:offerId/:contractorId" element={<OfferDetails/>}/>
                <Route path="assignments/:companyId/:id/edit" element={<UpsertJob/>}/>
                <Route path="assignments/:companyId/create" element={<UpsertJob/>}/>
                <Route path="assignments/:companyId/:id" element={<JobDetails/>}/>
                <Route path="assignments" element={<Jobs/>}/>
                <Route path="contractors/:id" element={<Profile/>}/>
                <Route path="contractors" element={<Contractors/>}/>
                <Route path="chats" element={<Messages/>}/>
                <Route path="" element={<Jobs/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </AdminRouterWrapper>
    )
}

export default AdminRouter
