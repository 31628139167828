import React, {FC, useState} from "react";
import {GridItem, HorizontalFlexLayout} from "components/common/FlexLayout";
import {Controller, FormProvider, useForm} from "react-hook-form";
import InputField from "components/common/InputField";
import strings from "res/strings";
import BudgetField from "components/common/BudgetField";
import Button from "components/common/Button";
import {handleFieldErrors, toDateInput} from "util/Utilities";
import Api from "data/remote/Api";
import ErrorResponse from "data/remote/models/ErrorResponse";
import Proposal from "models/Proposal";
import jobTypes from "res/jobTypes";
import LocationField from "components/common/LocationField";
import LookupFields from "components/common/LookupFields";

interface Props {
    proposal: Proposal
    onComplete: (proposal: Proposal) => void
}

const UpsertProposalControl: FC<Props> = (props) => {
    const [loading, setLoading] = useState(false)
    const form = useForm<Proposal>({
        defaultValues: {
            ...props.proposal,
            location: props.proposal.job?.location,
            deadline: toDateInput(props.proposal.job?.deadline),
            type: props.proposal.job?.type
        }
    })

    const onSubmitOffer = form.handleSubmit(async fields => {
        if (loading) {
            return
        }

        form.clearErrors()
        fields.job.deadline = new Date(fields.deadline).toISOString()

        if (fields.budget?.value) {
            fields.budget.value = Number.parseFloat(fields.budget.value.toString())
        } else {
            fields.budget.currency = ""
        }

        setLoading(true)

        if (fields.id) {
            await Api.proposals
                .updateProposalAsync(fields)
                .then(p => props.onComplete(p as Proposal))
                .catch(res => handleFieldErrors(res.data as ErrorResponse, form.setError))
        } else {
            // TODO: Handle creation here.
        }

        setLoading(false)
    })

    return (
        <FormProvider {...form}>
            <form onSubmit={onSubmitOffer}>
                <HorizontalFlexLayout spacing={2}>
                    <GridItem xs={12}>
                        <Controller
                            name="job.name"
                            control={form.control}
                            render={({field, fieldState: {error}}) => (
                                <InputField
                                    label={"Name"}
                                    placeholder={strings.jobNamePlaceholder}
                                    required
                                    fieldError={error}
                                    {...field} />
                            )}/>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <LocationField withLabel/>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <Controller
                            name="type"
                            control={form.control}
                            render={({field, fieldState: {error}}) => (
                                <LookupFields
                                    label={strings.projectType}
                                    items={jobTypes}
                                    required
                                    fieldError={error}
                                    {...field}/>
                            )}/>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <Controller
                            name="deadline"
                            control={form.control}
                            render={({field, fieldState: {error}}) => (
                                <InputField
                                    label={strings.deadline}
                                    type={"date"}
                                    required
                                    fieldError={error}
                                    {...field} />
                            )}/>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <BudgetField
                            namespace="budget"
                            label={strings.budget}/>
                    </GridItem>
                    <GridItem xs={12}>
                        <Controller
                            name="notes"
                            control={form.control}
                            render={({field, fieldState: {error}}) => (
                                <InputField
                                    label={strings.notes}
                                    multiline
                                    fieldError={error}
                                    {...field} />
                            )}/>
                    </GridItem>
                    <GridItem xs={12}>
                        <HorizontalFlexLayout
                            justify={"flex-end"}>
                            <Button
                                isBusy={loading}
                                type="submit"
                                variant={"contained"}>
                                {props.proposal.id ? strings.save : strings.next}
                            </Button>
                        </HorizontalFlexLayout>
                    </GridItem>
                </HorizontalFlexLayout>
            </form>
        </FormProvider>
    )
}

export default UpsertProposalControl
