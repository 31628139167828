import React, {ElementType} from "react"
import {CircularProgress} from "@mui/material"
import Button, {ButtonProps} from "@mui/material/Button/Button"

interface Props extends ButtonProps {
    isBusy?: boolean
    component?: ElementType
}

const IconButton: React.FC<Props> = (props) => {
    const style = {
        width: 38,
        height: 38,
        padding: 0,
        minWidth: "auto"
    }

    return (
        <Button {...props} sx={{...style, ...props.sx}}>
            {props.isBusy ?
                <CircularProgress size={24}/> :
                props.children}
        </Button>
    )
}

export default IconButton
