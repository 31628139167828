import React, {createContext, FC, PropsWithChildren, useContext} from "react";
import {SnackbarProvider, useSnackbar} from "notistack";
import {Alert} from "@mui/material";
import {Body2} from "components/common/Typography";
import colors from "res/colors";

interface AlertContext {
    enqueue: (alert: AlertContent) => void
}

interface AlertContent {
    message: string
    id?: string
    type?: "snackbar" | "dialog"
    severity?: 'success' | 'info' | 'warning' | 'error'
    persist?: boolean
    duration?: number
}

const alertContext = createContext<AlertContext>({
    enqueue: () => {
    }
})

export const useAlert = () => useContext<AlertContext>(alertContext)
// TODO: This is a temp solution, it might not be safe or working as expected.
export let staticEnqueueAlert :(alert: AlertContent) => void

const ActualAlertProvider: FC<PropsWithChildren> = (props) => {
    const snackbar = useSnackbar()

    const enqueue = (alert: AlertContent) => {
        snackbar.enqueueSnackbar(alert.message, {
            persist: alert.persist,
            autoHideDuration: alert.duration,
            content: () => {
                let bg = "#E7F7F3"
                let color = colors.success

                if (alert.severity === "error") {
                    bg = "#FBE6E6"
                    color = colors.error
                }

                return (
                    <Alert
                        icon={false}
                        color={"success"}
                        severity={alert.severity}
                        sx={{backgroundColor: bg}}>
                        <Body2
                            color={color}
                            sx={{fontWeight: 400}}>
                            {alert.message}
                        </Body2>
                    </Alert>
                )
            }
        })
    }

    staticEnqueueAlert = enqueue

    return (
        <alertContext.Provider value={{enqueue}}>
            {props.children}
        </alertContext.Provider>
    )
}

const AlertProvider: FC<PropsWithChildren> = (props) => {
    return (
        <SnackbarProvider
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}>
            <ActualAlertProvider>
                {props.children}
            </ActualAlertProvider>
        </SnackbarProvider>
    )
}

export default AlertProvider
