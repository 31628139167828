import React from "react"
import {TextField} from "@mui/material"
import {TextFieldProps} from "@mui/material/TextField/TextField"
import {Typography} from "components/common/Typography"
import {FieldError} from "react-hook-form/dist/types/errors";

type Props = TextFieldProps & {
    fieldError?: Partial<FieldError>
}

const InputField: React.FC<Props> = (props) => {
    const {fieldError, ...textFieldProps} = props

    return (<>
        <Typography
            component={"label"}
            sx={{lineHeight: "20px", fontSize: 14}}>
            {props.label}
        </Typography>
        <TextField
            fullWidth
            variant="outlined"
            {...textFieldProps}
            error={Boolean(fieldError)}
            helperText={fieldError?.message}
            label={undefined}/>
    </>)
}

export default InputField
