import React from "react";
import IconProps from "components/common/icons/IconProps";

const BackIcon: React.FC<IconProps> = (props) => (
    <div className={"svg-container"}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size ?? "24"}
            height={props.size ?? "24"}
            viewBox="0 0 24 24">
            <path
                d="M8.41406 12.657L12.3641 16.607C12.5417 16.7965 12.6387 17.0476 12.6345 17.3073C12.6303 17.567 12.5252 17.8148 12.3415 17.9984C12.1578 18.182 11.9098 18.2869 11.6501 18.2909C11.3905 18.295 11.1394 18.1978 10.9501 18.02L5.29306 12.364C5.19987 12.2714 5.12592 12.1612 5.07546 12.0399C5.025 11.9185 4.99902 11.7884 4.99902 11.657C4.99902 11.5256 5.025 11.3955 5.07546 11.2742C5.12592 11.1528 5.19987 11.0427 5.29306 10.95L10.9501 5.293C11.0423 5.19749 11.1526 5.12131 11.2747 5.0689C11.3967 5.01649 11.5279 4.98891 11.6607 4.98775C11.7934 4.9866 11.9251 5.0119 12.048 5.06218C12.1709 5.11246 12.2826 5.18672 12.3765 5.28061C12.4703 5.3745 12.5446 5.48615 12.5949 5.60905C12.6452 5.73195 12.6705 5.86363 12.6693 5.99641C12.6682 6.12919 12.6406 6.26041 12.5882 6.38241C12.5358 6.50441 12.4596 6.61476 12.3641 6.707L8.41406 10.657H18.0001C18.2653 10.657 18.5196 10.7624 18.7072 10.9499C18.8947 11.1374 19.0001 11.3918 19.0001 11.657C19.0001 11.9222 18.8947 12.1766 18.7072 12.3641C18.5196 12.5516 18.2653 12.657 18.0001 12.657H8.41406Z"
                fill={props.color ?? "black"}/>
        </svg>
    </div>
)

export default BackIcon
