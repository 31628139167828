import React, {FC, useEffect, useState} from "react";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "components/common/FlexLayout";
import {Box, Breadcrumbs, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {Body2, Heading3, Heading4} from "components/common/Typography";
import Button from "components/common/Button";
import colors from "res/colors";
import Status from "components/common/Status";
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator";
import {Link, useParams} from "react-router-dom";
import Proposal from "models/Proposal";
import DataNotFound from "components/common/DataNotFound";
import Api from "data/remote/Api";
import {handleFieldErrors, truncate} from "util/Utilities";
import ErrorResponse from "data/remote/models/ErrorResponse";
import Loading from "components/common/Loading";
import IconButton from "components/common/IconButton";
import ProposalDetailsCard from "components/common/ProposalDetailsCard";
import CloseIcon from "components/common/icons/CloseIcon";
import CreateProposal from "pages/admin/CreateProposal";
import {useAlert} from "contexts/AlertContext";
import EditIcon from "components/common/icons/EditIcon";
import UpsertProposalControl from "components/proposal/UpsertProposalControl";
import paths from "res/paths";
import strings from "res/strings";

const ProposalDetails: FC = () => {
    const {id, jobId, companyId} = useParams()
    const alert = useAlert()

    // TODO: Once agent changes anything on the offer, allow them to send it to agent again.

    const [proposal, setProposal] = useState<Proposal>()
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)

    useEffect(() => {
        void async function fetchData() {
            if (id && jobId) {
                await Api.proposals.getProposalAsync(id, jobId, companyId)
                    .then(p => setProposal(p))
                    .catch(res => handleFieldErrors(res.data as ErrorResponse))
            }

            setLoading(false)
        }()
    }, [id, jobId, companyId])

    const sendToClient = async () => {
        await Api.proposals.updateStatus(proposal!.id, jobId!, companyId!, "submit")
            .then(() => {
                alert.enqueue({
                    message: `Proposal sent successfully!`,
                    severity: "success"
                })
                setProposal(ps => ({...ps!, status: "pending"}))
            })
            .catch(res => handleFieldErrors(res.data as ErrorResponse))
    }

    if (loading) {
        return (<Loading/>)
    } else if (!proposal) {
        return (<DataNotFound/>)
    }

    const modificationAllowed = ["draft", "rejected", "declined"].includes(proposal.status)

    return (<>
        <HtmlMetaDecorator title={proposal.job.name}/>

        <VerticalFlexLayout
            height={"100%"}
            wrap={"nowrap"}>
            <Box p={2} sx={{backgroundColor: "white"}}>
                <VerticalFlexLayout spacing={2}>
                    <Breadcrumbs
                        separator=">"
                        sx={{fontSize: "small"}}>
                        <Link to={paths.adminAssignments}>
                            <Body2>
                                {strings.assignments}
                            </Body2>
                        </Link>
                        <Link to={paths.adminJobDetails(proposal.job.companyId, proposal.jobId)}>
                            <Body2>
                                {truncate(proposal.job.name)}
                            </Body2>
                        </Link>
                        <Link to={paths.adminJobDetails(proposal.job.companyId, proposal.job.id, "#proposals")}>
                            <Body2>
                                {strings.proposals}
                            </Body2>
                        </Link>
                        <Body2>
                            {strings.proposalDetails}
                        </Body2>
                    </Breadcrumbs>
                    <HorizontalFlexLayout spacing={2}>
                        <GridItem sx={{flexGrow: 1}}>
                            <Heading3>{proposal.job.name}</Heading3>
                        </GridItem>

                        {modificationAllowed &&
                        <IconButton
                            color={"secondary"}
                            variant={"outlined"}
                            onClick={() => setEditOpen(true)}>
                            <EditIcon/>
                        </IconButton>}

                        {modificationAllowed &&
                        <Button
                            variant={"outlined"}
                            color={"secondary"}
                            onClick={() => {
                                setOpen(true)
                            }}>
                            Add Offer
                        </Button>}

                        {modificationAllowed &&
                        <Button
                            onClick={sendToClient}
                            variant={"contained"}>
                            Send to client
                        </Button>}
                    </HorizontalFlexLayout>
                    <Status value={proposal.status}/>
                    {Boolean(proposal.status === "rejected" && proposal.rejectionReason) &&
                    <Body2 color={colors.error}>
                        {proposal.rejectionReason}
                    </Body2>}
                </VerticalFlexLayout>
            </Box>
            <GridItem sx={{flexGrow: 1, backgroundColor: "#F9F9F9"}}>
                <ProposalDetailsCard
                    proposal={proposal}
                    onUpdate={p => setProposal(p)}/>
            </GridItem>
        </VerticalFlexLayout>

        <Dialog
            open={open}
            fullWidth
            PaperProps={{
                sx: {height: "100%"}
            }}
            maxWidth={"xl"}>
            <DialogTitle>
                <HorizontalFlexLayout wrap={"nowrap"}>
                    <GridItem sx={{flexGrow: 1}}>
                        <Heading4>Selecting Offers</Heading4>
                    </GridItem>
                    <IconButton
                        onClick={() => setOpen(false)}>
                        <CloseIcon/>
                    </IconButton>
                </HorizontalFlexLayout>
            </DialogTitle>
            <DialogContent dividers>
                <CreateProposal
                    proposal={proposal}
                    onSuccess={p => {
                        setProposal(p)
                        setOpen(false)
                    }}/>
            </DialogContent>
        </Dialog>

        <Dialog
            open={editOpen}
            onClose={() => setEditOpen(false)}
            fullWidth
            maxWidth={"md"}>
            <DialogActions sx={{padding: 2}}>
                <HorizontalFlexLayout spacing={2} wrap={"nowrap"}>
                    <GridItem sx={{flexGrow: 1}}>
                        <Heading4>{proposal.job.name}</Heading4>
                    </GridItem>
                    <IconButton
                        onClick={() => setEditOpen(false)}>
                        <CloseIcon/>
                    </IconButton>
                </HorizontalFlexLayout>
            </DialogActions>
            <DialogContent dividers>
                <UpsertProposalControl
                    proposal={proposal}
                    onComplete={p => {
                        setProposal(p)
                        setEditOpen(false)
                    }}/>
            </DialogContent>
        </Dialog>
    </>)
}

export default ProposalDetails
