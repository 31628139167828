import React, {FC} from "react"
import Proposal from "models/Proposal";
import {Body1, Body2, Heading4, Heading6} from "components/common/Typography";
import DataTable, {ColumnDefinition} from "components/common/DataTable";
import strings from "res/strings";
import colors from "res/colors";
import {handleFieldErrors, toAge, toShortDate} from "util/Utilities";
import Api from "data/remote/Api";
import ErrorResponse from "data/remote/models/ErrorResponse";
import JobStatus from "models/types/JobStatus";
import {VerticalFlexLayout} from "components/common/FlexLayout";

const statuses: JobStatus[] = [
    "all",
    "draft",
    "pending",
    "approved",
    "declined",
    "closed",
    "cancelled"
]

const columns: ColumnDefinition<Proposal>[] = [
    {id: "job", label: strings.title, align: "left"},
    {id: "status", label: strings.status, align: "left"}
]

const renderRow = (p: Proposal): (string | JSX.Element)[] => {
    return [
        <>
            <Heading6>{p.job.name}</Heading6>
            <Body2 color={colors.disabledText}>
                {toShortDate(p.created)}
            </Body2>
        </>,
        <>
            <Heading6>{strings[`${p.status}Status`]}</Heading6>
            <Body2 color={colors.disabledText}>
                Last update: {toAge(p.updated)}
            </Body2>
        </>
    ]
}

const emptyView = (
    <VerticalFlexLayout
        height={"100%"}
        wrap={"nowrap"}
        justify={"center"}>
        <Heading4 align={"center"}>
            {strings.agentNoProposalsTitle}
        </Heading4>
        <Body1 align={"center"}>
            {strings.agentNoProposalsMessage}
        </Body1>
    </VerticalFlexLayout>
)

interface Props {
    jobId?: string
    companyId?: string
    onClick: (proposal: Proposal) => void
}

const ProposalsList: FC<Props> = (props) => {
    const load = async (status: JobStatus) => {
        return await Api.proposals
            .getJobProposalsAsync(props.jobId, props.companyId)
            .catch(res => {
                handleFieldErrors(res.data as ErrorResponse)
                return undefined
            })
    }

    return (
        <DataTable
            hideFiltration
            load={load}
            columns={columns}
            renderRow={renderRow}
            onClick={props.onClick}
            emptyView={emptyView}
            defaultStatus={"pending"}
            statues={statuses}/>
    )
}

export default ProposalsList
