import React, {FC, useState} from "react"
import {VerticalFlexLayout} from "components/common/FlexLayout"
import {Typography} from "@mui/material"
import {Controller, useForm} from "react-hook-form"
import Button from "components/common/Button"
import strings from "res/strings"
import Api from "data/remote/Api";
import InputField from "components/common/InputField";
import {handleFieldErrors} from "util/Utilities";
import ErrorResponse from "data/remote/models/ErrorResponse";
import {useAuth} from "contexts/AuthContext";

interface Props {
    username: string
    password: string
}

const ConfirmSignUpControl: FC<Props> = (props) => {
    const {update} = useAuth()
    const [isLoading, setIsLoading] = useState(false)
    const {control, handleSubmit, clearErrors, setError} = useForm<{ code: string }>()

    const onSubmit = handleSubmit(async fields => {
        setIsLoading(true)
        clearErrors()

        await Api.auth.confirmSignUpAsync(props.username, fields.code)
            .then(async () => {
                await Api.auth.signInAsync(props.username, props.password)
                    .then(() => update())
                    .catch(res => {
                        handleFieldErrors(res.data as ErrorResponse, setError)
                        setIsLoading(false)
                    })
            })
            .catch(res => {
                handleFieldErrors(res.data as ErrorResponse, setError)
                setIsLoading(false)
            })
    })

    return (
        <form onSubmit={onSubmit}>
            <VerticalFlexLayout spacing={3}>
                <Typography whiteSpace={"pre-wrap"}>
                    {strings.confirmSignUpMessage_Username
                        .replace("{0}", props.username)}
                </Typography>

                <Controller
                    name="code"
                    control={control}
                    render={({field, fieldState: {error}}) => (
                        <InputField
                            label={strings.verificationCode}
                            type="number"
                            autoFocus
                            required
                            fieldError={error}
                            {...field} />
                    )}/>

                <Button
                    fullWidth
                    type="submit"
                    size={"large"}
                    isBusy={isLoading}
                    variant="contained"
                    color="primary">
                    {strings.send}
                </Button>
            </VerticalFlexLayout>
        </form>
    )
}

export default ConfirmSignUpControl
